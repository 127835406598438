/* Genel Start */

.img_altili_sablon10 .campaing-dates {
  position: absolute;
  font-size: 30px;
  left: 18px;
  top: 950px;
  width: 387px;
  text-align: left;
  font-family: 'Montserrat Extra Bold';
}

.img_altili_sablon10 .address {
  position: absolute;
  font-size: 20px;
  width: 337px;
  text-align: right;
  top: 970px;
  left: 640px;
  font-family: 'Montserrat Extra Bold';
}

.img_altili_sablon10 .pattern-logo img {
  position: absolute;
  left: 40px;
  top: 60px;
  width: 14rem;
}

.img_altili_sablon10 .phone {
  position: absolute;
  font-size: 20px;
  width: 337px;
  text-align: right;
  top: 945px;
  left: 640px;
  font-family: 'Montserrat Extra Bold';
}

/* Genel Finish */

/* img_altili_sablon10 6-1 Start */

.img_altili_sablon10 .new-price6-1 {
  font-size: 32px;
  position: absolute;
  left: 283px;
  top: 368px;
  width: 165px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcd02;
  border-radius: 5px;
}

.img_altili_sablon10 .old-price6-1 {
  font-size: 26px;
  position: absolute;
  left: 318px;
  top: 418px;
  width: 130px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff2be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon10 .title6-1 {
  font-size: 17px;
  position: absolute;
  left: 20px;
  width: 452px;
  text-align: center;
  top: 490px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon10 .titleTR6-1 {
  font-size: 16px;
  position: absolute;
  left: 20px;
  width: 452px;
  text-align: center;
  top: 464px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon10 .origin6-1 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 418px;
  top: 320px;
  background-color: #f0ba00;
  text-align: center;
  font-weight: bold;
  font-family: 'Montserrat';
}

.img_altili_sablon10 .weight6-1 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 20px;
  top: 325px;
  font-family: 'Montserrat';
}

.img_altili_sablon10 .product-image6-1 img {
  position: absolute;
  max-height: 7.5rem;
  left: 50px;
  top: 338px;
}


/* img_altili_sablon10 6-2 Finish */


/* img_altili_sablon10 6-2 Start */

.img_altili_sablon10 .new-price6-2 {
  font-size: 32px;
  position: absolute;
  left: 790px;
  top: 368px;
  width: 165px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcd02;
  border-radius: 5px;
}

.img_altili_sablon10 .old-price6-2 {
  font-size: 26px;
  position: absolute;
  left: 825px;
  top: 418px;
  width: 130px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff2be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon10 .title6-2 {
  font-size: 17px;
  position: absolute;
  left: 520px;
  width: 452px;
  text-align: center;
  top: 490px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon10 .titleTR6-2 {
  font-size: 16px;
  position: absolute;
  left: 520px;
  width: 452px;
  text-align: center;
  top: 464px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon10 .origin6-2 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 925px;
  top: 320px;
  font-family: 'Montserrat';
  background-color: #f0ba00;
  text-align: center;
  font-weight: bold;
}

.img_altili_sablon10 .weight6-2 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 520px;
  top: 325px;
  font-family: 'Montserrat';
}

.img_altili_sablon10 .product-image6-2 img {
  position: absolute;
  max-height: 7.5rem;
  left: 550px;
  top: 338px;
}

/* img_altili_sablon10 6-2 Finish */


/* img_altili_sablon10 6-3 Start */

.img_altili_sablon10 .new-price6-3 {
  font-size: 32px;
  position: absolute;
  left: 283px;
  top: 576px;
  width: 165px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcd02;
  border-radius: 5px;
}

.img_altili_sablon10 .old-price6-3 {
  font-size: 26px;
  position: absolute;
  left: 318px;
  top: 626px;
  width: 130px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff2be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon10 .title6-3 {
  font-size: 17px;
  position: absolute;
  left: 20px;
  width: 452px;
  text-align: center;
  top: 698px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon10 .titleTR6-3 {
  font-size: 16px;
  position: absolute;
  left: 20px;
  width: 452px;
  text-align: center;
  top: 672px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon10 .origin6-3 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 418px;
  top: 320px;
  font-family: 'Montserrat';
  background-color: #f0ba00;
  text-align: center;
  font-weight: bold;
}

.img_altili_sablon10 .weight6-3 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 20px;
  top: 533px;
  font-family: 'Montserrat';
}

.img_altili_sablon10 .product-image6-3 img {
  position: absolute;
  max-height: 7.5rem;
  left: 50px;
  top: 546px;
}

/* img_altili_sablon10 6-3 Finish */


/* img_altili_sablon10 6-4 Start */

.img_altili_sablon10 .new-price6-4 {
  font-size: 32px;
  position: absolute;
  left: 790px;
  top: 576px;
  width: 165px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcd02;
  border-radius: 5px;
}

.img_altili_sablon10 .old-price6-4 {
  font-size: 26px;
  position: absolute;
  left: 825px;
  top: 626px;
  width: 130px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff2be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon10 .title6-4 {
  font-size: 17px;
  position: absolute;
  left: 520px;
  width: 452px;
  text-align: center;
  top: 698px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon10 .titleTR6-4 {
  font-size: 16px;
  position: absolute;
  left: 520px;
  width: 452px;
  text-align: center;
  top: 672px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon10 .origin6-4 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 925px;
  top: 528px;
  font-family: 'Montserrat';
  background-color: #f0ba00;
  text-align: center;
  font-weight: bold;
}

.img_altili_sablon10 .weight6-4 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 520px;
  top: 533px;
  font-family: 'Montserrat';
}

.img_altili_sablon10 .product-image6-4 img {
  position: absolute;
  max-height: 7.5rem;
  left: 550px;
  top: 546px;
}

/* img_altili_sablon10 6-4 Finish */

/* img_altili_sablon10 6-5 Start */

.img_altili_sablon10 .new-price6-5 {
  font-size: 32px;
  position: absolute;
  left: 283px;
  top: 784px;
  width: 165px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcd02;
  border-radius: 5px;
}

.img_altili_sablon10 .old-price6-5 {
  font-size: 26px;
  position: absolute;
  left: 318px;
  top: 834px;
  width: 130px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff2be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon10 .title6-5 {
  font-size: 17px;
  position: absolute;
  left: 20px;
  width: 452px;
  text-align: center;
  top: 905px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon10 .titleTR6-5 {
  font-size: 16px;
  position: absolute;
  left: 20px;
  width: 452px;
  text-align: center;
  top: 880px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon10 .origin6-5 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 418px;
  top: 736px;
  font-family: 'Montserrat';
  background-color: #f0ba00;
  text-align: center;
  font-weight: bold;
}

.img_altili_sablon10 .weight6-5 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 20px;
  top: 741px;
  font-family: 'Montserrat';
}

.img_altili_sablon10 .product-image6-5 img {
  position: absolute;
  max-height: 7.5rem;
  left: 50px;
  top: 754px;
}

/* img_altili_sablon10 6-5 Finish */

/* img_altili_sablon10 6-6 Start */

.img_altili_sablon10 .new-price6-6 {
  font-size: 32px;
  position: absolute;
  left: 790px;
  top: 784px;
  width: 165px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcd02;
  border-radius: 5px;
}

.img_altili_sablon10 .old-price6-6 {
  font-size: 26px;
  position: absolute;
  left: 825px;
  top: 834px;
  width: 130px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff2be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon10 .title6-6 {
  font-size: 17px;
  position: absolute;
  left: 520px;
  width: 452px;
  text-align: center;
  top: 905px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon10 .titleTR6-6 {
  font-size: 16px;
  position: absolute;
  left: 520px;
  width: 452px;
  text-align: center;
  top: 880px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon10 .origin6-6 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 925px;
  top: 736px;
  font-family: 'Montserrat';
  background-color: #f0ba00;
  text-align: center;
  font-weight: bold;
}

.img_altili_sablon10 .weight6-6 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 520px;
  top: 741px;
  font-family: 'Montserrat';
}

.img_altili_sablon10 .product-image6-6 img {
  position: absolute;
  max-height: 7.5rem;
  left: 550px;
  top: 754px;
}

/* img_altili_sablon10 6-6 Finish */