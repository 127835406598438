/* img_uclu_sablon1 Genel Start */

.img_uclu_sablon1 .campaing-dates {
  position: absolute;
  left: 30px;
  top: 950px;
  font-size: 30px;
  font-family: "Montserrat Extra Bold";
  font-weight: bold;
  color: #585454;
}

.img_uclu_sablon1 .address {
  position: absolute;
  font-size: 15px;
  top: 957px;
  left: 400px;
  width: 350px;
  text-align: center;
  color: #000;
  font-family: "Montserrat";
}

.img_uclu_sablon1 .phone {
  position: absolute;
  font-size: 22px;
  top: 953px;
  left: 815px;
  text-align: center;
  color: red;
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 2px;
}

.img_uclu_sablon1 .pattern-logo {
  position: absolute;
  left: 83px;
  top: 3px;
}

.img_uclu_sablon1 .pattern-logo img {
  width: 250px;
}

/* img_uclu_sablon1 Genel Finish */

/* img_uclu_sablon1 3-1 Start */

.img_uclu_sablon1 .new-price3-1 {
  font-size: 26px;
  position: absolute;
  width: 150px;
  text-align: center;
  left: 113px;
  top: 801px;
  color: white;
  background-color: red;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon1 .old-price3-1 {
  left: 130px;
  top: 836px;
  font-size: 20px;
  position: absolute;
  width: 120px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #6f6767;
  background-color: #beaeae;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon1 .title3-1 {
  font-size: 24px;
  position: absolute;
  left: 45px;
  top: 450px;
  width: 280px;
  text-transform: capitalize;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon1 .titleTR3-1 {
  font-size: 22px;
  position: absolute;
  left: 45px;
  top: 480px;
  width: 280px;
  text-transform: capitalize;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon1 .origin3-1 {
  font-size: 13px;
  position: absolute;
  left: 295px;
  top: 910px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
}

.img_uclu_sablon1 .weight3-1 {
  font-size: 15px;
  position: absolute;
  left: 48px;
  top: 890px;
  width: 280px;
  text-align: center;
}

.img_uclu_sablon1 .product-image3-1 img {
  position: absolute;
  max-height: 15rem;
  left: 65px;
  top: 537px;
}

/* img_uclu_sablon1 3-1 Finish */


/* img_uclu_sablon1 3-2 Start */

.img_uclu_sablon1 .new-price3-2 {
  font-size: 26px;
  position: absolute;
  width: 150px;
  text-align: center;
  left: 433px;
  top: 801px;
  color: white;
  background-color: red;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon1 .old-price3-2 {
  left: 450px;
  top: 836px;
  font-size: 20px;
  position: absolute;
  width: 120px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #6f6767;
  background-color: #beaeae;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon1 .title3-2 {
  font-size: 24px;
  position: absolute;
  left: 365px;
  top: 450px;
  width: 280px;
  text-transform: capitalize;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon1 .titleTR3-2 {
  font-size: 22px;
  position: absolute;
  left: 365px;
  top: 480px;
  width: 280px;
  text-transform: capitalize;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon1 .origin3-2 {
  font-size: 13px;
  position: absolute;
  left: 610px;
  top: 910px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
}

.img_uclu_sablon1 .weight3-2 {
  font-size: 15px;
  position: absolute;
  left: 365px;
  top: 890px;
  width: 280px;
  text-align: center;
}

.img_uclu_sablon1 .product-image3-2 img {
  position: absolute;
  max-height: 15rem;
  left: 385px;
  top: 537px;
}

/* img_uclu_sablon1 3-2 Finish */

/* img_uclu_sablon1 3-3 Start */

.img_uclu_sablon1 .new-price3-3 {
  font-size: 26px;
  position: absolute;
  width: 150px;
  text-align: center;
  left: 753px;
  top: 801px;
  color: white;
  background-color: red;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon1 .old-price3-3 {
  left: 770px;
  top: 836px;
  font-size: 20px;
  position: absolute;
  width: 120px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #6f6767;
  background-color: #beaeae;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon1 .title3-3 {
  font-size: 24px;
  position: absolute;
  left: 679px;
  top: 450px;
  width: 280px;
  text-transform: capitalize;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon1 .titleTR3-3 {
  font-size: 22px;
  position: absolute;
  left: 679px;
  top: 480px;
  width: 280px;
  text-transform: capitalize;
  font-family: 'Montserrat';
  font-weight: bold;
  text-align: center;
}

.img_uclu_sablon1 .origin3-3 {
  font-size: 13px;
  position: absolute;
  left: 925px;
  top: 910px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_uclu_sablon1 .weight3-3 {
  font-size: 15px;
  position: absolute;
  left: 680px;
  top: 890px;
  width: 280px;
  text-align: center;
}

.img_uclu_sablon1 .product-image3-3 img {
  position: absolute;
  max-height: 15rem;
  left: 705px;
  top: 537px;
}

/* img_uclu_sablon1 3-3 Finish */