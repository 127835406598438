.img_dokuzlu_sablon1 .campaing-dates {
  position: absolute;
  font-size: 30px;
  top: 950px;
  left: 35px;
  width: 387px;
  text-align: center;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_dokuzlu_sablon1 .address {
  position: absolute;
  font-size: 23px;
  width: 337px;
  text-align: left;
  top: 960px;
  left: 435px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_dokuzlu_sablon1 .pattern-logo img {
  position: absolute;
  left: 50px;
  top: 10px;
  width: 15rem;
}

.img_dokuzlu_sablon1 .phone {
  position: absolute;
  font-size: 28px;
  width: 337px;
  text-align: center;
  left: 650px;
  top: 948px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

/* Genel Finish */

/* img_dokuzlu_sablon1 9-1 Start */

.img_dokuzlu_sablon1 .new-price9-1 {
  font-size: 23px;
  position: absolute;
  left: 200px;
  top: 265px;
  width: 115px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f53425;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon1 .old-price9-1 {
  font-size: 20px;
  position: absolute;
  left: 208px;
  top: 300px;
  width: 100px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d4c7bf;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon1 .title9-1 {
  font-size: 28px;
  position: absolute;
  left: 25px;
  width: 305px;
  text-align: center;
  top: 166px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon1 .titleTR9-1 {
  font-size: 17px;
  position: absolute;
  left: 29px;
  width: 298px;
  text-align: center;
  top: 205px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon1 .origin9-1 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 297px;
  top: 159px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon1 .weight9-1 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 180px;
  top: 385px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon1 .product-image9-1 img {
  position: absolute;
  max-height: 8rem;
  left: 44px;
  top: 235px;
}


/* img_dokuzlu_sablon1 9-2 Finish */


/* img_dokuzlu_sablon1 9-2 Start */

.img_dokuzlu_sablon1 .new-price9-2 {
  font-size: 23px;
  position: absolute;
  left: 500px;
  top: 265px;
  width: 115px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f53425;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon1 .old-price9-2 {
  font-size: 20px;
  position: absolute;
  left: 510px;
  top: 300px;
  width: 100px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d4c7bf;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon1 .title9-2 {
  font-size: 28px;
  position: absolute;
  left: 345px;
  width: 305px;
  text-align: center;
  top: 166px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon1 .titleTR9-2 {
  font-size: 17px;
  position: absolute;
  left: 347px;
  width: 300px;
  text-align: center;
  top: 205px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon1 .origin9-2 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 618px;
  top: 159px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon1 .weight9-2 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 490px;
  top: 385px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon1 .product-image9-2 img {
  position: absolute;
  max-height: 8rem;
  left: 350px;
  top: 235px;
}

/* img_dokuzlu_sablon1 9-2 Finish */


/* img_dokuzlu_sablon1 9-3 Start */

.img_dokuzlu_sablon1 .new-price9-3 {
  font-size: 23px;
  position: absolute;
  left: 840px;
  top: 265px;
  width: 115px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f53425;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon1 .old-price9-3 {
  font-size: 20px;
  position: absolute;
  left: 847px;
  top: 300px;
  width: 100px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d4c7bf;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon1 .title9-3 {
  font-size: 28px;
  position: absolute;
  left: 665px;
  width: 300px;
  text-align: center;
  top: 166px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon1 .titleTR9-3 {
  font-size: 17px;
  position: absolute;
  left: 666px;
  width: 300px;
  text-align: center;
  top: 205px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon1 .origin9-3 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 936px;
  top: 159px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon1 .weight9-3 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 820px;
  top: 385px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon1 .product-image9-3 img {
  position: absolute;
  max-height: 8rem;
  left: 680px;
  top: 235px;
}

/* img_dokuzlu_sablon1 9-3 Finish */


/* img_dokuzlu_sablon1 9-4 Start */

.img_dokuzlu_sablon1 .new-price9-4 {
  font-size: 23px;
  position: absolute;
  left: 200px;
  top: 525px;
  width: 115px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f53425;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon1 .old-price9-4 {
  font-size: 20px;
  position: absolute;
  left: 208px;
  top: 558px;
  width: 100px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d4c7bf;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon1 .title9-4 {
  font-size: 28px;
  position: absolute;
  left: 28px;
  width: 300px;
  text-align: center;
  top: 436px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon1 .titleTR9-4 {
  font-size: 17px;
  position: absolute;
  left: 29px;
  width: 295px;
  text-align: center;
  top: 475px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon1 .origin9-4 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 297px;
  top: 423px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon1 .weight9-4 {
  font-size: 10px;
  position: absolute;
  width: 145px;
  text-align: left;
  left: 180px;
  top: 650px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon1 .product-image9-4 img {
  position: absolute;
  max-height: 8rem;
  left: 62px;
  top: 490px;
}

/* img_dokuzlu_sablon1 9-4 Finish */

/* img_dokuzlu_sablon1 9-5 Start */

.img_dokuzlu_sablon1 .new-price9-5 {
  font-size: 23px;
  position: absolute;
  left: 500px;
  top: 525px;
  width: 115px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f53425;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon1 .old-price9-5 {
  font-size: 20px;
  position: absolute;
  left: 510px;
  top: 558px;
  width: 100px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d4c7bf;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon1 .title9-5 {
  font-size: 28px;
  position: absolute;
  left: 345px;
  width: 300px;
  text-align: center;
  top: 436px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon1 .titleTR9-5 {
  font-size: 17px;
  position: absolute;
  left: 347px;
  width: 300px;
  text-align: center;
  top: 470px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon1 .origin9-5 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 618px;
  top: 423px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon1 .weight9-5 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 490px;
  top: 650px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon1 .product-image9-5 img {
  position: absolute;
  max-height: 8rem;
  left: 360px;
  top: 490px;
}

/* img_dokuzlu_sablon1 9-5 Finish */

/* img_dokuzlu_sablon1 9-6 Start */

.img_dokuzlu_sablon1 .new-price9-6 {
  font-size: 23px;
  position: absolute;
  left: 840px;
  top: 525px;
  width: 115px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f53425;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon1 .old-price9-6 {
  font-size: 20px;
  position: absolute;
  left: 847px;
  top: 558px;
  width: 100px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d4c7bf;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon1 .title9-6 {
  font-size: 28px;
  position: absolute;
  left: 665px;
  width: 300px;
  text-align: center;
  top: 436px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon1 .titleTR9-6 {
  font-size: 17px;
  position: absolute;
  left: 666px;
  width: 295px;
  text-align: center;
  top: 470px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon1 .origin9-6 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 936px;
  top: 423px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon1 .weight9-6 {
  font-size: 10px;
  position: absolute;
  width: 145px;
  text-align: left;
  left: 820px;
  top: 650px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon1 .product-image9-6 img {
  position: absolute;
  max-height: 8rem;
  left: 680px;
  top: 490px;
}

/* img_dokuzlu_sablon1 9-6 Finish */

/* img_dokuzlu_sablon1 9-7 Start */

.img_dokuzlu_sablon1 .new-price9-7 {
  font-size: 23px;
  position: absolute;
  left: 200px;
  top: 785px;
  width: 115px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f53425;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon1 .old-price9-7 {
  font-size: 20px;
  position: absolute;
  left: 208px;
  top: 815px;
  width: 100px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d4c7bf;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon1 .title9-7 {
  font-size: 28px;
  position: absolute;
  left: 28px;
  width: 300px;
  text-align: center;
  top: 698px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon1 .titleTR9-7 {
  font-size: 17px;
  position: absolute;
  left: 29px;
  width: 295px;
  text-align: center;
  top: 738px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon1 .origin9-7 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 297px;
  top: 688px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon1 .weight9-7 {
  font-size: 10px;
  position: absolute;
  width: 145px;
  text-align: left;
  left: 180px;
  top: 905px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon1 .product-image9-7 img {
  position: absolute;
  max-height: 8rem;
  left: 62px;
  top: 775px;
}

/* img_dokuzlu_sablon1 9-7 Finish */

/* img_dokuzlu_sablon1 9-8 Start */

.img_dokuzlu_sablon1 .new-price9-8 {
  font-size: 23px;
  position: absolute;
  left: 500px;
  top: 785px;
  width: 115px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f53425;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon1 .old-price9-8 {
  font-size: 20px;
  position: absolute;
  left: 510px;
  top: 815px;
  width: 100px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d4c7bf;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon1 .title9-8 {
  font-size: 28px;
  position: absolute;
  left: 345px;
  width: 300px;
  text-align: center;
  top: 707px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon1 .titleTR9-8 {
  font-size: 17px;
  position: absolute;
  left: 347px;
  width: 295px;
  text-align: center;
  top: 738px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon1 .origin9-8 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 618px;
  top: 688px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon1 .weight9-8 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 490px;
  top: 905px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon1 .product-image9-8 img {
  position: absolute;
  max-height: 8rem;
  left: 350px;
  top: 775px;
}

/* img_dokuzlu_sablon1 9-8 Finish */

/* img_dokuzlu_sablon1 9-9 Start */

.img_dokuzlu_sablon1 .new-price9-9 {
  font-size: 23px;
  position: absolute;
  left: 840px;
  top: 785px;
  width: 115px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f53425;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon1 .old-price9-9 {
  font-size: 20px;
  position: absolute;
  left: 847px;
  top: 815px;
  width: 100px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d4c7bf;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon1 .title9-9 {
  font-size: 28px;
  position: absolute;
  left: 665px;
  width: 295px;
  text-align: center;
  top: 707px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon1 .titleTR9-9 {
  font-size: 17px;
  position: absolute;
  left: 666px;
  width: 295px;
  text-align: center;
  top: 747px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon1 .origin9-9 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 936px;
  top: 688px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon1 .weight9-9 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 820px;
  top: 905px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon1 .product-image9-9 img {
  position: absolute;
  max-height: 8rem;
  left: 680px;
  top: 775px;
}

/* img_dokuzlu_sablon1 9-9 Finish */