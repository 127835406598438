/* img_uclu_sablon8 Genel Start */

.img_uclu_sablon8 .campaing-dates {
  position: absolute;
  left: 340px;
  top: 215px;
  font-size: 25px;
  font-family: "Montserrat Extra Bold";
  font-weight: bold;
  color: #fff;
}

.img_uclu_sablon8 .address {
  position: absolute;
  font-size: 15px;
  top: 221px;
  left: 530px;
  width: 350px;
  text-align: center;
  color: #fff;
  font-family: "Montserrat";
}

.img_uclu_sablon8 .phone {
  position: absolute;
  font-size: 22px;
  top: 215px;
  left: 812px;
  text-align: center;
  color: #fff;
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 2px;
}

.img_uclu_sablon8 .pattern-logo {
  position: absolute;
  left: 58px;
  top: 18px;
}

.img_uclu_sablon8 .pattern-logo img {
  width: 250px;
}

/* img_uclu_sablon8 Genel Finish */

/* img_uclu_sablon8 3-1 Start */

.img_uclu_sablon8 .new-price3-1 {
  font-size: 28px;
  position: absolute;
  width: 150px;
  text-align: center;
  left: 745px;
  top: 392px;
  color: white;
  background-color: #d35a2d;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon8 .old-price3-1 {
  left: 760px;
  top: 432px;
  font-size: 20px;
  position: absolute;
  width: 120px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #fff;
  background-color: #f4bcaf;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon8 .title3-1 {
  font-size: 27px;
  position: absolute;
  left: 680px;
  top: 292px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon8 .titleTR3-1 {
  font-size: 22px;
  position: absolute;
  left: 680px;
  top: 322px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon8 .origin3-1 {
  font-size: 12px;
  position: absolute;
  left: 930px;
  top: 468px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
  color: #1E8742;
}

.img_uclu_sablon8 .weight3-1 {
  font-size: 13px;
  position: absolute;
  left: 725px;
  top: 468px;
  width: 180px;
  text-align: center;
  color: #1E8742;
}

.img_uclu_sablon8 .product-image3-1 img {
  position: absolute;
  max-height: 13rem;
  left: 393px;
  top: 270px;
}

/* img_uclu_sablon8 3-1 Finish */


/* img_uclu_sablon8 3-2 Start */

.img_uclu_sablon8 .new-price3-2 {
  font-size: 28px;
  position: absolute;
  width: 150px;
  text-align: center;
  left: 745px;
  top: 632px;
  color: white;
  background-color: #d35a2d;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon8 .old-price3-2 {
  left: 760px;
  top: 672px;
  font-size: 20px;
  position: absolute;
  width: 120px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #fff;
  background-color: #f4bcaf;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon8 .title3-2 {
  font-size: 27px;
  position: absolute;
  left: 680px;
  top: 532px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon8 .titleTR3-2 {
  font-size: 22px;
  position: absolute;
  left: 680px;
  top: 562px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon8 .origin3-2 {
  font-size: 12px;
  position: absolute;
  left: 930px;
  top: 708px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
  color: #1E8742;
}

.img_uclu_sablon8 .weight3-2 {
  font-size: 13px;
  position: absolute;
  left: 725px;
  top: 708px;
  width: 180px;
  text-align: center;
  color: #1E8742;
}

.img_uclu_sablon8 .product-image3-2 img {
  position: absolute;
  max-height: 13rem;
  left: 393px;
  top: 510px;
}

/* img_uclu_sablon8 3-2 Finish */

/* img_uclu_sablon8 3-3 Start */

.img_uclu_sablon8 .new-price3-3 {
  font-size: 28px;
  position: absolute;
  width: 150px;
  text-align: center;
  left: 745px;
  top: 872px;
  color: white;
  background-color: #d35a2d;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon8 .old-price3-3 {
  left: 760px;
  top: 912px;
  font-size: 20px;
  position: absolute;
  width: 120px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #fff;
  background-color: #f4bcaf;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon8 .title3-3 {
  font-size: 27px;
  position: absolute;
  left: 680px;
  top: 772px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon8 .titleTR3-3 {
  font-size: 22px;
  position: absolute;
  left: 680px;
  top: 802px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon8 .origin3-3 {
  font-size: 12px;
  position: absolute;
  left: 930px;
  top: 948px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
  color: #1E8742;
}

.img_uclu_sablon8 .weight3-3 {
  font-size: 13px;
  position: absolute;
  left: 725px;
  top: 948px;
  width: 180px;
  text-align: center;
  color: #1E8742;
}

.img_uclu_sablon8 .product-image3-3 img {
  position: absolute;
  max-height: 13rem;
  left: 393px;
  top: 750px;
}

/* img_uclu_sablon8 3-3 Finish */