/* Genel Start */

.img_altili_sablon4 .campaing-dates {
  position: absolute;
  font-size: 30px;
  left: 0;
  top: 945px;
  width: 387px;
  text-align: center;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_altili_sablon4 .address {
  position: absolute;
  font-size: 23px;
  width: 337px;
  text-align: left;
  top: 952px;
  left: 415px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_altili_sablon4 .pattern-logo img {
  position: absolute;
  left: 400px;
  top: 665px;
  width: 18rem;
}

.img_altili_sablon4 .phone {
  position: absolute;
  font-size: 23px;
  width: 337px;
  text-align: left;
  top: 950px;
  left: 786px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

/* Genel Finish */

/* img_altili_sablon4 6-1 Start */

.img_altili_sablon4 .new-price6-1 {
  font-size: 18px;
  position: absolute;
  left: 234px;
  top: 273px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #036939;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon4 .old-price6-1 {
  font-size: 16px;
  position: absolute;
  left: 250px;
  top: 298px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fece0a;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon4 .title6-1 {
  font-size: 21px;
  position: absolute;
  left: 34px;
  width: 207px;
  text-align: left;
  top: 37px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon4 .titleTR6-1 {
  font-size: 15px;
  position: absolute;
  left: 34px;
  width: 125px;
  text-align: left;
  top: 67px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon4 .origin6-1 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 295px;
  top: 35px;
  font-family: 'Montserrat';
  background-color: #2c8942;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon4 .weight6-1 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 40px;
  top: 303px;
  font-family: 'Montserrat';
}

.img_altili_sablon4 .product-image6-1 img {
  position: absolute;
  max-height: 11rem;
  left: 90px;
  top: 90px;
}


/* img_altili_sablon4 6-1 Finish */

/* img_altili_sablon4 6-2 Start */

.img_altili_sablon4 .new-price6-2 {
  font-size: 18px;
  position: absolute;
  left: 556px;
  top: 273px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #036939;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon4 .old-price6-2 {
  font-size: 16px;
  position: absolute;
  left: 573px;
  top: 298px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fece0a;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon4 .title6-2 {
  font-size: 21px;
  position: absolute;
  left: 354px;
  width: 207px;
  text-align: left;
  top: 37px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon4 .titleTR6-2 {
  font-size: 15px;
  position: absolute;
  left: 352px;
  width: 125px;
  text-align: left;
  top: 67px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon4 .origin6-2 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 617px;
  top: 35px;
  font-family: 'Montserrat';
  background-color: #2c8942;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon4 .weight6-2 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 360px;
  top: 303px;
  font-family: 'Montserrat';
}

.img_altili_sablon4 .product-image6-2 img {
  position: absolute;
  max-height: 11rem;
  left: 411px;
  top: 90px;
}


/* img_altili_sablon4 6-2 Finish */

/* img_altili_sablon4 6-3 Start */

.img_altili_sablon4 .new-price6-3 {
  font-size: 18px;
  position: absolute;
  left: 876px;
  top: 273px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #036939;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon4 .old-price6-3 {
  font-size: 16px;
  position: absolute;
  left: 893px;
  top: 298px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fece0a;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon4 .title6-3 {
  font-size: 21px;
  position: absolute;
  left: 674px;
  width: 207px;
  text-align: left;
  top: 37px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon4 .titleTR6-3 {
  font-size: 15px;
  position: absolute;
  left: 672px;
  width: 125px;
  text-align: left;
  top: 67px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon4 .origin6-3 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 937px;
  top: 35px;
  font-family: 'Montserrat';
  background-color: #2c8942;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon4 .weight6-3 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 680px;
  top: 303px;
  font-family: 'Montserrat';
}

.img_altili_sablon4 .product-image6-3 img {
  position: absolute;
  max-height: 11rem;
  left: 732px;
  top: 90px;
}


/* img_altili_sablon4 6-3 Finish */

/* img_altili_sablon4 6-4 Start */

.img_altili_sablon4 .new-price6-4 {
  font-size: 18px;
  position: absolute;
  left: 234px;
  top: 566px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #036939;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon4 .old-price6-4 {
  font-size: 16px;
  position: absolute;
  left: 250px;
  top: 591px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fece0a;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon4 .title6-4 {
  font-size: 21px;
  position: absolute;
  left: 34px;
  width: 207px;
  text-align: left;
  top: 330px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon4 .titleTR6-4 {
  font-size: 15px;
  position: absolute;
  left: 34px;
  width: 125px;
  text-align: left;
  top: 360px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon4 .origin6-4 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 295px;
  top: 328px;
  font-family: 'Montserrat';
  background-color: #2c8942;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon4 .weight6-4 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 40px;
  top: 596px;
  font-family: 'Montserrat';
}

.img_altili_sablon4 .product-image6-4 img {
  position: absolute;
  max-height: 11rem;
  left: 90px;
  top: 383px;
}


/* img_altili_sablon4 6-4 Finish */

/* img_altili_sablon4 6-5 Start */

.img_altili_sablon4 .new-price6-5 {
  font-size: 18px;
  position: absolute;
  left: 556px;
  top: 566px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #036939;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon4 .old-price6-5 {
  font-size: 16px;
  position: absolute;
  left: 573px;
  top: 591px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fece0a;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon4 .title6-5 {
  font-size: 21px;
  position: absolute;
  left: 354px;
  width: 207px;
  text-align: left;
  top: 330px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon4 .titleTR6-5 {
  font-size: 15px;
  position: absolute;
  left: 352px;
  width: 125px;
  text-align: left;
  top: 360px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon4 .origin6-5 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 617px;
  top: 328px;
  font-family: 'Montserrat';
  background-color: #2c8942;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon4 .weight6-5 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 360px;
  top: 596px;
  font-family: 'Montserrat';
}

.img_altili_sablon4 .product-image6-5 img {
  position: absolute;
  max-height: 11rem;
  left: 411px;
  top: 383px;
}


/* img_altili_sablon4 6-5 Finish */

/* img_altili_sablon4 6-6 Start */

.img_altili_sablon4 .new-price6-6 {
  font-size: 18px;
  position: absolute;
  left: 876px;
  top: 566px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #036939;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon4 .old-price6-6 {
  font-size: 16px;
  position: absolute;
  left: 893px;
  top: 591px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fece0a;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon4 .title6-6 {
  font-size: 21px;
  position: absolute;
  left: 674px;
  width: 207px;
  text-align: left;
  top: 330px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon4 .titleTR6-6 {
  font-size: 15px;
  position: absolute;
  left: 672px;
  width: 125px;
  text-align: left;
  top: 360px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon4 .origin6-6 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 937px;
  top: 328px;
  font-family: 'Montserrat';
  background-color: #2c8942;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon4 .weight6-6 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 680px;
  top: 596px;
  font-family: 'Montserrat';
}

.img_altili_sablon4 .product-image6-6 img {
  position: absolute;
  max-height: 11rem;
  left: 732px;
  top: 383px;
}


/* img_altili_sablon4 6-6 Finish */