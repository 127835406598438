/* img_uclu_sablon10 Genel Start */

.img_uclu_sablon10 .campaing-dates {
  position: absolute;
  left: 30px;
  top: 940px;
  font-size: 30px;
  font-family: "Montserrat Extra Bold";
  font-weight: bold;
  color: #585454;
}

.img_uclu_sablon10 .address {
  position: absolute;
  font-size: 15px;
  top: 957px;
  left: 400px;
  width: 350px;
  text-align: center;
  color: #000;
  font-family: "Montserrat";
}

.img_uclu_sablon10 .phone {
  position: absolute;
  font-size: 26px;
  top: 943px;
  left: 805px;
  text-align: center;
  color: #000;
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 2px;
}

.img_uclu_sablon10 .pattern-logo {
  position: absolute;
  left: 63px;
  top: 770px;
}

.img_uclu_sablon10 .pattern-logo img {
  width: 250px;
}

/* img_uclu_sablon10 Genel Finish */

/* img_uclu_sablon10 3-1 Start */

.img_uclu_sablon10 .new-price3-1 {
  font-size: 47px;
  position: absolute;
  width: 229px;
  text-align: center;
  left: 68px;
  top: 501px;
  color: #000;
  background-color: #fece00;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon10 .old-price3-1 {
  left: 80px;
  top: 570px;
  font-size: 40px;
  position: absolute;
  width: 203px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #000;
  background-color: #fff4bf;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon10 .title3-1 {
  font-size: 23px;
  position: absolute;
  left: 59px;
  top: 46px;
  width: 235px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon10 .titleTR3-1 {
  font-size: 19px;
  position: absolute;
  left: 59px;
  top: 96px;
  width: 235px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon10 .origin3-1 {
  font-size: 15px;
  position: absolute;
  left: 292px;
  top: 672px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
}

.img_uclu_sablon10 .weight3-1 {
  font-size: 14px;
  position: absolute;
  left: 0px;
  top: 671px;
  width: 280px;
  text-align: center;
}

.img_uclu_sablon10 .product-image3-1 img {
  position: absolute;
  max-height: 15rem;
  left: 61px;
  top: 190px;
}

/* img_uclu_sablon10 3-1 Finish */


/* img_uclu_sablon10 3-2 Start */

.img_uclu_sablon10 .new-price3-2 {
  font-size: 47px;
  position: absolute;
  width: 229px;
  text-align: center;
  left: 390px;
  top: 501px;
  color: #000;
  background-color: #fece00;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon10 .old-price3-2 {
  left: 402px;
  top: 570px;
  font-size: 40px;
  position: absolute;
  width: 203px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #000;
  background-color: #fff4bf;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon10 .title3-2 {
  font-size: 23px;
  position: absolute;
  left: 381px;
  top: 46px;
  width: 235px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon10 .titleTR3-2 {
  font-size: 19px;
  position: absolute;
  left: 381px;
  top: 96px;
  width: 235px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon10 .origin3-2 {
  font-size: 15px;
  position: absolute;
  left: 614px;
  top: 672px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
}

.img_uclu_sablon10 .weight3-2 {
  font-size: 14px;
  position: absolute;
  left: 322px;
  top: 671px;
  width: 280px;
  text-align: center;
}

.img_uclu_sablon10 .product-image3-2 img {
  position: absolute;
  max-height: 15rem;
  left: 383px;
  top: 190px;
}

/* img_uclu_sablon10 3-2 Finish */

/* img_uclu_sablon10 3-3 Start */

.img_uclu_sablon10 .new-price3-3 {
  font-size: 47px;
  position: absolute;
  width: 229px;
  text-align: center;
  left: 712px;
  top: 501px;
  color: #000;
  background-color: #fece00;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon10 .old-price3-3 {
  left: 724px;
  top: 570px;
  font-size: 40px;
  position: absolute;
  width: 203px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #000;
  background-color: #fff4bf;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon10 .title3-3 {
  font-size: 23px;
  position: absolute;
  left: 703px;
  top: 46px;
  width: 235px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon10 .titleTR3-3 {
  font-size: 19px;
  position: absolute;
  left: 703px;
  top: 96px;
  width: 235px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon10 .origin3-3 {
  font-size: 15px;
  position: absolute;
  left: 936px;
  top: 672px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
}

.img_uclu_sablon10 .weight3-3 {
  font-size: 14px;
  position: absolute;
  left: 644px;
  top: 671px;
  width: 280px;
  text-align: center;
}

.img_uclu_sablon10 .product-image3-3 img {
  position: absolute;
  max-height: 15rem;
  left: 705px;
  top: 190px;
}

/* img_uclu_sablon10 3-3 Finish */