/* img_uclu_sablon7 Genel Start */

.img_uclu_sablon7 .campaing-dates {
  position: absolute;
  left: 30px;
  top: 950px;
  font-size: 30px;
  font-family: "Montserrat Extra Bold";
  font-weight: bold;
  color: #585454;
}

.img_uclu_sablon7 .address {
  position: absolute;
  font-size: 15px;
  top: 957px;
  left: 400px;
  width: 350px;
  text-align: center;
  color: #000;
  font-family: "Montserrat";
}

.img_uclu_sablon7 .phone {
  position: absolute;
  font-size: 22px;
  top: 953px;
  left: 815px;
  text-align: center;
  color: #f6631e;
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 2px;
}

.img_uclu_sablon7 .pattern-logo {
  position: absolute;
  left: 53px;
  top: 28px;
}

.img_uclu_sablon7 .pattern-logo img {
  width: 250px;
}

/* img_uclu_sablon7 Genel Finish */

/* img_uclu_sablon7 3-1 Start */

.img_uclu_sablon7 .new-price3-1 {
  font-size: 32px;
  position: absolute;
  width: 165px;
  text-align: center;
  left: 105px;
  top: 793px;
  color: white;
  background-color: #f16624;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon7 .old-price3-1 {
  left: 125px;
  top: 840px;
  font-size: 24px;
  position: absolute;
  width: 127px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #55565a;
  background-color: #d1d2d4;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon7 .title3-1 {
  font-size: 28px;
  position: absolute;
  left: 45px;
  top: 412px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon7 .titleTR3-1 {
  font-size: 22px;
  position: absolute;
  left: 45px;
  top: 452px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon7 .origin3-1 {
  font-size: 12px;
  position: absolute;
  left: 295px;
  top: 880px;
  width: 30px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
}

.img_uclu_sablon7 .weight3-1 {
  font-size: 14px;
  position: absolute;
  left: 85px;
  top: 880px;
  width: 200px;
  text-align: center;
}

.img_uclu_sablon7 .product-image3-1 img {
  position: absolute;
  max-height: 17rem;
  left: 53px;
  top: 515px;
}

/* img_uclu_sablon7 3-1 Finish */


/* img_uclu_sablon7 3-2 Start */

.img_uclu_sablon7 .new-price3-2 {
  font-size: 32px;
  position: absolute;
  width: 165px;
  text-align: center;
  left: 415px;
  top: 793px;
  color: white;
  background-color: #f16624;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon7 .old-price3-2 {
  left: 435px;
  top: 840px;
  font-size: 24px;
  position: absolute;
  width: 127px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #55565a;
  background-color: #d1d2d4;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon7 .title3-2 {
  font-size: 28px;
  position: absolute;
  left: 355px;
  top: 412px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon7 .titleTR3-2 {
  font-size: 22px;
  position: absolute;
  left: 355px;
  top: 452px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon7 .origin3-2 {
  font-size: 12px;
  position: absolute;
  left: 605px;
  top: 880px;
  width: 30px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
}

.img_uclu_sablon7 .weight3-2 {
  font-size: 14px;
  position: absolute;
  left: 390px;
  top: 880px;
  width: 200px;
  text-align: center;
}

.img_uclu_sablon7 .product-image3-2 img {
  position: absolute;
  max-height: 17rem;
  left: 363px;
  top: 515px;
}

/* img_uclu_sablon7 3-2 Finish */

/* img_uclu_sablon7 3-3 Start */

.img_uclu_sablon7 .new-price3-3 {
  font-size: 32px;
  position: absolute;
  width: 165px;
  text-align: center;
  left: 725px;
  top: 793px;
  color: white;
  background-color: #f16624;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon7 .old-price3-3 {
  left: 745px;
  top: 840px;
  font-size: 24px;
  position: absolute;
  width: 127px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #55565a;
  background-color: #d1d2d4;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon7 .title3-3 {
  font-size: 28px;
  position: absolute;
  left: 665px;
  top: 412px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon7 .titleTR3-3 {
  font-size: 22px;
  position: absolute;
  left: 665px;
  top: 452px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon7 .origin3-3 {
  font-size: 12px;
  position: absolute;
  left: 915px;
  top: 880px;
  width: 30px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
}

.img_uclu_sablon7 .weight3-3 {
  font-size: 14px;
  position: absolute;
  left: 700px;
  top: 880px;
  width: 200px;
  text-align: center;
}

.img_uclu_sablon7 .product-image3-3 img {
  position: absolute;
  max-height: 17rem;
  left: 673px;
  top: 515px;
}

/* img_uclu_sablon7 3-3 Finish */