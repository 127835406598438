/* Genel Start */

.img_altili_sablon11 .campaing-dates {
  position: absolute;
  font-size: 30px;
  left: 18px;
  top: 950px;
  width: 387px;
  text-align: left;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_altili_sablon11 .address {
  position: absolute;
  font-size: 20px;
  width: 337px;
  text-align: right;
  top: 970px;
  left: 640px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_altili_sablon11 .pattern-logo img {
  position: absolute;
  left: 45px;
  top: 50px;
  width: 13rem;
}

.img_altili_sablon11 .phone {
  position: absolute;
  font-size: 20px;
  width: 337px;
  text-align: right;
  top: 940px;
  left: 640px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

/* Genel Finish */

/* img_altili_sablon11 6-1 Start */

.img_altili_sablon11 .new-price6-1 {
  font-size: 32px;
  position: absolute;
  left: 296px;
  top: 366px;
  width: 161px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcd02;
  border-radius: 5px;
}

.img_altili_sablon11 .old-price6-1 {
  font-size: 26px;
  position: absolute;
  left: 330px;
  top: 416px;
  width: 127px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff2be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon11 .title6-1 {
  font-size: 17px;
  position: absolute;
  left: 38px;
  width: 443px;
  text-align: center;
  top: 484px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  overflow-wrap: break-word;
}

.img_altili_sablon11 .titleTR6-1 {
  font-size: 16px;
  position: absolute;
  left: 38px;
  width: 443px;
  text-align: center;
  top: 464px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon11 .origin6-1 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 427px;
  top: 318px;
  background-color: #f0ba00;
  text-align: center;
  font-weight: bold;
  font-family: 'Montserrat';
}

.img_altili_sablon11 .weight6-1 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 38px;
  top: 321px;
  font-family: 'Montserrat';
}

.img_altili_sablon11 .product-image6-1 img {
  position: absolute;
  max-height: 7.5rem;
  left: 65px;
  top: 336px;
}


/* img_altili_sablon11 6-1 Finish */


/* img_altili_sablon11 6-2 Start */

.img_altili_sablon11 .new-price6-2 {
  font-size: 32px;
  position: absolute;
  left: 784px;
  top: 366px;
  width: 161px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcd02;
  border-radius: 5px;
}

.img_altili_sablon11 .old-price6-2 {
  font-size: 26px;
  position: absolute;
  left: 818px;
  top: 416px;
  width: 127px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff2be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon11 .title6-2 {
  font-size: 17px;
  position: absolute;
  left: 526px;
  width: 443px;
  text-align: center;
  top: 484px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  overflow-wrap: break-word;
}

.img_altili_sablon11 .titleTR6-2 {
  font-size: 16px;
  position: absolute;
  left: 526px;
  width: 443px;
  text-align: center;
  top: 464px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon11 .origin6-2 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 923px;
  top: 318px;
  font-family: 'Montserrat';
  background-color: #f0ba00;
  text-align: center;
  font-weight: bold;
}

.img_altili_sablon11 .weight6-2 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 526px;
  top: 321px;
  font-family: 'Montserrat';
}

.img_altili_sablon11 .product-image6-2 img {
  position: absolute;
  max-height: 7.5rem;
  left: 553px;
  top: 336px;
}

/* img_altili_sablon11 6-2 Finish */


/* img_altili_sablon11 6-3 Start */

.img_altili_sablon11 .new-price6-3 {
  font-size: 32px;
  position: absolute;
  left: 296px;
  top: 570px;
  width: 161px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcd02;
  border-radius: 5px;
}

.img_altili_sablon11 .old-price6-3 {
  font-size: 26px;
  position: absolute;
  left: 330px;
  top: 620px;
  width: 127px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff2be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon11 .title6-3 {
  font-size: 17px;
  position: absolute;
  left: 38px;
  width: 443px;
  text-align: center;
  top: 688px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  overflow-wrap: break-word;
}

.img_altili_sablon11 .titleTR6-3 {
  font-size: 16px;
  position: absolute;
  left: 38px;
  width: 443px;
  text-align: center;
  top: 668px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon11 .origin6-3 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 427px;
  top: 522px;
  font-family: 'Montserrat';
  background-color: #f0ba00;
  text-align: center;
  font-weight: bold;
}

.img_altili_sablon11 .weight6-3 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 38px;
  top: 525px;
  font-family: 'Montserrat';
}

.img_altili_sablon11 .product-image6-3 img {
  position: absolute;
  max-height: 7.5rem;
  left: 65px;
  top: 540px;
}

/* img_altili_sablon11 6-3 Finish */


/* img_altili_sablon11 6-4 Start */

.img_altili_sablon11 .new-price6-4 {
  font-size: 32px;
  position: absolute;
  left: 784px;
  top: 570px;
  width: 161px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcd02;
  border-radius: 5px;
}

.img_altili_sablon11 .old-price6-4 {
  font-size: 26px;
  position: absolute;
  left: 818px;
  top: 620px;
  width: 127px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff2be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon11 .title6-4 {
  font-size: 17px;
  position: absolute;
  left: 526px;
  width: 443px;
  text-align: center;
  top: 688px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  overflow-wrap: break-word;
}

.img_altili_sablon11 .titleTR6-4 {
  font-size: 16px;
  position: absolute;
  left: 526px;
  width: 443px;
  text-align: center;
  top: 668px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon11 .origin6-4 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 923px;
  top: 522px;
  font-family: 'Montserrat';
  background-color: #f0ba00;
  text-align: center;
  font-weight: bold;
}

.img_altili_sablon11 .weight6-4 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 526px;
  top: 525px;
  font-family: 'Montserrat';
}

.img_altili_sablon11 .product-image6-4 img {
  position: absolute;
  max-height: 7.5rem;
  left: 553px;
  top: 540px;
}

/* img_altili_sablon11 6-4 Finish */

/* img_altili_sablon11 6-5 Start */

.img_altili_sablon11 .new-price6-5 {
  font-size: 32px;
  position: absolute;
  left: 296px;
  top: 774px;
  width: 161px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcd02;
  border-radius: 5px;
}

.img_altili_sablon11 .old-price6-5 {
  font-size: 26px;
  position: absolute;
  left: 330px;
  top: 824px;
  width: 127px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff2be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon11 .title6-5 {
  font-size: 17px;
  position: absolute;
  left: 38px;
  width: 443px;
  text-align: center;
  top: 891px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  overflow-wrap: break-word;
}

.img_altili_sablon11 .titleTR6-5 {
  font-size: 16px;
  position: absolute;
  left: 38px;
  width: 443px;
  text-align: center;
  top: 871px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon11 .origin6-5 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 427px;
  top: 726px;
  font-family: 'Montserrat';
  background-color: #f0ba00;
  text-align: center;
  font-weight: bold;
}

.img_altili_sablon11 .weight6-5 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 38px;
  top: 729px;
  font-family: 'Montserrat';
}

.img_altili_sablon11 .product-image6-5 img {
  position: absolute;
  max-height: 7.5rem;
  left: 65px;
  top: 744px;
}

/* img_altili_sablon11 6-5 Finish */

/* img_altili_sablon11 6-6 Start */

.img_altili_sablon11 .new-price6-6 {
  font-size: 32px;
  position: absolute;
  left: 784px;
  top: 774px;
  width: 161px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcd02;
  border-radius: 5px;
}

.img_altili_sablon11 .old-price6-6 {
  font-size: 26px;
  position: absolute;
  left: 818px;
  top: 824px;
  width: 127px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff2be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon11 .title6-6 {
  font-size: 17px;
  position: absolute;
  left: 526px;
  width: 443px;
  text-align: center;
  top: 891px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  overflow-wrap: break-word;
}

.img_altili_sablon11 .titleTR6-6 {
  font-size: 16px;
  position: absolute;
  left: 526px;
  width: 443px;
  text-align: center;
  top: 871px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon11 .origin6-6 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 923px;
  top: 726px;
  font-family: 'Montserrat';
  background-color: #f0ba00;
  text-align: center;
  font-weight: bold;
}

.img_altili_sablon11 .weight6-6 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 526px;
  top: 729px;
  font-family: 'Montserrat';
}

.img_altili_sablon11 .product-image6-6 img {
  position: absolute;
  max-height: 7.5rem;
  left: 553px;
  top: 744px;
}

/* img_altili_sablon11 6-6 Finish */