/* Genel Start */

.img_altili_sablon1 .campaing-dates {
  position: absolute;
  font-size: 30px;
  left: 570px;
  top: 942px;
  width: 387px;
  text-align: center;
  font-family: 'Montserrat Extra Bold';
}

.img_altili_sablon1 .address {
  position: absolute;
  font-size: 23px;
  width: 337px;
  text-align: left;
  top: 960px;
  left: 35px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_altili_sablon1 .pattern-logo img {
  position: absolute;
  left: 50px;
  top: 40px;
  width: 10rem;
}

.img_altili_sablon1 .phone {
  position: absolute;
  font-size: 23px;
  width: 337px;
  text-align: left;
  top: 932px;
  left: 35px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

/* Genel Finish */

/* img_altili_sablon1 6-1 Start */

.img_altili_sablon1 .new-price6-1 {
  font-size: 32px;
  position: absolute;
  left: 298px;
  top: 340px;
  width: 157px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #c40816;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon1 .old-price6-1 {
  font-size: 26px;
  position: absolute;
  left: 313px;
  top: 390px;
  width: 127px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f7e792;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon1 .title6-1 {
  font-size: 21px;
  position: absolute;
  left: 270px;
  width: 207px;
  text-align: center;
  top: 265px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon1 .titleTR6-1 {
  font-size: 16px;
  position: absolute;
  left: 270px;
  width: 207px;
  text-align: center;
  top: 300px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon1 .origin6-1 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 458px;
  top: 440px;
  font-family: 'Montserrat';
}

.img_altili_sablon1 .weight6-1 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 280px;
  top: 442px;
  font-family: 'Montserrat';
}

.img_altili_sablon1 .product-image6-1 img {
  position: absolute;
  max-height: 11rem;
  left: 50px;
  top: 270px;
}


/* img_altili_sablon1 6-2 Finish */


/* img_altili_sablon1 6-2 Start */

.img_altili_sablon1 .new-price6-2 {
  font-size: 32px;
  position: absolute;
  left: 792px;
  top: 340px;
  width: 157px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #c40816;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon1 .old-price6-2 {
  font-size: 26px;
  position: absolute;
  left: 807px;
  top: 390px;
  width: 127px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f7e792;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon1 .title6-2 {
  font-size: 21px;
  position: absolute;
  left: 765px;
  width: 207px;
  text-align: center;
  top: 265px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon1 .titleTR6-2 {
  font-size: 16px;
  position: absolute;
  left: 765px;
  width: 207px;
  text-align: center;
  top: 300px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon1 .origin6-2 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 953px;
  top: 440px;
  font-family: 'Montserrat';
}

.img_altili_sablon1 .weight6-2 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 777px;
  top: 442px;
  font-family: 'Montserrat';
}

.img_altili_sablon1 .product-image6-2 img {
  position: absolute;
  max-height: 11rem;
  left: 547px;
  top: 270px;
}

/* img_altili_sablon1 6-2 Finish */


/* img_altili_sablon1 6-3 Start */

.img_altili_sablon1 .new-price6-3 {
  font-size: 32px;
  position: absolute;
  left: 298px;
  top: 565px;
  width: 157px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #c40816;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon1 .old-price6-3 {
  font-size: 26px;
  position: absolute;
  left: 313px;
  top: 615px;
  width: 127px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f7e792;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon1 .title6-3 {
  font-size: 21px;
  position: absolute;
  left: 270px;
  width: 207px;
  text-align: center;
  top: 490px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon1 .titleTR6-3 {
  font-size: 16px;
  position: absolute;
  left: 270px;
  width: 207px;
  text-align: center;
  top: 525px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon1 .origin6-3 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 458px;
  top: 665px;
  font-family: 'Montserrat';
}

.img_altili_sablon1 .weight6-3 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 280px;
  top: 668px;
  font-family: 'Montserrat';
}

.img_altili_sablon1 .product-image6-3 img {
  position: absolute;
  max-height: 11rem;
  left: 50px;
  top: 490px;
}

/* img_altili_sablon1 6-3 Finish */


/* img_altili_sablon1 6-4 Start */

.img_altili_sablon1 .new-price6-4 {
  font-size: 32px;
  position: absolute;
  left: 792px;
  top: 565px;
  width: 157px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #c40816;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon1 .old-price6-4 {
  font-size: 26px;
  position: absolute;
  left: 807px;
  top: 615px;
  width: 127px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f7e792;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon1 .title6-4 {
  font-size: 21px;
  position: absolute;
  left: 765px;
  width: 207px;
  text-align: center;
  top: 490px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon1 .titleTR6-4 {
  font-size: 16px;
  position: absolute;
  left: 765px;
  width: 207px;
  text-align: center;
  top: 525px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon1 .origin6-4 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 953px;
  top: 665px;
  font-family: 'Montserrat';
}

.img_altili_sablon1 .weight6-4 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 777px;
  top: 668px;
  font-family: 'Montserrat';
}

.img_altili_sablon1 .product-image6-4 img {
  position: absolute;
  max-height: 11rem;
  left: 547px;
  top: 490px;
}

/* img_altili_sablon1 6-4 Finish */

/* img_altili_sablon1 6-5 Start */

.img_altili_sablon1 .new-price6-5 {
  font-size: 32px;
  position: absolute;
  left: 298px;
  top: 785px;
  width: 157px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #c40816;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon1 .old-price6-5 {
  font-size: 26px;
  position: absolute;
  left: 313px;
  top: 835px;
  width: 127px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f7e792;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon1 .title6-5 {
  font-size: 21px;
  position: absolute;
  left: 270px;
  width: 207px;
  text-align: center;
  top: 707px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon1 .titleTR6-5 {
  font-size: 16px;
  position: absolute;
  left: 270px;
  width: 207px;
  text-align: center;
  top: 747px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon1 .origin6-5 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 458px;
  top: 887px;
  font-family: 'Montserrat';
}

.img_altili_sablon1 .weight6-5 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 280px;
  top: 889px;
  font-family: 'Montserrat';
}

.img_altili_sablon1 .product-image6-5 img {
  position: absolute;
  max-height: 11rem;
  left: 50px;
  top: 715px;
}

/* img_altili_sablon1 6-5 Finish */

/* img_altili_sablon1 6-6 Start */

.img_altili_sablon1 .new-price6-6 {
  font-size: 32px;
  position: absolute;
  left: 792px;
  top: 785px;
  width: 157px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #c40816;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon1 .old-price6-6 {
  font-size: 26px;
  position: absolute;
  left: 807px;
  top: 835px;
  width: 127px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f7e792;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon1 .title6-6 {
  font-size: 21px;
  position: absolute;
  left: 765px;
  width: 207px;
  text-align: center;
  top: 707px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon1 .titleTR6-6 {
  font-size: 16px;
  position: absolute;
  left: 765px;
  width: 207px;
  text-align: center;
  top: 747px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon1 .origin6-6 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 953px;
  top: 887px;
  font-family: 'Montserrat';
}

.img_altili_sablon1 .weight6-6 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 777px;
  top: 889px;
  font-family: 'Montserrat';
}

.img_altili_sablon1 .product-image6-6 img {
  position: absolute;
  max-height: 11rem;
  left: 547px;
  top: 715px;
}

/* img_altili_sablon1 6-6 Finish */