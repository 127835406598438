/* img_uclu_sablon5 Genel Start */

.img_uclu_sablon5 .campaing-dates {
  position: absolute;
  left: 30px;
  top: 950px;
  font-size: 30px;
  font-family: "Montserrat Extra Bold";
  font-weight: bold;
  color: #fff;
}

.img_uclu_sablon5 .address {
  position: absolute;
  font-size: 15px;
  top: 957px;
  left: 400px;
  width: 350px;
  text-align: center;
  color: #fff;
  font-family: "Montserrat";
}

.img_uclu_sablon5 .phone {
  position: absolute;
  font-size: 22px;
  top: 953px;
  left: 815px;
  text-align: center;
  color: #fff;
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 2px;
}

.img_uclu_sablon5 .pattern-logo {
  position: absolute;
  left: 363px;
  top: 8px;
}

.img_uclu_sablon5 .pattern-logo img {
  width: 250px;
}

/* img_uclu_sablon5 Genel Finish */

/* img_uclu_sablon5 3-1 Start */

.img_uclu_sablon5 .new-price3-1 {
  font-size: 35px;
  position: absolute;
  width: 170px;
  text-align: center;
  left: 288px;
  top: 301px;
  color: white;
  background-color: #d35a2a;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon5 .old-price3-1 {
  left: 303px;
  top: 355px;
  font-size: 29px;
  position: absolute;
  width: 140px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #6f6767;
  background-color: #f4bcab;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon5 .title3-1 {
  font-size: 20px;
  position: absolute;
  left: 265px;
  top: 200px;
  width: 210px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon5 .titleTR3-1 {
  font-size: 18px;
  position: absolute;
  left: 265px;
  top: 230px;
  width: 210px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon5 .origin3-1 {
  font-size: 12px;
  position: absolute;
  left: 450px;
  top: 470px;
  width: 30px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
  color: #4c9e68;
}

.img_uclu_sablon5 .weight3-1 {
  font-size: 13px;
  position: absolute;
  left: 260px;
  top: 470px;
  width: 180px;
  text-align: center;
  color: #4c9e68;
}

.img_uclu_sablon5 .product-image3-1 img {
  position: absolute;
  max-height: 12rem;
  left: 53px;
  top: 215px;
}

/* img_uclu_sablon5 3-1 Finish */


/* img_uclu_sablon5 3-2 Start */

.img_uclu_sablon5 .new-price3-2 {
  font-size: 35px;
  position: absolute;
  width: 170px;
  text-align: center;
  left: 763px;
  top: 301px;
  color: white;
  background-color: #d35a2a;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon5 .old-price3-2 {
  left: 778px;
  top: 355px;
  font-size: 29px;
  position: absolute;
  width: 140px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #6f6767;
  background-color: #f4bcab;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon5 .title3-2 {
  font-size: 20px;
  position: absolute;
  left: 740px;
  top: 200px;
  width: 210px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon5 .titleTR3-2 {
  font-size: 18px;
  position: absolute;
  left: 740px;
  top: 230px;
  width: 210px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon5 .origin3-2 {
  font-size: 12px;
  position: absolute;
  left: 925px;
  top: 470px;
  width: 30px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
  color: #4c9e68;
}

.img_uclu_sablon5 .weight3-2 {
  font-size: 13px;
  position: absolute;
  left: 735px;
  top: 470px;
  width: 180px;
  text-align: center;
  color: #4c9e68;
}

.img_uclu_sablon5 .product-image3-2 img {
  position: absolute;
  max-height: 12rem;
  left: 528px;
  top: 215px;
}

/* img_uclu_sablon5 3-2 Finish */

/* img_uclu_sablon5 3-3 Start */

.img_uclu_sablon5 .new-price3-3 {
  font-size: 35px;
  position: absolute;
  width: 170px;
  text-align: center;
  left: 288px;
  top: 651px;
  color: white;
  background-color: #d35a2a;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon5 .old-price3-3 {
  left: 303px;
  top: 705px;
  font-size: 29px;
  position: absolute;
  width: 140px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #6f6767;
  background-color: #f4bcab;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon5 .title3-3 {
  font-size: 20px;
  position: absolute;
  left: 265px;
  top: 550px;
  width: 210px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon5 .titleTR3-3 {
  font-size: 18px;
  position: absolute;
  left: 265px;
  top: 580px;
  width: 210px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon5 .origin3-3 {
  font-size: 12px;
  position: absolute;
  left: 450px;
  top: 825px;
  width: 30px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
  color: #4c9e68;
}

.img_uclu_sablon5 .weight3-3 {
  font-size: 13px;
  position: absolute;
  left: 260px;
  top: 820px;
  width: 180px;
  text-align: center;
  color: #4c9e68;
}

.img_uclu_sablon5 .product-image3-3 img {
  position: absolute;
  max-height: 12rem;
  left: 53px;
  top: 565px;
}

/* img_uclu_sablon5 3-3 Finish */