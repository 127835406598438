/* img_tekli_sablon2 Start */

.img_tekli_sablon2 .new-price {
  font-size: 61px;
  position: absolute;
  width: 300px;
  text-align: center;
  left: 644px;
  top: 473px;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fff;
  background-color: #fe0000;
  border-radius: 11px;
}

.img_tekli_sablon2 .old-price {
  font-size: 50px;
  position: absolute;
  width: 245px;
  text-align: center;
  left: 669px;
  top: 570px;
  text-decoration: line-through;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fe0000;
  background-color: #c3f97f;
  border-radius: 11px;
}

.img_tekli_sablon2 .title {
  font-size: 50px;
  position: absolute;
  left: 477px;
  top: 312px;
  width: 517px;
  text-align: center;
  text-transform: capitalize;
  color: #29532f;
  font-family: 'Montserrat Extra Bold';
  font-weight: bolder;
  line-height: 55px;
  overflow-wrap: break-word;
}

.img_tekli_sablon2 .titleTR {
  font-size: 40px;
  position: absolute;
  left: 477px;
  top: 384px;
  width: 517px;
  text-align: center;
  text-transform: capitalize;
  color: #29532f;
  font-family: 'Montserrat Extra Bold';
  font-weight: bolder;
  line-height: 55px;
  overflow-wrap: break-word;
}

.img_tekli_sablon2 .origin {
  font-size: 25px;
  position: absolute;
  left: 477px;
  top: 435px;
  width: 517px;
  text-align: center;
  text-transform: uppercase;
  color: #29532f;
  font-family: 'Montserrat Extra Bold';
  font-weight: bolder;
  overflow-wrap: break-word;
}

.img_tekli_sablon2 .weight {
  font-size: 17px;
  position: absolute;
  width: 300px;
  text-align: center;
  left: 644px;
  top: 697px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_tekli_sablon2 .pattern-logo {
  position: absolute;
  left: 74px;
  top: 885px;
}

.img_tekli_sablon2 .pattern-logo img {
  min-width: 200px;
  max-height: 200px;
}

.img_tekli_sablon2 .product-image img {
  position: absolute;
  max-height: 25rem;
  left: 93px;
  top: 333px;
}

.img_tekli_sablon2 .campaing-dates {
  position: absolute;
  font-size: 34px;
  width: 440px;
  left: 550px;
  top: 855px;
  text-align: center;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_tekli_sablon2 .address {
  position: absolute;
  font-size: 25px;
  width: 360px;
  text-align: right;
  top: 950px;
  left: 625px;
  font-family: 'Montserrat Extra Bold';
  color: #fe0000;
}

.img_tekli_sablon2 .phone {
  position: absolute;
  font-size: 25px;
  width: 200px;
  text-align: center;
  top: 777px;
  left: 85px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

/* img_tekli_sablon2 Finish */