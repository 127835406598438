/* img_uclu_sablon4 Genel Start */

.img_uclu_sablon4 .campaing-dates {
  position: absolute;
  left: 30px;
  top: 950px;
  font-size: 30px;
  font-family: "Montserrat Extra Bold";
  font-weight: bold;
  color: #585454;
}

.img_uclu_sablon4 .address {
  position: absolute;
  font-size: 15px;
  top: 957px;
  left: 400px;
  width: 350px;
  text-align: center;
  color: #000;
  font-family: "Montserrat";
}

.img_uclu_sablon4 .phone {
  position: absolute;
  font-size: 22px;
  top: 953px;
  left: 815px;
  text-align: center;
  color: red;
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 2px;
}

.img_uclu_sablon4 .pattern-logo {
  position: absolute;
  left: 363px;
  top: 8px;
}

.img_uclu_sablon4 .pattern-logo img {
  width: 250px;
}

/* img_uclu_sablon4 Genel Finish */

/* img_uclu_sablon4 3-1 Start */

.img_uclu_sablon4 .new-price3-1 {
  font-size: 26px;
  position: absolute;
  width: 150px;
  text-align: center;
  left: 95px;
  top: 801px;
  color: white;
  background-color: red;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon4 .old-price3-1 {
  left: 112px;
  top: 836px;
  font-size: 20px;
  position: absolute;
  width: 120px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #6f6767;
  background-color: #beaeae;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon4 .title3-1 {
  font-size: 29px;
  position: absolute;
  left: 25px;
  top: 435px;
  width: 290px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon4 .titleTR3-1 {
  font-size: 26px;
  position: absolute;
  left: 25px;
  top: 475px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon4 .origin3-1 {
  font-size: 18px;
  position: absolute;
  left: 290px;
  top: 885px;
  width: 50px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
  color: red;
}

.img_uclu_sablon4 .weight3-1 {
  font-size: 15px;
  position: absolute;
  left: 40px;
  top: 884px;
  width: 250px;
  text-align: center;
}

.img_uclu_sablon4 .product-image3-1 img {
  position: absolute;
  max-height: 16rem;
  left: 45px;
  top: 537px;
}

/* img_uclu_sablon4 3-1 Finish */


/* img_uclu_sablon4 3-2 Start */

.img_uclu_sablon4 .new-price3-2 {
  font-size: 26px;
  position: absolute;
  width: 150px;
  text-align: center;
  left: 425px;
  top: 801px;
  color: white;
  background-color: red;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon4 .old-price3-2 {
  left: 442px;
  top: 836px;
  font-size: 20px;
  position: absolute;
  width: 120px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #6f6767;
  background-color: #beaeae;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon4 .title3-2 {
  font-size: 29px;
  position: absolute;
  left: 355px;
  top: 435px;
  width: 290px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon4 .titleTR3-2 {
  font-size: 26px;
  position: absolute;
  left: 365px;
  top: 475px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon4 .origin3-2 {
  font-size: 18px;
  position: absolute;
  left: 610px;
  top: 885px;
  width: 50px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: red;
}

.img_uclu_sablon4 .weight3-2 {
  font-size: 15px;
  position: absolute;
  left: 375px;
  top: 884px;
  width: 250px;
  text-align: center;
}

.img_uclu_sablon4 .product-image3-2 img {
  position: absolute;
  max-height: 16rem;
  left: 375px;
  top: 537px;
}

/* img_uclu_sablon4 3-2 Finish */

/* img_uclu_sablon4 3-3 Start */

.img_uclu_sablon4 .new-price3-3 {
  font-size: 26px;
  position: absolute;
  width: 150px;
  text-align: center;
  left: 755px;
  top: 801px;
  color: white;
  background-color: red;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon4 .old-price3-3 {
  left: 772px;
  top: 836px;
  font-size: 20px;
  position: absolute;
  width: 120px;
  padding: 0 15px 0 15px;
  text-align: center;
  color: #6f6767;
  background-color: #beaeae;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon4 .title3-3 {
  font-size: 29px;
  position: absolute;
  left: 685px;
  top: 435px;
  width: 290px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon4 .titleTR3-3 {
  font-size: 26px;
  position: absolute;
  left: 679px;
  top: 475px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  text-align: center;
}

.img_uclu_sablon4 .origin3-3 {
  font-size: 18px;
  position: absolute;
  left: 950px;
  top: 885px;
  width: 50px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: red;
}

.img_uclu_sablon4 .weight3-3 {
  font-size: 15px;
  position: absolute;
  left: 700px;
  top: 884px;
  width: 250px;
  text-align: center;
}

.img_uclu_sablon4 .product-image3-3 img {
  position: absolute;
  max-height: 16rem;
  left: 705px;
  top: 537px;
}

/* img_uclu_sablon4 3-3 Finish */