/* img_uclu_sablon2 Genel Start */

.img_uclu_sablon2 .campaing-dates {
  position: absolute;
  left: 30px;
  top: 950px;
  font-size: 30px;
  font-family: "Montserrat Extra Bold";
  font-weight: bold;
  color: #585454;
}

.img_uclu_sablon2 .address {
  position: absolute;
  font-size: 15px;
  top: 957px;
  left: 400px;
  width: 350px;
  text-align: center;
  color: #000;
  font-family: "Montserrat";
}

.img_uclu_sablon2 .phone {
  position: absolute;
  font-size: 22px;
  top: 953px;
  left: 815px;
  text-align: center;
  color: red;
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 2px;
}

.img_uclu_sablon2 .pattern-logo {
  position: absolute;
  left: 13px;
  top: 15px;
}

.img_uclu_sablon2 .pattern-logo img {
  width: 250px;
}

/* img_uclu_sablon2 Genel Finish */

/* img_uclu_sablon2 3-1 Start */

.img_uclu_sablon2 .new-price3-1 {
  font-size: 30px;
  position: absolute;
  width: 150px;
  text-align: center;
  left: 733px;
  top: 161px;
  color: #000;
  background-color: #ffd599;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon2 .old-price3-1 {
  left: 750px;
  top: 210px;
  font-size: 26px;
  position: absolute;
  width: 120px;
  text-align: center;
  color: #000;
  background-color: #ffbf64;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon2 .title3-1 {
  font-size: 28px;
  position: absolute;
  left: 670px;
  top: 73px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon2 .titleTR3-1 {
  font-size: 25px;
  position: absolute;
  left: 670px;
  top: 108px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon2 .origin3-1 {
  font-size: 12px;
  position: absolute;
  left: 940px;
  top: 290px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
}

.img_uclu_sablon2 .weight3-1 {
  font-size: 15px;
  position: absolute;
  left: 680px;
  top: 278px;
  width: 260px;
  text-align: center;
}

.img_uclu_sablon2 .product-image3-1 img {
  position: absolute;
  max-height: 15rem;
  left: 370px;
  top: 57px;
}

/* img_uclu_sablon2 3-1 Finish */


/* img_uclu_sablon2 3-2 Start */

.img_uclu_sablon2 .new-price3-2 {
  font-size: 30px;
  position: absolute;
  width: 150px;
  text-align: center;
  left: 733px;
  top: 461px;
  color: #000;
  background-color: #ffd599;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon2 .old-price3-2 {
  left: 750px;
  top: 510px;
  font-size: 26px;
  position: absolute;
  width: 120px;
  text-align: center;
  color: #000;
  background-color: #ffbf64;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon2 .title3-2 {
  font-size: 28px;
  position: absolute;
  left: 670px;
  top: 373px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon2 .titleTR3-2 {
  font-size: 22px;
  position: absolute;
  left: 675px;
  top: 408px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon2 .origin3-2 {
  font-size: 12px;
  position: absolute;
  left: 940px;
  top: 590px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_uclu_sablon2 .weight3-2 {
  font-size: 15px;
  position: absolute;
  left: 680px;
  top: 578px;
  width: 260px;
  text-align: center;
}

.img_uclu_sablon2 .product-image3-2 img {
  position: absolute;
  max-height: 15rem;
  left: 370px;
  top: 357px;
}

/* img_uclu_sablon2 3-2 Finish */

/* img_uclu_sablon2 3-3 Start */

.img_uclu_sablon2 .new-price3-3 {
  font-size: 30px;
  position: absolute;
  width: 150px;
  text-align: center;
  left: 733px;
  top: 761px;
  color: #000;
  background-color: #ffd599;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon2 .old-price3-3 {
  left: 750px;
  top: 810px;
  font-size: 26px;
  position: absolute;
  width: 120px;
  text-align: center;
  color: #000;
  background-color: #ffbf64;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon2 .title3-3 {
  font-size: 28px;
  position: absolute;
  left: 670px;
  top: 673px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon2 .titleTR3-3 {
  font-size: 22px;
  position: absolute;
  left: 670px;
  top: 708px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon2 .origin3-3 {
  font-size: 12px;
  position: absolute;
  left: 940px;
  top: 888px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_uclu_sablon2 .weight3-3 {
  font-size: 15px;
  position: absolute;
  left: 680px;
  top: 878px;
  width: 260px;
  text-align: center;
}

.img_uclu_sablon2 .product-image3-3 img {
  position: absolute;
  max-height: 15rem;
  left: 370px;
  top: 657px;
}

/* img_uclu_sablon2 3-3 Finish */