/* img_uclu_sablon11 Genel Start */

.img_uclu_sablon11 .campaing-dates {
  position: absolute;
  left: 30px;
  top: 950px;
  font-size: 30px;
  font-family: "Montserrat Extra Bold";
  font-weight: bold;
  color: #fff;
}

.img_uclu_sablon11 .address {
  position: absolute;
  font-size: 15px;
  top: 950px;
  left: 450px;
  width: 350px;
  text-align: center;
  color: #fff;
  font-family: "Montserrat";
}

.img_uclu_sablon11 .phone {
  position: absolute;
  font-size: 26px;
  top: 950px;
  left: 795px;
  text-align: center;
  color: #fff;
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 2px;
}

.img_uclu_sablon11 .pattern-logo {
  position: absolute;
  left: 123px;
  top: 790px;
}

.img_uclu_sablon11 .pattern-logo img {
  width: 250px;
}

/* img_uclu_sablon11 Genel Finish */

/* img_uclu_sablon11 3-1 Start */

.img_uclu_sablon11 .new-price3-1 {
  font-size: 35px;
  position: absolute;
  width: 180px;
  text-align: center;
  left: 139px;
  top: 501px;
  color: #fff;
  background-color: #1a4b73;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon11 .old-price3-1 {
  left: 154px;
  top: 554px;
  font-size: 29px;
  position: absolute;
  width: 149px;
  text-align: center;
  color: #000;
  background-color: #b8bece;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon11 .title3-1 {
  font-size: 25px;
  position: absolute;
  left: 106px;
  top: 90px;
  width: 263px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon11 .titleTR3-1 {
  font-size: 22px;
  position: absolute;
  left: 106px;
  top: 134px;
  width: 263px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon11 .origin3-1 {
  font-size: 15px;
  position: absolute;
  left: 324px;
  top: 31px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
  color: #fff;
  background: #1a4c71;
}

.img_uclu_sablon11 .weight3-1 {
  font-size: 16px;
  position: absolute;
  left: 100px;
  top: 680px;
  width: 280px;
  text-align: center;
}

.img_uclu_sablon11 .product-image3-1 img {
  position: absolute;
  max-height: 15rem;
  left: 119px;
  top: 190px;
}

/* img_uclu_sablon11 3-1 Finish */


/* img_uclu_sablon11 3-2 Start */

.img_uclu_sablon11 .new-price3-2 {
  font-size: 35px;
  position: absolute;
  width: 180px;
  text-align: center;
  left: 439px;
  top: 501px;
  color: #fff;
  background-color: #1a4b73;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon11 .old-price3-2 {
  left: 454px;
  top: 554px;
  font-size: 29px;
  position: absolute;
  width: 149px;
  text-align: center;
  color: #000;
  background-color: #b8bece;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon11 .title3-2 {
  font-size: 25px;
  position: absolute;
  left: 401px;
  top: 90px;
  width: 263px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon11 .titleTR3-2 {
  font-size: 22px;
  position: absolute;
  left: 401px;
  top: 134px;
  width: 263px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon11 .origin3-2 {
  font-size: 15px;
  position: absolute;
  left: 619px;
  top: 31px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
  color: #fff;
  background: #1a4c71;
}

.img_uclu_sablon11 .weight3-2 {
  font-size: 16px;
  position: absolute;
  left: 395px;
  top: 680px;
  width: 280px;
  text-align: center;
}

.img_uclu_sablon11 .product-image3-2 img {
  position: absolute;
  max-height: 15rem;
  left: 409px;
  top: 190px;
}

/* img_uclu_sablon11 3-2 Finish */

/* img_uclu_sablon11 3-3 Start */

.img_uclu_sablon11 .new-price3-3 {
  font-size: 35px;
  position: absolute;
  width: 180px;
  text-align: center;
  left: 739px;
  top: 501px;
  color: #fff;
  background-color: #1a4b73;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon11 .old-price3-3 {
  left: 754px;
  top: 554px;
  font-size: 29px;
  position: absolute;
  width: 149px;
  text-align: center;
  color: #000;
  background-color: #b8bece;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon11 .title3-3 {
  font-size: 25px;
  position: absolute;
  left: 696px;
  top: 90px;
  width: 263px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon11 .titleTR3-3 {
  font-size: 22px;
  position: absolute;
  left: 696px;
  top: 134px;
  width: 263px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon11 .origin3-3 {
  font-size: 15px;
  position: absolute;
  left: 914px;
  top: 31px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
  color: #fff;
  background: #1a4c71;
}

.img_uclu_sablon11 .weight3-3 {
  font-size: 16px;
  position: absolute;
  left: 690px;
  top: 680px;
  width: 280px;
  text-align: center;
}

.img_uclu_sablon11 .product-image3-3 img {
  position: absolute;
  max-height: 15rem;
  left: 710px;
  top: 190px;
}

/* img_uclu_sablon11 3-3 Finish */