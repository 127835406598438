/* img_uclu_sablon12 Genel Start */

.img_uclu_sablon12 .campaing-dates {
  position: absolute;
  left: 30px;
  top: 950px;
  font-size: 30px;
  font-family: "Montserrat Extra Bold";
  font-weight: bold;
  color: #fff;
}

.img_uclu_sablon12 .address {
  position: absolute;
  font-size: 15px;
  top: 950px;
  left: 450px;
  width: 350px;
  text-align: center;
  color: #fff;
  font-family: "Montserrat";
}

.img_uclu_sablon12 .phone {
  position: absolute;
  font-size: 26px;
  top: 950px;
  left: 795px;
  text-align: center;
  color: #fff;
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 2px;
}

.img_uclu_sablon12 .pattern-logo {
  position: absolute;
  left: 123px;
  top: 10px;
}

.img_uclu_sablon12 .pattern-logo img {
  width: 250px;
}

/* img_uclu_sablon12 Genel Finish */

/* img_uclu_sablon12 3-1 Start */

.img_uclu_sablon12 .new-price3-1 {
  font-size: 35px;
  position: absolute;
  width: 180px;
  text-align: center;
  left: 122px;
  top: 690px;
  color: #fff;
  background-color: #ed1b24;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon12 .old-price3-1 {
  left: 139px;
  top: 743px;
  font-size: 29px;
  position: absolute;
  width: 149px;
  text-align: center;
  color: #000;
  background-color: #f5967c;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon12 .title3-1 {
  font-size: 25px;
  position: absolute;
  left: 80px;
  top: 278px;
  width: 263px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon12 .titleTR3-1 {
  font-size: 22px;
  position: absolute;
  left: 80px;
  top: 308px;
  width: 263px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon12 .origin3-1 {
  font-size: 15px;
  position: absolute;
  left: 295px;
  top: 199px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
  color: #fff;
  background: #ec1d27;
}

.img_uclu_sablon12 .weight3-1 {
  font-size: 16px;
  position: absolute;
  left: 80px;
  top: 850px;
  width: 260px;
  text-align: center;
}

.img_uclu_sablon12 .product-image3-1 img {
  position: absolute;
  max-height: 15rem;
  left: 90px;
  top: 370px;
}

/* img_uclu_sablon12 3-1 Finish */


/* img_uclu_sablon12 3-2 Start */

.img_uclu_sablon12 .new-price3-2 {
  font-size: 35px;
  position: absolute;
  width: 180px;
  text-align: center;
  left: 412px;
  top: 690px;
  color: #fff;
  background-color: #ed1b24;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon12 .old-price3-2 {
  left: 429px;
  top: 743px;
  font-size: 29px;
  position: absolute;
  width: 149px;
  text-align: center;
  color: #000;
  background-color: #f5967c;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon12 .title3-2 {
  font-size: 25px;
  position: absolute;
  left: 370px;
  top: 278px;
  width: 263px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon12 .titleTR3-2 {
  font-size: 22px;
  position: absolute;
  left: 370px;
  top: 308px;
  width: 263px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon12 .origin3-2 {
  font-size: 15px;
  position: absolute;
  left: 585px;
  top: 199px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
  color: #fff;
  background: #ec1d27;
}

.img_uclu_sablon12 .weight3-2 {
  font-size: 16px;
  position: absolute;
  left: 370px;
  top: 850px;
  width: 260px;
  text-align: center;
}

.img_uclu_sablon12 .product-image3-2 img {
  position: absolute;
  max-height: 15rem;
  left: 380px;
  top: 370px;
}

/* img_uclu_sablon12 3-2 Finish */

/* img_uclu_sablon12 3-3 Start */

.img_uclu_sablon12 .new-price3-3 {
  font-size: 35px;
  position: absolute;
  width: 180px;
  text-align: center;
  left: 702px;
  top: 690px;
  color: #fff;
  background-color: #ed1b24;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon12 .old-price3-3 {
  left: 719px;
  top: 743px;
  font-size: 29px;
  position: absolute;
  width: 149px;
  text-align: center;
  color: #000;
  background-color: #f5967c;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon12 .title3-3 {
  font-size: 25px;
  position: absolute;
  left: 660px;
  top: 278px;
  width: 263px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon12 .titleTR3-3 {
  font-size: 22px;
  position: absolute;
  left: 660px;
  top: 308px;
  width: 263px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon12 .origin3-3 {
  font-size: 15px;
  position: absolute;
  left: 875px;
  top: 199px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
  color: #fff;
  background: #ec1d27;
}

.img_uclu_sablon12 .weight3-3 {
  font-size: 16px;
  position: absolute;
  left: 660px;
  top: 850px;
  width: 260px;
  text-align: center;
}

.img_uclu_sablon12 .product-image3-3 img {
  position: absolute;
  max-height: 15rem;
  left: 675px;
  top: 370px;
}

/* img_uclu_sablon12 3-3 Finish */