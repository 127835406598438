/* img_uclu_sablon3 Genel Start */

.img_uclu_sablon3 .campaing-dates {
  position: absolute;
  left: 30px;
  top: 950px;
  font-size: 30px;
  font-family: "Montserrat Extra Bold";
  font-weight: bold;
  color: #585454;
}

.img_uclu_sablon3 .address {
  position: absolute;
  font-size: 15px;
  top: 957px;
  left: 400px;
  width: 350px;
  text-align: center;
  color: #000;
  font-family: "Montserrat";
}

.img_uclu_sablon3 .phone {
  position: absolute;
  font-size: 22px;
  top: 953px;
  left: 815px;
  text-align: center;
  color: red;
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 2px;
}

.img_uclu_sablon3 .pattern-logo {
  position: absolute;
  left: 363px;
  top: 8px;
}

.img_uclu_sablon3 .pattern-logo img {
  width: 250px;
}

/* img_uclu_sablon3 Genel Finish */

/* img_uclu_sablon3 3-1 Start */

.img_uclu_sablon3 .new-price3-1 {
  font-size: 45px;
  position: absolute;
  width: 280px;
  text-align: center;
  left: 33px;
  top: 715px;
  color: white;
  background-color: #007201;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon3 .old-price3-1 {
  left: 53px;
  top: 780px;
  font-size: 40px;
  position: absolute;
  width: 240px;
  text-align: center;
  color: #007201;
  background-color: white;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon3 .title3-1 {
  font-size: 24px;
  position: absolute;
  left: 30px;
  top: 290px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon3 .titleTR3-1 {
  font-size: 22px;
  position: absolute;
  left: 30px;
  top: 330px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon3 .origin3-1 {
  font-size: 14px;
  position: absolute;
  left: 280px;
  top: 665px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  overflow-wrap: break-word;
  font-weight: bold;
  color: #007201;
}

.img_uclu_sablon3 .weight3-1 {
  font-size: 18px;
  position: absolute;
  left: 30px;
  top: 860px;
  width: 280px;
  text-align: center;
}

.img_uclu_sablon3 .product-image3-1 img {
  position: absolute;
  max-height: 18rem;
  left: 28px;
  top: 375px;
}

/* img_uclu_sablon3 3-1 Finish */


/* img_uclu_sablon3 3-2 Start */

.img_uclu_sablon3 .new-price3-2 {
  font-size: 45px;
  position: absolute;
  width: 280px;
  text-align: center;
  left: 363px;
  top: 715px;
  color: white;
  background-color: #007201;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon3 .old-price3-2 {
  left: 383px;
  top: 780px;
  font-size: 40px;
  position: absolute;
  width: 240px;
  text-align: center;
  color: #007201;
  background-color: white;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon3 .title3-2 {
  font-size: 24px;
  position: absolute;
  left: 360px;
  top: 290px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon3 .titleTR3-2 {
  font-size: 22px;
  position: absolute;
  left: 360px;
  top: 330px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon3 .origin3-2 {
  font-size: 14px;
  position: absolute;
  left: 610px;
  top: 665px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: #007201;
}

.img_uclu_sablon3 .weight3-2 {
  font-size: 18px;
  position: absolute;
  left: 360px;
  top: 860px;
  width: 280px;
  text-align: center;
}

.img_uclu_sablon3 .product-image3-2 img {
  position: absolute;
  max-height: 18rem;
  left: 358px;
  top: 375px;
}

/* img_uclu_sablon3 3-2 Finish */

/* img_uclu_sablon3 3-3 Start */

.img_uclu_sablon3 .new-price3-3 {
  font-size: 45px;
  position: absolute;
  width: 280px;
  text-align: center;
  left: 693px;
  top: 715px;
  color: white;
  background-color: #007201;
  border-radius: 10px;
  font-family: "Montserrat Extra Bold";
}

.img_uclu_sablon3 .old-price3-3 {
  left: 713px;
  top: 780px;
  font-size: 40px;
  position: absolute;
  width: 240px;
  text-align: center;
  color: #007201;
  background-color: white;
  border-radius: 10px;
  text-decoration: line-through;
  font-family: "Montserrat";
}

.img_uclu_sablon3 .title3-3 {
  font-size: 24px;
  position: absolute;
  left: 690px;
  top: 290px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  text-align: center;
}

.img_uclu_sablon3 .titleTR3-3 {
  font-size: 22px;
  position: absolute;
  left: 690px;
  top: 330px;
  width: 280px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  text-align: center;
  font-weight: bold;
}

.img_uclu_sablon3 .origin3-3 {
  font-size: 14px;
  position: absolute;
  left: 942px;
  top: 665px;
  width: 40px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: #007201;
}

.img_uclu_sablon3 .weight3-3 {
  font-size: 18px;
  position: absolute;
  left: 690px;
  top: 860px;
  width: 280px;
  text-align: center;
}

.img_uclu_sablon3 .product-image3-3 img {
  position: absolute;
  max-height: 18rem;
  left: 688px;
  top: 375px;
}

/* img_uclu_sablon3 3-3 Finish */