/* img_tekli_sablon10 Start */

.img_tekli_sablon10 .new-price {
  font-size: 35px;
  position: absolute;
  width: 200px;
  text-align: center;
  left: 450px;
  top: 785px;
  font-family: 'Montserrat Extra Bold';
  color: #000000;
  background-color: #ffcd02;
  border-radius: 11px;
}

.img_tekli_sablon10 .old-price {
  font-size: 30px;
  position: absolute;
  width: 180px;
  text-align: center;
  left: 460px;
  top: 835px;
  text-decoration: line-through;
  font-family: 'Montserrat Extra Bold';
  color: #000000;
  background-color: #fff4bf;
  border-radius: 11px;
}

.img_tekli_sablon10 .title {
  font-size: 48px;
  position: absolute;
  left: 320px;
  top: 250px;
  width: 496px;
  text-align: center;
  text-transform: capitalize;
  color: #141414;
  font-family: 'Montserrat Extra Bold';
  font-weight: bolder;
  line-height: 55px;
  overflow-wrap: break-word;
}

.img_tekli_sablon10 .titleTR {
  font-size: 30px;
  position: absolute;
  left: 320px;
  top: 340px;
  width: 496px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  font-family: 'Montserrat';
  font-weight: bolder;
  line-height: 40px;
  overflow-wrap: break-word;
}

.img_tekli_sablon10 .origin {
  font-size: 22px;
  position: absolute;
  left: 880px;
  top: 925px;
  width: 50px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  font-family: 'Montserrat';

  overflow-wrap: break-word;
}

.img_tekli_sablon10 .weight {
  font-size: 18px;
  position: absolute;
  width: 310px;
  text-align: center;
  left: 210px;
  top: 925px;
  font-family: 'Montserrat';
  color: #000000;
}

.img_tekli_sablon10 .pattern-logo {
  position: absolute;
  left: 108px;
  top: 102px;
}

.img_tekli_sablon10 .pattern-logo img {
  min-width: 190px;
  max-height: 190px;
  position: absolute;
  left: 100px;

}

.img_tekli_sablon10 .product-image img {
  position: absolute;
  max-height: 24rem;
  left: 350px;
  top: 390px;
}

.img_tekli_sablon10 .campaing-dates {
  position: absolute;
  font-size: 24px;
  width: 300px;
  left: 700px;
  top: 155px;
  text-align: center;
  font-family: 'Montserrat Extra Bold';
  color: #000000;
}

.img_tekli_sablon10 .address {
  position: absolute;
  font-size: 20px;
  width: 337px;
  text-align: center;
  top: 965px;
  left: 380px;
  font-family: 'Montserrat Extra Bold';
  color: #000000;
}

.img_tekli_sablon10 .phone {
  position: absolute;
  font-size: 24px;
  width: 250px;
  top: 965px;
  text-align: center;
  font-family: 'Montserrat Extra Bold';
  color: #56a437;
}

/* img_tekli_sablon10 Finish */