/* img_tekli_sablon9 Start */

.img_tekli_sablon9 .new-price {
  font-size: 50px;
  position: absolute;
  width: 300px;
  text-align: center;
  left: 605px;
  top: 500px;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fff;
  background-color: #cc7a1d;
  border-radius: 11px;
}

.img_tekli_sablon9 .old-price {
  font-size: 40px;
  position: absolute;
  width: 240px;
  text-align: center;
  left: 640px;
  top: 585px;
  text-decoration: line-through;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #cc7a1d;
  background-color: #fff;
  border-radius: 11px;
}

.img_tekli_sablon9 .title {
  font-size: 50px;
  position: absolute;
  left: 500px;
  top: 330px;
  width: 492px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  font-family: 'Montserrat Extra Bold';
  font-weight: bolder;
  line-height: 55px;
  overflow-wrap: break-word;
}

.img_tekli_sablon9 .titleTR {
  font-size: 30px;
  position: absolute;
  left: 500px;
  top: 380px;
  width: 492px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  font-family: 'Montserrat Extra Bold';
  font-weight: bolder;
  line-height: 55px;
  overflow-wrap: break-word;
}

.img_tekli_sablon9 .origin {
  font-size: 20px;
  position: absolute;
  left: 500px;
  top: 430px;
  width: 492px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-family: 'Montserrat Extra Bold';
  font-weight: bolder;
  overflow-wrap: break-word;
}

.img_tekli_sablon9 .weight {
  font-size: 19px;
  position: absolute;
  width: 375px;
  text-align: center;
  left: 105px;
  top: 800px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_tekli_sablon9 .pattern-logo {
  position: absolute;
  left: 45px;
  top: 885px;
}

.img_tekli_sablon9 .pattern-logo img {
  min-width: 200px;
  max-height: 200px;
}

.img_tekli_sablon9 .product-image img {
  position: absolute;
  max-height: 25rem;
  left: 90px;
  top: 300px;
}

.img_tekli_sablon9 .campaing-dates {
  position: absolute;
  font-size: 30px;
  width: 440px;
  left: 570px;
  top: 885px;
  text-align: center;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_tekli_sablon9 .address {
  position: absolute;
  font-size: 25px;
  width: 360px;
  text-align: right;
  top: 965px;
  left: 625px;
  font-family: 'Montserrat Extra Bold';
  color: #565656;
}

.img_tekli_sablon9 .phone {
  position: absolute;
  font-size: 25px;
  width: 200px;
  text-align: center;
  top: 965px;
  left: 383px;
  font-family: 'Montserrat Extra Bold';
  color: #565656;
}

/* img_tekli_sablon9 Finish */