/* Genel Start */

.img_dokuzlu_sablon8 .campaing-dates {
  position: absolute;
  font-size: 30px;
  top: 950px;
  left: 20px;
  width: 387px;
  text-align: left;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_dokuzlu_sablon8 .address {
  position: absolute;
  font-size: 20px;
  width: 337px;
  text-align: right;
  top: 965px;
  left: 645px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_dokuzlu_sablon8 .pattern-logo img {
  position: absolute;
  left: 50px;
  top: 10px;
  width: 15rem;
}

.img_dokuzlu_sablon8 .phone {
  position: absolute;
  font-size: 20px;
  width: 337px;
  text-align: right;
  left: 645px;
  top: 940px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

/* Genel Finish */

/* img_dokuzlu_sablon8 9-1 Start */

.img_dokuzlu_sablon8 .new-price9-1 {
  position: absolute;
  left: 215px;
  top: 378px;
  text-align: center;
  width: 95px;
  font-size: 20px;
  font-family: 'Montserrat Extra Bold';
  background-color: #f0ba00;
  border-radius: 10px;
}

.img_dokuzlu_sablon8 .old-price9-1 {
  position: absolute;
  left: 235px;
  top: 409px;
  text-align: center;
  font-size: 15px;
  width: 75px;
  font-family: 'Montserrat Extra Bold';
  background-color: #fff4bf;
  border-radius: 10px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon8 .title9-1 {
  font-size: 18px;
  position: absolute;
  left: 18px;
  top: 481px;
  width: 305px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon8 .titleTR9-1 {
  font-size: 15px;
  position: absolute;
  left: 18px;
  width: 305px;
  text-align: center;
  top: 461px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon8 .origin9-1 {
  position: absolute;
  left: 285px;
  top: 312px;
  text-align: center;
  width: 23px;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f0ba00;
}

.img_dokuzlu_sablon8 .weight9-1 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 36px;
  top: 314px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon8 .product-image9-1 img {
  position: absolute;
  max-height: 8rem;
  left: 36px;
  top: 327px;
}


/* img_dokuzlu_sablon8 9-2 Finish */


/* img_dokuzlu_sablon8 9-2 Start */

.img_dokuzlu_sablon8 .new-price9-2 {
  position: absolute;
  left: 545px;
  top: 378px;
  text-align: center;
  width: 95px;
  font-size: 20px;
  font-family: 'Montserrat Extra Bold';
  background-color: #f0ba00;
  border-radius: 10px;
}

.img_dokuzlu_sablon8 .old-price9-2 {
  position: absolute;
  left: 565px;
  top: 409px;
  text-align: center;
  font-size: 15px;
  width: 75px;
  font-family: 'Montserrat Extra Bold';
  background-color: #fff4bf;
  border-radius: 10px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon8 .title9-2 {
  font-size: 18px;
  position: absolute;
  left: 348px;
  width: 305px;
  text-align: center;
  top: 481px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon8 .titleTR9-2 {
  font-size: 15px;
  position: absolute;
  left: 348px;
  width: 305px;
  text-align: center;
  top: 461px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon8 .origin9-2 {
  position: absolute;
  left: 615px;
  top: 312px;
  text-align: center;
  width: 23px;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f0ba00;
}

.img_dokuzlu_sablon8 .weight9-2 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 366px;
  top: 314px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon8 .product-image9-2 img {
  position: absolute;
  max-height: 8rem;
  left: 366px;
  top: 327px;
}

/* img_dokuzlu_sablon8 9-2 Finish */


/* img_dokuzlu_sablon8 9-3 Start */

.img_dokuzlu_sablon8 .new-price9-3 {
  position: absolute;
  left: 875px;
  top: 378px;
  text-align: center;
  width: 95px;
  font-size: 20px;
  font-family: 'Montserrat Extra Bold';
  background-color: #f0ba00;
  border-radius: 10px;
}

.img_dokuzlu_sablon8 .old-price9-3 {
  position: absolute;
  left: 895px;
  top: 409px;
  text-align: center;
  font-size: 15px;
  width: 75px;
  font-family: 'Montserrat Extra Bold';
  background-color: #fff4bf;
  border-radius: 10px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon8 .title9-3 {
  font-size: 18px;
  position: absolute;
  left: 678px;
  width: 305px;
  text-align: center;
  top: 481px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon8 .titleTR9-3 {
  font-size: 14px;
  position: absolute;
  left: 678px;
  width: 305px;
  text-align: center;
  top: 461px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon8 .origin9-3 {
  position: absolute;
  left: 945px;
  top: 312px;
  text-align: center;
  width: 23px;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f0ba00;
}

.img_dokuzlu_sablon8 .weight9-3 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 696px;
  top: 314px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon8 .product-image9-3 img {
  position: absolute;
  max-height: 8rem;
  left: 696px;
  top: 327px;
}

/* img_dokuzlu_sablon8 9-3 Finish */


/* img_dokuzlu_sablon8 9-4 Start */

.img_dokuzlu_sablon8 .new-price9-4 {
  position: absolute;
  left: 215px;
  top: 586px;
  text-align: center;
  width: 95px;
  font-size: 20px;
  font-family: 'Montserrat Extra Bold';
  background-color: #f0ba00;
  border-radius: 10px;
}

.img_dokuzlu_sablon8 .old-price9-4 {
  position: absolute;
  left: 235px;
  top: 617px;
  text-align: center;
  font-size: 15px;
  width: 75px;
  font-family: 'Montserrat Extra Bold';
  background-color: #fff4bf;
  border-radius: 10px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon8 .title9-4 {
  font-size: 18px;
  position: absolute;
  left: 18px;
  width: 305px;
  text-align: center;
  top: 694px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon8 .titleTR9-4 {
  font-size: 15px;
  position: absolute;
  left: 18px;
  width: 305px;
  text-align: center;
  top: 669px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon8 .origin9-4 {
  position: absolute;
  left: 285px;
  top: 520px;
  text-align: center;
  width: 23px;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f0ba00;
}

.img_dokuzlu_sablon8 .weight9-4 {
  font-size: 10px;
  position: absolute;
  width: 145px;
  text-align: left;
  left: 36px;
  top: 522px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon8 .product-image9-4 img {
  position: absolute;
  max-height: 8rem;
  left: 36px;
  top: 535px;
}

/* img_dokuzlu_sablon8 9-4 Finish */

/* img_dokuzlu_sablon8 9-5 Start */

.img_dokuzlu_sablon8 .new-price9-5 {
  position: absolute;
  left: 545px;
  top: 586px;
  text-align: center;
  width: 95px;
  font-size: 20px;
  font-family: 'Montserrat Extra Bold';
  background-color: #f0ba00;
  border-radius: 10px;
}

.img_dokuzlu_sablon8 .old-price9-5 {
  position: absolute;
  left: 565px;
  top: 617px;
  text-align: center;
  font-size: 15px;
  width: 75px;
  font-family: 'Montserrat Extra Bold';
  background-color: #fff4bf;
  border-radius: 10px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon8 .title9-5 {
  font-size: 18px;
  position: absolute;
  left: 348px;
  width: 305px;
  text-align: center;
  top: 689px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon8 .titleTR9-5 {
  font-size: 15px;
  position: absolute;
  left: 348px;
  width: 305px;
  text-align: center;
  top: 669px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon8 .origin9-5 {
  position: absolute;
  left: 615px;
  top: 520px;
  text-align: center;
  width: 23px;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f0ba00;
}

.img_dokuzlu_sablon8 .weight9-5 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 366px;
  top: 522px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon8 .product-image9-5 img {
  position: absolute;
  max-height: 8rem;
  left: 366px;
  top: 535px;
}

/* img_dokuzlu_sablon8 9-5 Finish */

/* img_dokuzlu_sablon8 9-6 Start */

.img_dokuzlu_sablon8 .new-price9-6 {
  position: absolute;
  left: 875px;
  top: 586px;
  text-align: center;
  width: 95px;
  font-size: 20px;
  font-family: 'Montserrat Extra Bold';
  background-color: #f0ba00;
  border-radius: 10px;
}

.img_dokuzlu_sablon8 .old-price9-6 {
  position: absolute;
  left: 895px;
  top: 617px;
  text-align: center;
  font-size: 15px;
  width: 75px;
  font-family: 'Montserrat Extra Bold';
  background-color: #fff4bf;
  border-radius: 10px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon8 .title9-6 {
  font-size: 18px;
  position: absolute;
  left: 678px;
  width: 305px;
  text-align: center;
  top: 689px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon8 .titleTR9-6 {
  font-size: 15px;
  position: absolute;
  left: 678px;
  width: 305px;
  text-align: center;
  top: 669px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon8 .origin9-6 {
  position: absolute;
  left: 945px;
  top: 520px;
  text-align: center;
  width: 23px;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f0ba00;
}

.img_dokuzlu_sablon8 .weight9-6 {
  font-size: 10px;
  position: absolute;
  width: 145px;
  text-align: left;
  left: 696px;
  top: 522px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon8 .product-image9-6 img {
  position: absolute;
  max-height: 8rem;
  left: 696px;
  top: 535px;
}

/* img_dokuzlu_sablon8 9-6 Finish */

/* img_dokuzlu_sablon8 9-7 Start */

.img_dokuzlu_sablon8 .new-price9-7 {
  position: absolute;
  left: 215px;
  top: 794px;
  text-align: center;
  width: 95px;
  font-size: 20px;
  font-family: 'Montserrat Extra Bold';
  background-color: #f0ba00;
  border-radius: 10px;
}

.img_dokuzlu_sablon8 .old-price9-7 {
  position: absolute;
  left: 235px;
  top: 825px;
  text-align: center;
  font-size: 15px;
  width: 75px;
  font-family: 'Montserrat Extra Bold';
  background-color: #fff4bf;
  border-radius: 10px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon8 .title9-7 {
  font-size: 18px;
  position: absolute;
  left: 18px;
  width: 305px;
  text-align: center;
  top: 897px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon8 .titleTR9-7 {
  font-size: 15px;
  position: absolute;
  left: 18px;
  width: 305px;
  text-align: center;
  top: 877px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon8 .origin9-7 {
  position: absolute;
  left: 285px;
  top: 728px;
  text-align: center;
  width: 23px;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f0ba00;
}

.img_dokuzlu_sablon8 .weight9-7 {
  font-size: 10px;
  position: absolute;
  width: 145px;
  text-align: left;
  left: 36px;
  top: 730px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon8 .product-image9-7 img {
  position: absolute;
  max-height: 8rem;
  left: 36px;
  top: 743px;
}

/* img_dokuzlu_sablon8 9-7 Finish */

/* img_dokuzlu_sablon8 9-8 Start */

.img_dokuzlu_sablon8 .new-price9-8 {
  position: absolute;
  left: 545px;
  top: 794px;
  text-align: center;
  width: 95px;
  font-size: 20px;
  font-family: 'Montserrat Extra Bold';
  background-color: #f0ba00;
  border-radius: 10px;
}

.img_dokuzlu_sablon8 .old-price9-8 {
  position: absolute;
  left: 565px;
  top: 825px;
  text-align: center;
  font-size: 15px;
  width: 75px;
  font-family: 'Montserrat Extra Bold';
  background-color: #fff4bf;
  border-radius: 10px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon8 .title9-8 {
  font-size: 18px;
  position: absolute;
  left: 348px;
  width: 305px;
  text-align: center;
  top: 897px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon8 .titleTR9-8 {
  font-size: 15px;
  position: absolute;
  left: 348px;
  width: 305px;
  text-align: center;
  top: 877px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon8 .origin9-8 {
  position: absolute;
  left: 615px;
  top: 728px;
  text-align: center;
  width: 23px;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f0ba00;
}

.img_dokuzlu_sablon8 .weight9-8 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 366px;
  top: 730px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon8 .product-image9-8 img {
  position: absolute;
  max-height: 8rem;
  left: 366px;
  top: 743px;
}

/* img_dokuzlu_sablon8 9-8 Finish */

/* img_dokuzlu_sablon8 9-9 Start */

.img_dokuzlu_sablon8 .new-price9-9 {
  position: absolute;
  left: 875px;
  top: 794px;
  text-align: center;
  width: 95px;
  font-size: 20px;
  font-family: 'Montserrat Extra Bold';
  background-color: #f0ba00;
  border-radius: 10px;
}

.img_dokuzlu_sablon8 .old-price9-9 {
  position: absolute;
  left: 895px;
  top: 825px;
  text-align: center;
  font-size: 15px;
  width: 75px;
  font-family: 'Montserrat Extra Bold';
  background-color: #fff4bf;
  border-radius: 10px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon8 .title9-9 {
  font-size: 18px;
  position: absolute;
  left: 678px;
  width: 305px;
  text-align: center;
  top: 897px;
  text-transform: uppercase;
  font-family: 'Montserrat Extra Bold';
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon8 .titleTR9-9 {
  font-size: 15px;
  position: absolute;
  left: 678px;
  width: 305px;
  text-align: center;
  top: 877px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon8 .origin9-9 {
  position: absolute;
  left: 945px;
  top: 728px;
  text-align: center;
  width: 23px;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f0ba00;
}

.img_dokuzlu_sablon8 .weight9-9 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 696px;
  top: 730px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon8 .product-image9-9 img {
  position: absolute;
  max-height: 8rem;
  left: 696px;
  top: 743px;
}

/* img_dokuzlu_sablon8 9-9 Finish */