/* img_tekli_sablon5 Start */

.img_tekli_sablon5 .new-price {
  font-size: 54px;
  position: absolute;
  width: 300px;
  text-align: center;
  left: 83px;
  top: 560px;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fff;
  background-color: #f53b84;
  border-radius: 11px;
}

.img_tekli_sablon5 .old-price {
  font-size: 40px;
  position: absolute;
  width: 240px;
  text-align: center;
  left: 120px;
  top: 648px;
  text-decoration: line-through;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fff;
  background-color: #bc2b6c;
  border-radius: 11px;
}

.img_tekli_sablon5 .title {
  font-size: 65px;
  position: absolute;
  top: 325px;
  left: 10%;
  width: 80%;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  font-family: 'Montserrat Extra Bold';
  line-height: 55px;
  overflow-wrap: break-word;
}

.img_tekli_sablon5 .titleTR {
  font-size: 40px;
  position: absolute;
  top: 380px;
  left: 10%;
  width: 80%;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  font-family: 'Montserrat Extra Bold';
  line-height: 55px;
  overflow-wrap: break-word;
}

.img_tekli_sablon5 .origin {
  font-size: 25px;
  position: absolute;
  top: 430px;
  left: 10%;
  width: 80%;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_tekli_sablon5 .weight {
  font-size: 19px;
  position: absolute;
  width: 305px;
  text-align: center;
  left: 83px;
  top: 747px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_tekli_sablon5 .pattern-logo {
  position: absolute;
  left: 60px;
  top: 48px;
}

.img_tekli_sablon5 .pattern-logo img {
  min-width: 200px;
  max-height: 200px;
}

.img_tekli_sablon5 .product-image img {
  position: absolute;
  max-height: 26rem;
  left: 515px;
  top: 465px;
}

.img_tekli_sablon5 .campaing-dates {
  position: absolute;
  font-size: 25px;
  width: 335px;
  left: 49px;
  top: 953px;
  text-align: left;
  font-family: 'Montserrat Extra Bold';
  color: #4b1f74;
}

.img_tekli_sablon5 .address {
  position: absolute;
  font-size: 25px;
  width: 360px;
  text-align: right;
  top: 953px;
  left: 625px;
  font-family: 'Montserrat Extra Bold';
  color: #4b1f74;
}

.img_tekli_sablon5 .phone {
  position: absolute;
  font-size: 25px;
  width: 200px;
  text-align: center;
  top: 953px;
  left: 380px;
  font-family: 'Montserrat Extra Bold';
  color: #4b1f74;
}

/* img_tekli_sablon5 Finish */