:root {
  --top: 225px;
}

/* Genel Start */
.img_dokuzlu_sablon5 .campaing-dates {
  position: absolute;
  font-size: 25px;
  top: 953px;
  left: 1px;
  width: 387px;
  text-align: center;
  font-family: 'Montserrat Extra Bold';
  color: #000;
}

.img_dokuzlu_sablon5 .address {
  position: absolute;
  font-size: 13px;
  width: 337px;
  text-align: left;
  top: 965px;
  left: 520px;
  font-family: 'Montserrat Extra Bold';
  color: #000;
}

.img_dokuzlu_sablon5 .pattern-logo img {
  position: absolute;
  left: 360px;
  top: 740px;
  width: 15rem;
}

.img_dokuzlu_sablon5 .phone {
  position: absolute;
  font-size: 23px;
  width: 337px;
  text-align: left;
  left: 758px;
  top: 960px;
  font-family: 'Montserrat Extra Bold';
  color: #000;
}

/* Genel Finish */

.img_dokuzlu_sablon5 .new-price9-1 {
  font-size: 18px;
  position: absolute;
  left: 234px;
  top: 37px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcf02;
  border-radius: 5px;
  color: #000;
}

.img_dokuzlu_sablon5 .old-price9-1 {
  font-size: 16px;
  position: absolute;
  left: 252px;
  top: 62px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff4be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon5 .title9-1 {
  font-size: 21px;
  position: absolute;
  left: 68px;
  width: 207px;
  text-align: center;
  top: 203px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: #000;
}

.img_dokuzlu_sablon5 .titleTR9-1 {
  font-size: 12px;
  position: absolute;
  left: 68px;
  width: 207px;
  text-align: center;
  top: 186px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon5 .origin9-1 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 286px;
  top: 232px;
  font-family: 'Montserrat';
  color: #272727;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon5 .weight9-1 {
  font-size: 12px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 44px;
  top: 231px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon5 .product-image9-1 img {
  position: absolute;
  max-height: 9rem;
  left: 50px;
  top: 38px;
}


/* img_dokuzlu_sablon5 9-1 Finish */

/* img_dokuzlu_sablon5 9-2 Start */

.img_dokuzlu_sablon5 .new-price9-2 {
  font-size: 18px;
  position: absolute;
  left: 558px;
  top: 37px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcf02;
  border-radius: 5px;
  color: #000;
}

.img_dokuzlu_sablon5 .old-price9-2 {
  font-size: 16px;
  position: absolute;
  left: 576px;
  top: 62px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff4be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon5 .title9-2 {
  font-size: 21px;
  position: absolute;
  left: 392px;
  width: 207px;
  text-align: center;
  top: 203px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: #000;
}

.img_dokuzlu_sablon5 .titleTR9-2 {
  font-size: 12px;
  position: absolute;
  left: 392px;
  width: 207px;
  text-align: center;
  top: 186px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon5 .origin9-2 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 610px;
  top: 228px;
  font-family: 'Montserrat';
  color: #272727;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon5 .weight9-2 {
  font-size: 12px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 368px;
  top: 232px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon5 .product-image9-2 img {
  position: absolute;
  max-height: 9rem;
  left: 374px;
  top: 38px;
}


/* img_dokuzlu_sablon5 9-2 Finish */

/* img_dokuzlu_sablon5 9-3 Start */

.img_dokuzlu_sablon5 .new-price9-3 {
  font-size: 18px;
  position: absolute;
  left: 882px;
  top: 37px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcf02;
  border-radius: 5px;
  color: #000;
}

.img_dokuzlu_sablon5 .old-price9-3 {
  font-size: 16px;
  position: absolute;
  left: 900px;
  top: 62px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff4be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon5 .title9-3 {
  font-size: 21px;
  position: absolute;
  left: 716px;
  width: 207px;
  text-align: center;
  top: 203px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: #000;
}

.img_dokuzlu_sablon5 .titleTR9-3 {
  font-size: 12px;
  position: absolute;
  left: 716px;
  width: 207px;
  text-align: center;
  top: 186px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon5 .origin9-3 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 934px;
  top: 228px;
  font-family: 'Montserrat';
  color: #272727;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon5 .weight9-3 {
  font-size: 12px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 692px;
  top: 232px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon5 .product-image9-3 img {
  position: absolute;
  max-height: 9rem;
  left: 698px;
  top: 38px;
}


/* img_dokuzlu_sablon5 9-3 Finish */

/* img_dokuzlu_sablon5 9-4 Start */

.img_dokuzlu_sablon5 .new-price9-4 {
  font-size: 18px;
  position: absolute;
  left: 234px;
  top: calc(37px + var(--top));
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcf02;
  border-radius: 5px;
  color: #000;
}

.img_dokuzlu_sablon5 .old-price9-4 {
  font-size: 16px;
  position: absolute;
  left: 252px;
  top: calc(62px + var(--top));
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff4be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon5 .title9-4 {
  font-size: 21px;
  position: absolute;
  left: 68px;
  width: 207px;
  text-align: center;
  top: calc(203px + var(--top));
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: #000;
}

.img_dokuzlu_sablon5 .titleTR9-4 {
  font-size: 12px;
  position: absolute;
  left: 68px;
  width: 207px;
  text-align: center;
  top: calc(186px + var(--top));
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon5 .origin9-4 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 286px;
  top: calc(228px + var(--top));
  font-family: 'Montserrat';
  color: #272727;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon5 .weight9-4 {
  font-size: 12px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 44px;
  top: calc(232px + var(--top));
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon5 .product-image9-4 img {
  position: absolute;
  max-height: 9rem;
  left: 50px;
  top: calc(38px + var(--top));
}


/* img_dokuzlu_sablon5 9-4 Finish */

/* img_dokuzlu_sablon5 9-5 Start */

.img_dokuzlu_sablon5 .new-price9-5 {
  font-size: 18px;
  position: absolute;
  left: 558px;
  top: calc(37px + var(--top));
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcf02;
  border-radius: 5px;
  color: #000;
}

.img_dokuzlu_sablon5 .old-price9-5 {
  font-size: 16px;
  position: absolute;
  left: 576px;
  top: calc(62px + var(--top));
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff4be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon5 .title9-5 {
  font-size: 21px;
  position: absolute;
  left: 392px;
  width: 207px;
  text-align: center;
  top: calc(203px + var(--top));
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: #000;
}

.img_dokuzlu_sablon5 .titleTR9-5 {
  font-size: 12px;
  position: absolute;
  left: 392px;
  width: 207px;
  text-align: center;
  top: calc(186px + var(--top));
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon5 .origin9-5 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 610px;
  top: calc(228px + var(--top));
  font-family: 'Montserrat';
  color: #272727;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon5 .weight9-5 {
  font-size: 12px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 368px;
  top: calc(232px + var(--top));
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon5 .product-image9-5 img {
  position: absolute;
  max-height: 9rem;
  left: 374px;
  top: calc(38px + var(--top));
}


/* img_dokuzlu_sablon5 9-5 Finish */

/* img_dokuzlu_sablon5 9-6 Start */

.img_dokuzlu_sablon5 .new-price9-6 {
  font-size: 18px;
  position: absolute;
  left: 882px;
  top: calc(37px + var(--top));
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcf02;
  border-radius: 5px;
  color: #000;
}

.img_dokuzlu_sablon5 .old-price9-6 {
  font-size: 16px;
  position: absolute;
  left: 900px;
  top: calc(62px + var(--top));
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff4be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon5 .title9-6 {
  font-size: 21px;
  position: absolute;
  left: 716px;
  width: 207px;
  text-align: center;
  top: calc(203px + var(--top));
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: #000;
}

.img_dokuzlu_sablon5 .titleTR9-6 {
  font-size: 12px;
  position: absolute;
  left: 716px;
  width: 207px;
  text-align: center;
  top: calc(186px + var(--top));
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon5 .origin9-6 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 934px;
  top: calc(228px + var(--top));
  font-family: 'Montserrat';
  color: #272727;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon5 .weight9-6 {
  font-size: 12px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 692px;
  top: calc(232px + var(--top));
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon5 .product-image9-6 img {
  position: absolute;
  max-height: 9rem;
  left: 698px;
  top: calc(38px + var(--top));
}

/* img_dokuzlu_sablon5 9-7 Start */

.img_dokuzlu_sablon5 .new-price9-7 {
  font-size: 18px;
  position: absolute;
  left: 234px;
  top: calc(37px + 2* var(--top));
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcf02;
  border-radius: 5px;
  color: #000;
}

.img_dokuzlu_sablon5 .old-price9-7 {
  font-size: 16px;
  position: absolute;
  left: 252px;
  top: calc(62px + 2* var(--top));
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff4be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon5 .title9-7 {
  font-size: 21px;
  position: absolute;
  left: 68px;
  width: 207px;
  text-align: center;
  top: calc(203px + 2* var(--top));
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: #000;
}

.img_dokuzlu_sablon5 .titleTR9-7 {
  font-size: 12px;
  position: absolute;
  left: 68px;
  width: 207px;
  text-align: center;
  top: calc(186px + 2* var(--top));
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon5 .origin9-7 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 286px;
  top: calc(228px + 2* var(--top));
  font-family: 'Montserrat';
  color: #272727;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon5 .weight9-7 {
  font-size: 12px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 44px;
  top: calc(232px + 2* var(--top));
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon5 .product-image9-7 img {
  position: absolute;
  max-height: 9rem;
  left: 50px;
  top: calc(38px + 2* var(--top));
}


/* img_dokuzlu_sablon5 9-7 Finish */

/* img_dokuzlu_sablon5 9-8 Start */

.img_dokuzlu_sablon5 .new-price9-8 {
  font-size: 18px;
  position: absolute;
  left: 558px;
  top: calc(37px + 2* var(--top));
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcf02;
  border-radius: 5px;
  color: #000;
}

.img_dokuzlu_sablon5 .old-price9-8 {
  font-size: 16px;
  position: absolute;
  left: 576px;
  top: calc(62px + 2* var(--top));
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff4be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon5 .title9-8 {
  font-size: 21px;
  position: absolute;
  left: 392px;
  width: 207px;
  text-align: center;
  top: calc(203px + 2* var(--top));
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: #000;
}

.img_dokuzlu_sablon5 .titleTR9-8 {
  font-size: 12px;
  position: absolute;
  left: 392px;
  width: 207px;
  text-align: center;
  top: calc(186px + 2* var(--top));
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon5 .origin9-8 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 610px;
  top: calc(228px + 2* var(--top));
  font-family: 'Montserrat';
  color: #272727;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon5 .weight9-8 {
  font-size: 12px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 368px;
  top: calc(232px + 2* var(--top));
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon5 .product-image9-8 img {
  position: absolute;
  max-height: 9rem;
  left: 374px;
  top: calc(38px + 2* var(--top));
}


/* img_dokuzlu_sablon5 9-8 Finish */

/* img_dokuzlu_sablon5 9-9 Start */

.img_dokuzlu_sablon5 .new-price9-9 {
  font-size: 18px;
  position: absolute;
  left: 882px;
  top: calc(37px + 2* var(--top));
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #ffcf02;
  border-radius: 5px;
  color: #000;
}

.img_dokuzlu_sablon5 .old-price9-9 {
  font-size: 16px;
  position: absolute;
  left: 900px;
  top: calc(62px + 2* var(--top));
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #fff4be;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon5 .title9-9 {
  font-size: 21px;
  position: absolute;
  left: 716px;
  width: 207px;
  text-align: center;
  top: calc(203px + 2* var(--top));
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: #000;
}

.img_dokuzlu_sablon5 .titleTR9-9 {
  font-size: 12px;
  position: absolute;
  left: 716px;
  width: 207px;
  text-align: center;
  top: calc(186px + 2* var(--top));
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon5 .origin9-9 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 934px;
  top: calc(228px + 2* var(--top));
  font-family: 'Montserrat';
  color: #272727;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon5 .weight9-9 {
  font-size: 12px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 692px;
  top: calc(232px + 2* var(--top));
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon5 .product-image9-9 img {
  position: absolute;
  max-height: 9rem;
  left: 698px;
  top: calc(38px + 2* var(--top));
}