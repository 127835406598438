/* Genel Start */

.img_altili_sablon3 .campaing-dates {
  position: absolute;
  font-size: 33px;
  left: 600px;
  top: 650px;
  width: 387px;
  text-align: center;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_altili_sablon3 .address {
  position: absolute;
  font-size: 23px;
  width: 337px;
  text-align: left;
  top: 952px;
  left: 415px;
  font-family: 'Montserrat Extra Bold';
  color: #000;
}

.img_altili_sablon3 .pattern-logo img {
  position: absolute;
  left: 50px;
  top: 610px;
  width: 17rem;
}

.img_altili_sablon3 .phone {
  position: absolute;
  font-size: 23px;
  width: 337px;
  text-align: left;
  top: 950px;
  left: 695px;
  font-family: 'Montserrat Extra Bold';
  color: #000;
}

/* Genel Finish */

/* img_altili_sablon3 6-1 Start */

.img_altili_sablon3 .new-price6-1 {
  font-size: 18px;
  position: absolute;
  left: 234px;
  top: 23px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #6aa247;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon3 .old-price6-1 {
  font-size: 16px;
  position: absolute;
  left: 252px;
  top: 50px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #cae0ba;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon3 .title6-1 {
  font-size: 21px;
  position: absolute;
  left: 68px;
  width: 207px;
  text-align: center;
  top: 227px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: #fff;
}

.img_altili_sablon3 .titleTR6-1 {
  font-size: 15px;
  position: absolute;
  left: 68px;
  width: 207px;
  text-align: center;
  top: 203px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon3 .origin6-1 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 286px;
  top: 267px;
  font-family: 'Montserrat';
  color: #272727;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon3 .weight6-1 {
  font-size: 12px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 44px;
  top: 268px;
  font-family: 'Montserrat';
}

.img_altili_sablon3 .product-image6-1 img {
  position: absolute;
  max-height: 11rem;
  left: 50px;
  top: 23px;
}


/* img_altili_sablon3 6-1 Finish */

/* img_altili_sablon3 6-2 Start */

.img_altili_sablon3 .new-price6-2 {
  font-size: 18px;
  position: absolute;
  left: 558px;
  top: 23px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #6aa247;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon3 .old-price6-2 {
  font-size: 16px;
  position: absolute;
  left: 576px;
  top: 50px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #cae0ba;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon3 .title6-2 {
  font-size: 21px;
  position: absolute;
  left: 392px;
  width: 207px;
  text-align: center;
  top: 227px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: #fff;
}

.img_altili_sablon3 .titleTR6-2 {
  font-size: 15px;
  position: absolute;
  left: 392px;
  width: 207px;
  text-align: center;
  top: 203px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon3 .origin6-2 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 610px;
  top: 267px;
  font-family: 'Montserrat';
  color: #272727;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon3 .weight6-2 {
  font-size: 12px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 368px;
  top: 268px;
  font-family: 'Montserrat';
}

.img_altili_sablon3 .product-image6-2 img {
  position: absolute;
  max-height: 11rem;
  left: 374px;
  top: 23px;
}


/* img_altili_sablon3 6-2 Finish */

/* img_altili_sablon3 6-3 Start */

.img_altili_sablon3 .new-price6-3 {
  font-size: 18px;
  position: absolute;
  left: 882px;
  top: 23px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #6aa247;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon3 .old-price6-3 {
  font-size: 16px;
  position: absolute;
  left: 900px;
  top: 50px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #cae0ba;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon3 .title6-3 {
  font-size: 21px;
  position: absolute;
  left: 716px;
  width: 207px;
  text-align: center;
  top: 227px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: #fff;
}

.img_altili_sablon3 .titleTR6-3 {
  font-size: 15px;
  position: absolute;
  left: 716px;
  width: 207px;
  text-align: center;
  top: 203px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon3 .origin6-3 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 934px;
  top: 267px;
  font-family: 'Montserrat';
  color: #272727;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon3 .weight6-3 {
  font-size: 12px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 692px;
  top: 268px;
  font-family: 'Montserrat';
}

.img_altili_sablon3 .product-image6-3 img {
  position: absolute;
  max-height: 11rem;
  left: 698px;
  top: 23px;
}


/* img_altili_sablon3 6-3 Finish */

/* img_altili_sablon3 6-4 Start */

.img_altili_sablon3 .new-price6-4 {
  font-size: 18px;
  position: absolute;
  left: 234px;
  top: 288px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #6aa247;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon3 .old-price6-4 {
  font-size: 16px;
  position: absolute;
  left: 252px;
  top: 315px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #cae0ba;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon3 .title6-4 {
  font-size: 21px;
  position: absolute;
  left: 68px;
  width: 207px;
  text-align: center;
  top: 492px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: #fff;
}

.img_altili_sablon3 .titleTR6-4 {
  font-size: 15px;
  position: absolute;
  left: 68px;
  width: 207px;
  text-align: center;
  top: 468px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon3 .origin6-4 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 286px;
  top: 532px;
  font-family: 'Montserrat';
  color: #272727;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon3 .weight6-4 {
  font-size: 12px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 44px;
  top: 533px;
  font-family: 'Montserrat';
}

.img_altili_sablon3 .product-image6-4 img {
  position: absolute;
  max-height: 11rem;
  left: 50px;
  top: 290px;
}


/* img_altili_sablon3 6-4 Finish */

/* img_altili_sablon3 6-5 Start */

.img_altili_sablon3 .new-price6-5 {
  font-size: 18px;
  position: absolute;
  left: 558px;
  top: 288px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #6aa247;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon3 .old-price6-5 {
  font-size: 16px;
  position: absolute;
  left: 576px;
  top: 315px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #cae0ba;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon3 .title6-5 {
  font-size: 21px;
  position: absolute;
  left: 392px;
  width: 207px;
  text-align: center;
  top: 492px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: #fff;
}

.img_altili_sablon3 .titleTR6-5 {
  font-size: 15px;
  position: absolute;
  left: 392px;
  width: 207px;
  text-align: center;
  top: 468px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon3 .origin6-5 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 610px;
  top: 532px;
  font-family: 'Montserrat';
  color: #272727;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon3 .weight6-5 {
  font-size: 12px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 368px;
  top: 533px;
  font-family: 'Montserrat';
}

.img_altili_sablon3 .product-image6-5 img {
  position: absolute;
  max-height: 11rem;
  left: 374px;
  top: 292px;
}


/* img_altili_sablon3 6-5 Finish */

/* img_altili_sablon3 6-6 Start */

.img_altili_sablon3 .new-price6-6 {
  font-size: 18px;
  position: absolute;
  left: 882px;
  top: 288px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #6aa247;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon3 .old-price6-6 {
  font-size: 16px;
  position: absolute;
  left: 900px;
  top: 315px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #cae0ba;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon3 .title6-6 {
  font-size: 21px;
  position: absolute;
  left: 716px;
  width: 207px;
  text-align: center;
  top: 492px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
  color: #fff;
}

.img_altili_sablon3 .titleTR6-6 {
  font-size: 15px;
  position: absolute;
  left: 716px;
  width: 207px;
  text-align: center;
  top: 468px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon3 .origin6-6 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 934px;
  top: 532px;
  font-family: 'Montserrat';
  color: #272727;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon3 .weight6-6 {
  font-size: 12px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 692px;
  top: 533px;
  font-family: 'Montserrat';
}

.img_altili_sablon3 .product-image6-6 img {
  position: absolute;
  max-height: 11rem;
  left: 698px;
  top: 290px;
}


/* img_altili_sablon3 6-6 Finish */