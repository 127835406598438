/* Genel Start */

.img_altili_sablon12 .campaing-dates {
  position: absolute;
  font-size: 30px;
  left: 24px;
  top: 950px;
  width: 387px;
  text-align: left;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_altili_sablon12 .address {
  position: absolute;
  font-size: 20px;
  width: 337px;
  text-align: right;
  top: 970px;
  left: 635px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_altili_sablon12 .pattern-logo img {
  position: absolute;
  left: 45px;
  top: 35px;
  width: 10rem;
}

.img_altili_sablon12 .phone {
  position: absolute;
  font-size: 20px;
  width: 337px;
  text-align: right;
  top: 945px;
  left: 635px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

/* Genel Finish */

/* img_altili_sablon12 6-1 Start */

.img_altili_sablon12 .new-price6-1 {
  font-size: 27px;
  position: absolute;
  left: 87px;
  top: 413px;
  width: 169px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f43324;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon12 .old-price6-1 {
  font-size: 20px;
  position: absolute;
  left: 103px;
  top: 454px;
  width: 137px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d4c9c3;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon12 .title6-1 {
  font-size: 21px;
  position: absolute;
  left: 36px;
  width: 207px;
  text-align: left;
  top: 168px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon12 .titleTR6-1 {
  font-size: 16px;
  position: absolute;
  left: 36px;
  width: 207px;
  text-align: left;
  top: 192px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon12 .origin6-1 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 299px;
  top: 168px;
  font-family: 'Montserrat Extra Bold';
  color: #f43324;
}

.img_altili_sablon12 .weight6-1 {
  font-size: 10px;
  position: absolute;
  width: 296px;
  text-align: center;
  left: 29px;
  top: 510px;
  font-family: 'Montserrat';
}

.img_altili_sablon12 .product-image6-1 img {
  position: absolute;
  max-height: 8.75rem;
  left: 103px;
  top: 248px;
}


/* img_altili_sablon12 6-2 Finish */


/* img_altili_sablon12 6-2 Start */

.img_altili_sablon12 .new-price6-2 {
  font-size: 27px;
  position: absolute;
  left: 405px;
  top: 413px;
  width: 169px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f43324;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon12 .old-price6-2 {
  font-size: 20px;
  position: absolute;
  left: 421px;
  top: 454px;
  width: 137px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d4c9c3;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon12 .title6-2 {
  font-size: 21px;
  position: absolute;
  left: 354px;
  width: 207px;
  text-align: left;
  top: 168px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon12 .titleTR6-2 {
  font-size: 16px;
  position: absolute;
  left: 354px;
  width: 207px;
  text-align: left;
  top: 192px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon12 .origin6-2 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 617px;
  top: 168px;
  font-family: 'Montserrat Extra Bold';
  color: #f43324;
}

.img_altili_sablon12 .weight6-2 {
  font-size: 10px;
  position: absolute;
  width: 296px;
  text-align: center;
  left: 347px;
  top: 510px;
  font-family: 'Montserrat';
}

.img_altili_sablon12 .product-image6-2 img {
  position: absolute;
  max-height: 8.75rem;
  left: 421px;
  top: 248px;
}

/* img_altili_sablon12 6-2 Finish */


/* img_altili_sablon12 6-3 Start */

.img_altili_sablon12 .new-price6-3 {
  font-size: 27px;
  position: absolute;
  left: 726px;
  top: 413px;
  width: 169px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f43324;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon12 .old-price6-3 {
  font-size: 20px;
  position: absolute;
  left: 742px;
  top: 454px;
  width: 137px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d4c9c3;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon12 .title6-3 {
  font-size: 21px;
  position: absolute;
  left: 675px;
  width: 207px;
  text-align: left;
  top: 168px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon12 .titleTR6-3 {
  font-size: 16px;
  position: absolute;
  left: 675px;
  width: 207px;
  text-align: left;
  top: 192px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon12 .origin6-3 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 938px;
  top: 168px;
  font-family: 'Montserrat Extra Bold';
  color: #f43324;
}

.img_altili_sablon12 .weight6-3 {
  font-size: 10px;
  position: absolute;
  width: 296px;
  text-align: center;
  left: 668px;
  top: 510px;
  font-family: 'Montserrat';
}

.img_altili_sablon12 .product-image6-3 img {
  position: absolute;
  max-height: 8.75rem;
  left: 742px;
  top: 248px;
}

/* img_altili_sablon12 6-3 Finish */


/* img_altili_sablon12 6-4 Start */

.img_altili_sablon12 .new-price6-4 {
  font-size: 27px;
  position: absolute;
  left: 87px;
  top: 807px;
  width: 169px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f43324;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon12 .old-price6-4 {
  font-size: 20px;
  position: absolute;
  left: 103px;
  top: 848px;
  width: 137px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d4c9c3;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon12 .title6-4 {
  font-size: 21px;
  position: absolute;
  left: 36px;
  width: 207px;
  text-align: left;
  top: 562px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon12 .titleTR6-4 {
  font-size: 16px;
  position: absolute;
  left: 36px;
  width: 207px;
  text-align: left;
  top: 586px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon12 .origin6-4 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 299px;
  top: 562px;
  font-family: 'Montserrat Extra Bold';
  color: #f43324;
}

.img_altili_sablon12 .weight6-4 {
  font-size: 10px;
  position: absolute;
  width: 296px;
  text-align: center;
  left: 29px;
  top: 904px;
  font-family: 'Montserrat';
}

.img_altili_sablon12 .product-image6-4 img {
  position: absolute;
  max-height: 8.75rem;
  left: 103px;
  top: 642px;
}

/* img_altili_sablon12 6-4 Finish */

/* img_altili_sablon12 6-5 Start */

.img_altili_sablon12 .new-price6-5 {
  font-size: 27px;
  position: absolute;
  left: 405px;
  top: 807px;
  width: 169px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f43324;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon12 .old-price6-5 {
  font-size: 20px;
  position: absolute;
  left: 421px;
  top: 848px;
  width: 137px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d4c9c3;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon12 .title6-5 {
  font-size: 21px;
  position: absolute;
  left: 354px;
  width: 207px;
  text-align: left;
  top: 562px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon12 .titleTR6-5 {
  font-size: 16px;
  position: absolute;
  left: 354px;
  width: 207px;
  text-align: left;
  top: 586px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon12 .origin6-5 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 617px;
  top: 562px;
  font-family: 'Montserrat Extra Bold';
  color: #f43324;
}

.img_altili_sablon12 .weight6-5 {
  font-size: 10px;
  position: absolute;
  width: 296px;
  text-align: center;
  left: 347px;
  top: 904px;
  font-family: 'Montserrat';
}

.img_altili_sablon12 .product-image6-5 img {
  position: absolute;
  max-height: 8.75rem;
  left: 421px;
  top: 642px;
}

/* img_altili_sablon12 6-5 Finish */

/* img_altili_sablon12 6-6 Start */

.img_altili_sablon12 .new-price6-6 {
  font-size: 27px;
  position: absolute;
  left: 726px;
  top: 807px;
  width: 169px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f43324;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon12 .old-price6-6 {
  font-size: 20px;
  position: absolute;
  left: 742px;
  top: 848px;
  width: 137px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d4c9c3;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon12 .title6-6 {
  font-size: 21px;
  position: absolute;
  left: 675px;
  width: 207px;
  text-align: left;
  top: 562px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon12 .titleTR6-6 {
  font-size: 16px;
  position: absolute;
  left: 675px;
  width: 207px;
  text-align: left;
  top: 586px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon12 .origin6-6 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 938px;
  top: 562px;
  font-family: 'Montserrat Extra Bold';
  color: #f43324;
}

.img_altili_sablon12 .weight6-6 {
  font-size: 10px;
  position: absolute;
  width: 296px;
  text-align: center;
  left: 668px;
  top: 904px;
  font-family: 'Montserrat';
}

.img_altili_sablon12 .product-image6-6 img {
  position: absolute;
  max-height: 8.75rem;
  left: 742px;
  top: 642px;
}

/* img_altili_sablon12 6-6 Finish */