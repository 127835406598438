/* img_tekli_sablon3 Start */

.img_tekli_sablon3 .new-price {
  font-size: 52px;
  position: absolute;
  width: 300px;
  text-align: center;
  left: 617px;
  top: 603px;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #0c55c4;
  background-color: #ffbd31;
  border-radius: 11px;
}

.img_tekli_sablon3 .old-price {
  font-size: 50px;
  position: absolute;
  width: 245px;
  text-align: center;
  left: 640px;
  top: 691px;
  text-decoration: line-through;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fff;
  background-color: #6faaf6;
  border-radius: 11px;
}

.img_tekli_sablon3 .title {
  font-size: 45px;
  position: absolute;
  left: 10%;
  top: 290px;
  width: 80%;
  text-align: center;
  text-transform: capitalize;
  color: #0047b5;
  font-family: 'Montserrat';
  font-weight: bold;
  line-height: 55px;
  overflow-wrap: break-word;
}

.img_tekli_sablon3 .titleTR {
  font-size: 38px;
  position: absolute;
  left: 10%;
  top: 345px;
  width: 80%;
  text-align: center;
  text-transform: capitalize;
  color: #0047b5;
  font-family: 'Montserrat';
  font-weight: bold;
  line-height: 45px;
  overflow-wrap: break-word;
}

.img_tekli_sablon3 .origin {
  font-size: 25px;
  position: absolute;
  left: 10%;
  top: 390px;
  width: 80%;
  text-align: center;
  text-transform: uppercase;
  color: #0047b5;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_tekli_sablon3 .weight {
  font-size: 15px;
  position: absolute;
  width: 300px;
  text-align: center;
  left: 617px;
  top: 790px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_tekli_sablon3 .pattern-logo {
  position: absolute;
  left: 380px;
  top: 51px;
}

.img_tekli_sablon3 .pattern-logo img {
  min-width: 200px;
  max-height: 200px;
}

.img_tekli_sablon3 .product-image img {
  position: absolute;
  max-height: 25rem;
  left: 45px;
  top: 439px;
}

.img_tekli_sablon3 .campaing-dates {
  position: absolute;
  font-size: 28px;
  width: 380px;
  left: 40px;
  top: 940px;
  text-align: left;
  font-family: 'Montserrat Extra Bold';
  color: #0042b1;
}

.img_tekli_sablon3 .address {
  position: absolute;
  font-size: 25px;
  width: 360px;
  text-align: right;
  top: 942px;
  left: 625px;
  font-family: 'Montserrat Extra Bold';
  color: #0042b1;
}

.img_tekli_sablon3 .phone {
  position: absolute;
  font-size: 25px;
  width: 200px;
  text-align: center;
  top: 942px;
  left: 422px;
  font-family: 'Montserrat Extra Bold';
  color: #0042b1;
}

/* img_tekli_sablon3 Finish */