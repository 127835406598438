/* Genel Start */

.img_dokuzlu_sablon6 .campaing-dates {
  position: absolute;
  font-size: 30px;
  left: 0;
  top: 945px;
  width: 387px;
  text-align: center;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_dokuzlu_sablon6 .address {
  position: absolute;
  font-size: 23px;
  width: 337px;
  text-align: left;
  top: 952px;
  left: 415px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_dokuzlu_sablon6 .pattern-logo img {
  position: absolute;
  left: 50px;
  top: 40px;
  width: 10rem;
}

.img_dokuzlu_sablon6 .phone {
  position: absolute;
  font-size: 23px;
  width: 337px;
  text-align: left;
  top: 950px;
  left: 695px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

/* Genel Finish */

/* img_dokuzlu_sablon6 9-1 Start */

.img_dokuzlu_sablon6 .new-price9-1 {
  font-size: 16px;
  position: absolute;
  left: 260px;
  top: 365px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f11b25;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon6 .old-price9-1 {
  font-size: 14px;
  position: absolute;
  left: 265px;
  top: 389px;
  width: 72px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f5957c;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon6 .title9-1 {
  font-size: 21px;
  position: absolute;
  left: 82px;
  width: 207px;
  text-align: left;
  top: 178px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon6 .titleTR9-1 {
  font-size: 15px;
  position: absolute;
  left: 82px;
  width: 125px;
  text-align: left;
  top: 206px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon6 .origin9-1 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 310px;
  top: 169px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon6 .weight9-1 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 82px;
  top: 397px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon6 .product-image9-1 img {
  position: absolute;
  max-height: 7rem;
  left: 140px;
  top: 235px;
}


/* img_dokuzlu_sablon6 9-1 Finish */

/* img_dokuzlu_sablon6 9-2 Start */

.img_dokuzlu_sablon6 .new-price9-2 {
  font-size: 16px;
  position: absolute;
  left: 550px;
  top: 365px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f11b25;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon6 .old-price9-2 {
  font-size: 14px;
  position: absolute;
  left: 555px;
  top: 389px;
  width: 72px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f5957c;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon6 .title9-2 {
  font-size: 21px;
  position: absolute;
  left: 372px;
  width: 207px;
  text-align: left;
  top: 178px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon6 .titleTR9-2 {
  font-size: 15px;
  position: absolute;
  left: 372px;
  width: 125px;
  text-align: left;
  top: 206px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon6 .origin9-2 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 601px;
  top: 169px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon6 .weight9-2 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 372px;
  top: 397px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon6 .product-image9-2 img {
  position: absolute;
  max-height: 7rem;
  left: 430px;
  top: 235px;
}


/* img_dokuzlu_sablon6 9-2 Finish */

/* img_dokuzlu_sablon6 9-3 Start */

.img_dokuzlu_sablon6 .new-price9-3 {
  font-size: 16px;
  position: absolute;
  left: 841px;
  top: 365px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f11b25;
  border-radius: 5px;
  color: #ffffff;
}

.img_dokuzlu_sablon6 .old-price9-3 {
  font-size: 14px;
  position: absolute;
  left: 846px;
  top: 389px;
  width: 72px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f5957c;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon6 .title9-3 {
  font-size: 21px;
  position: absolute;
  left: 660px;
  width: 207px;
  text-align: left;
  top: 178px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon6 .titleTR9-3 {
  font-size: 15px;
  position: absolute;
  left: 660px;
  width: 125px;
  text-align: left;
  top: 206px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon6 .origin9-3 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 893px;
  top: 169px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon6 .weight9-3 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 660px;
  top: 397px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon6 .product-image9-3 img {
  position: absolute;
  max-height: 7rem;
  left: 715px;
  top: 235px;
}


/* img_dokuzlu_sablon6 9-3 Finish */

/* img_dokuzlu_sablon6 9-4 Start */

.img_dokuzlu_sablon6 .new-price9-4 {
  font-size: 16px;
  position: absolute;
  left: 260px;
  top: 615px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f11b25;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon6 .old-price9-4 {
  font-size: 14px;
  position: absolute;
  left: 265px;
  top: 639px;
  width: 72px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f5957c;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon6 .title9-4 {
  font-size: 21px;
  position: absolute;
  left: 82px;
  width: 207px;
  text-align: left;
  top: 434px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon6 .titleTR9-4 {
  font-size: 15px;
  position: absolute;
  left: 82px;
  width: 125px;
  text-align: left;
  top: 462px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon6 .origin9-4 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 310px;
  top: 422px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon6 .weight9-4 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 82px;
  top: 648px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon6 .product-image9-4 img {
  position: absolute;
  max-height: 7rem;
  left: 140px;
  top: 480px;
}


/* img_dokuzlu_sablon6 9-4 Finish */

/* img_dokuzlu_sablon6 9-5 Start */

.img_dokuzlu_sablon6 .new-price9-5 {
  font-size: 16px;
  position: absolute;
  left: 550px;
  top: 615px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f11b25;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon6 .old-price9-5 {
  font-size: 14px;
  position: absolute;
  left: 555px;
  top: 639px;
  width: 72px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f5957c;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon6 .title9-5 {
  font-size: 21px;
  position: absolute;
  left: 372px;
  width: 207px;
  text-align: left;
  top: 434px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon6 .titleTR9-5 {
  font-size: 15px;
  position: absolute;
  left: 372px;
  width: 125px;
  text-align: left;
  top: 462px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon6 .origin9-5 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 601px;
  top: 422px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon6 .weight9-5 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 372px;
  top: 648px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon6 .product-image9-5 img {
  position: absolute;
  max-height: 7rem;
  left: 430px;
  top: 480px;
}


/* img_dokuzlu_sablon6 9-5 Finish */

/* img_dokuzlu_sablon6 9-6 Start */

.img_dokuzlu_sablon6 .new-price9-6 {
  font-size: 16px;
  position: absolute;
  left: 841px;
  top: 615px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f11b25;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon6 .old-price9-6 {
  font-size: 14px;
  position: absolute;
  left: 846px;
  top: 639px;
  width: 72px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f5957c;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon6 .title9-6 {
  font-size: 21px;
  position: absolute;
  left: 660px;
  width: 207px;
  text-align: left;
  top: 434px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon6 .titleTR9-6 {
  font-size: 15px;
  position: absolute;
  left: 660px;
  width: 125px;
  text-align: left;
  top: 462px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon6 .origin9-6 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 893px;
  top: 422px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon6 .weight9-6 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 660px;
  top: 648px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon6 .product-image9-6 img {
  position: absolute;
  max-height: 7rem;
  left: 715px;
  top: 480px;
}


/* img_dokuzlu_sablon6 9-6 Finish */




.img_dokuzlu_sablon6 .new-price9-7 {
  font-size: 16px;
  position: absolute;
  left: 260px;
  top: 868px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f11b25;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon6 .old-price9-7 {
  font-size: 14px;
  position: absolute;
  left: 265px;
  top: 892px;
  width: 72px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f5957c;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon6 .title9-7 {
  font-size: 21px;
  position: absolute;
  left: 82px;
  width: 207px;
  text-align: left;
  top: 680px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon6 .titleTR9-7 {
  font-size: 15px;
  position: absolute;
  left: 82px;
  width: 125px;
  text-align: left;
  top: 708px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon6 .origin9-7 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 310px;
  top: 675px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon6 .weight9-7 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 82px;
  top: 902px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon6 .product-image9-7 img {
  position: absolute;
  max-height: 7rem;
  left: 140px;
  top: 730px;
}


/* img_dokuzlu_sablon6 9-4 Finish */

/* img_dokuzlu_sablon6 9-5 Start */

.img_dokuzlu_sablon6 .new-price9-8 {
  font-size: 16px;
  position: absolute;
  left: 550px;
  top: 868px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f11b25;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon6 .old-price9-8 {
  font-size: 14px;
  position: absolute;
  left: 555px;
  top: 892px;
  width: 72px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f5957c;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon6 .title9-8 {
  font-size: 21px;
  position: absolute;
  left: 372px;
  width: 207px;
  text-align: left;
  top: 680px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon6 .titleTR9-8 {
  font-size: 15px;
  position: absolute;
  left: 372px;
  width: 125px;
  text-align: left;
  top: 708px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon6 .origin9-8 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 601px;
  top: 675px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon6 .weight9-8 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 372px;
  top: 902px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon6 .product-image9-8 img {
  position: absolute;
  max-height: 7rem;
  left: 430px;
  top: 730px;
}


/* img_dokuzlu_sablon6 9-5 Finish */

/* img_dokuzlu_sablon6 9-6 Start */

.img_dokuzlu_sablon6 .new-price9-9 {
  font-size: 16px;
  position: absolute;
  left: 841px;
  top: 868px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f11b25;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon6 .old-price9-9 {
  font-size: 14px;
  position: absolute;
  left: 846px;
  top: 892px;
  width: 72px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f5957c;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon6 .title9-9 {
  font-size: 21px;
  position: absolute;
  left: 660px;
  width: 207px;
  text-align: left;
  top: 680px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon6 .titleTR9-9 {
  font-size: 15px;
  position: absolute;
  left: 660px;
  width: 125px;
  text-align: left;
  top: 708px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon6 .origin9-9 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 893px;
  top: 675px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon6 .weight9-9 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 660px;
  top: 902px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon6 .product-image9-9 img {
  position: absolute;
  max-height: 7rem;
  left: 715px;
  top: 730px;
}