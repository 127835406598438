html,
body,
#root {
  height: 100% !important;
  margin: 0 !important;
}

a {
  text-decoration: none !important;
  font-weight: bold;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.w-85 {
  width: 85% !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.mh-100 {
  min-height: 100vh !important;
}

.container {
  min-height: 100vh !important;
}

.none {
  display: none !important;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* Start Menu */

.nav-bar {
  display: block !important;
  position: relative;
  height: 100% !important;
  top: 0 !important;
  bottom: 0 !important;
  overflow-y: auto !important;
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%) !important;
}

.nav-bar li a {
  text-decoration: none;
}

.logo {
  /* width: 100% !important;
  max-width: 12em !important;
  display: block;
  height: auto;
  margin: 0 auto 1em; */
  max-width: 50%;
  margin: 2% 12.5%;
}

.menu {
  padding: 1em !important;
}

.nav-pills .nav-link.active {
  color: #fff !important;
  background-color: #7a17bf !important;
}

.nav-link {
  color: #7a17bf !important;
}

.nav-link a {
  text-decoration: none !important;
}

li.nav-item a a img {
  width: 16px;
  margin-bottom: 4px;
  margin-right: 5px;
}

.logout-icon::before {
  background-image: url(../images/icons/logout.png);
  background-size: 14px 15px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 14px;
  height: 15px;
  content: "";
  margin-right: 5px;
  margin-bottom: -1px;
}

/* End Menu */

/* Start Home Component */

.home-pattern {
  background-color: #f3f3f3;
  padding: 1rem;
  margin: 1rem;
  border-radius: 5px;
  position: relative;
}

.home-pattern-cd {
  text-align: end;
}

.home-pattern-icon .col {
  position: absolute;
  bottom: 3%;
  right: 0;
  text-align: right;
}

.home-pattern-icon .col img {
  cursor: pointer;
}

.home-pattern-sd,
.home-pattern-ed {
  margin-top: 1rem !important;
}


/* End Home Component */

.search-bar {
  margin-bottom: 2em !important;
  justify-content: center !important;
}

.product-list-filter {
  justify-content: center;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.product-list-filter::-webkit-scrollbar {
  display: none;
}

.product-list-filter div {
  text-align: center;
  border: 1px solid #7a17bf;
}

.product-list-filter.row .cat-list:first-child {
  border-radius: 10px 0px 0px 0px;
}

.product-list-filter.row .cat-list:last-child {
  border-radius: 0px 10px 0px 0px;
}

.add-button {
  background-color: #7a17bf !important;
  color: #fff !important;
  padding-left: 2em !important;
  padding-right: 2em !important;
  font-weight: bold !important;
}

.product-card {
  padding: 0.5em;
  margin: 3em 1em !important;
  position: relative;
  background-color: rgb(243 244 246);
  border-radius: 0.5em;
  max-height: 13rem !important;
}

.product-card .product-image {
  filter: drop-shadow(0 1.5em 1.5em rgb(0 0 0 / 0.5));
  margin-top: -2em !important;
}

.add-product-button {
  min-height: 2em !important;
  width: 2em !important;
  color: #ff9948;
  border: 1px solid #ff9948;
  border-radius: 100%;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  display: flex;
  font-size: 22px;
  position: absolute;
  top: 15%;
  right: 5%;
  cursor: pointer;
  transition: 0.5s;
}

.add-product-button:hover {
  background-color: #ff9948;
  color: #fff;
}

.delete-product-button {
  min-height: 2em !important;
  width: 2em !important;
  color: #e80013;
  border: 1px solid #e80013;
  border-radius: 100%;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  display: flex;
  font-size: 22px;
  position: absolute;
  top: 15%;
  right: 20%;
  cursor: pointer;
  transition: 0.5s;
}

.delete-product-button:hover {
  background-color: #e80013;
  color: #fff;

}

.product-name {
  font-size: 14px;
  text-align: right;
  justify-content: end;
}

.product-name-tr {
  font-size: 11px;
  text-align: right;
  justify-content: end;
}

.product-cart {
  width: 20%;
  background-color: #7a17bf;
  color: #fff;
  padding: 0.5em;
  border-radius: 1em 1em 0 0;
  position: fixed;
  bottom: 0;
  right: 2%;
  justify-content: center;
}

.product-cart .cart-list .cart-item {

  background-color: #fff;
  border: 1px solid #7a17bf;
  color: #000;
  max-height: 25%;
  display: block;
  margin-bottom: 1%;
}

.product-cart .cart-list {
  height: 0;
  width: 20%;
  position: fixed;
  z-index: 99 !important;
  border-radius: 1em 1em 0 0;
  bottom: 0;
  right: 2%;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}

.cart-item {
  box-shadow: 0 0 10px rgb(219, 219, 219) !important;
  padding: 0.5em;
  border-radius: 0.5em;
}

.cart-product-name {
  font-size: 1em !important;
  font-weight: bold;
}

.cart-delete-product-button {
  background-color: #ff9948;
  height: 1em;
  width: 1em !important;
  color: #fff;
  border-radius: 100%;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  display: flex;
  font-size: 1.5em;
  top: 10%;
  right: 10%;
  cursor: pointer;
}

.cart-product-image {
  filter: drop-shadow(0 1.5em 1.5em rgb(0 0 0 / 0.5));
}

.cart-product-price,
.cart-product-last-price {
  justify-content: right;
}

.cart-product-price {
  text-decoration: line-through;
}

.hamburger-icon {
  width: 2em;
  position: absolute;
  left: 1em;
  top: 2em;
}

.mobile-list {
  position: relative;
}

.list-count {
  background-color: #e80013;
  height: 1em;
  width: 1em !important;
  color: #fff;
  border-radius: 100%;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  display: flex;
  font-size: 1.5em;
  top: 10%;
  right: 10%;
}

.list-icon {
  width: 2em;
  position: absolute;
  right: 1em;
  top: 2em;
}

.mobile-header {
  display: block;
  position: fixed;
  background-color: #fff;
  width: 100%;
  z-index: 9;
}

.mobile-product-cart {
  width: 100%;
  background-color: #fff;
  border: 1px solid #7a17bf;
  color: #000;
  padding: 0.5em;
  border-radius: 1em 1em 0 0;
  position: fixed;
  bottom: 0;
  justify-content: center;
}

.close {
  width: 3em !important;
}

.mobile-product-cart .cart-list .cart-item {
  background-color: #7a17bf;
  max-height: 25%;
  display: block;
  margin-bottom: 1%;
}

.mobile-product-cart .cart-list {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 99 !important;
  bottom: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}

.cart-list .close {
  position: absolute;
  top: 1%;
  right: 1%;
}

.background-mask {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0;
  height: 0;
  width: 100vw;
  z-index: 9 !important;
}

.cart {
  display: block !important;
  height: 85% !important;
  padding-bottom: 0.5rem !important;
  margin-top: 3rem !important;
  overflow-y: scroll !important;
}

.mobile-cart-button {
  margin: 0 !important;
  padding: 0 !important;
  bottom: 0 !important;
  z-index: 9 !important;
  width: 100%;
}

/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9 !important;

}

.box {
  position: relative;
  width: 30%;
  margin: 0 auto;
  height: auto;
  max-height: 100vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;

}

.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(35% - 12px);
  top: calc(100vh - 85vh - 30px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}


.pattern-canvas {
  display: flex;
  justify-content: center;
  width: 80%;

}

.pattern-canvas .img {
  width: 1000px !important;
  height: 1000px !important;
  transform: scale(0.64);
  margin-top: -180px;
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%) !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: absolute;
}

.save-button .col {
  width: 30%;
}

.pattern-right-sidebar {
  max-width: 100% !important;
  max-height: 100vh !important;
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%) !important;
  margin-top: -2rem !important;
  padding-top: 1rem !important;
}

.pattern-list {
  max-width: 100% !important;
  max-height: 60vh !important;
  overflow-y: scroll;
  padding-top: 1rem !important;
}

.pattern-item {
  width: 100% !important;
}

.pattern-item label {
  position: relative !important;
}

input.pattern-radio {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}

img.pattern-img {
  width: 150px !important;
}

.pattern-item-mask {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  background-image: linear-gradient(transparent 30%, rgba(0, 0, 0, 0.5)) !important;
}

.form-check-input:checked {
  background-color: #7a17bf !important;
  border-color: #7a17bf !important;
}

.cursor-button {
  cursor: pointer;
}

#capture-1 {
  position: absolute !important;
}

.relative {
  position: relative;
}

.background-active {
  background-color: #7a17bf !important;
  color: white !important;

}

/* Login Start */

.login {
  margin: 0 auto !important;
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%) !important;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 1rem;
}

.login .row {
  margin: 5% auto;
}

.login-logo {
  text-align: center;
}

.login-label {
  display: flex;
  align-items: center;
}

.login-label label {
  margin-bottom: 0 !important;
}

.login-button {
  text-align: center;
}

.login-button button {
  width: 50% !important;
}

/* Login Finish */

/* Profile Page */

.profile-card {
  background-color: #f3f3f3;
  border-radius: 0.5rem;
  margin: 1rem;
  padding: 1rem;
}

/* Profile Page Finish */

.period-radio-button {
  margin-right: 0.5rem;
}

.native-flags {
  width: 33% !important;
  float: left;
}

.flags-row {
  margin-bottom: 0.90rem;
}

.cat-list {
  padding: 5px;
  line-height: 30px;
  display: inline;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.home-pattern-button button {
  width: 100%;
  margin-top: 10px;
}

a.nav-link svg {
  margin-bottom: 3px;
}

.home-create-template button,
.home-create-template button:hover {
  background-color: #7a17bf;
}

.product-list-filter-row {
  width: 100% !important;
  overflow-x: hidden;
  float: left;
}

.product-list-filter {
  width: 105%;
  max-width: 105%;
  float: left;
  cursor: pointer;
}