/* .img_tekli_sablon1 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-1.jpg');
    background-size: cover !important;
  }
  .img_tekli_sablon2 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-2.jpg');
    background-size: cover !important;
  }
  .img_tekli_sablon3 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-3.jpg');
    background-size: cover !important;
  }
  .img_tekli_sablon4 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-4.jpg');
    background-size: cover !important;
  }
  .img_tekli_sablon5 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-5.jpg');
    background-size: cover !important;
  }
  .img_tekli_sablon6 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-6.jpg');
    background-size: cover !important;
  }
  .img_tekli_sablon7 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-7.jpg');
    background-size: cover !important;
  }
  .img_tekli_sablon8 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-8.jpg');
    background-size: cover !important;
  }
  .img_tekli_sablon9 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-9.jpg');
    background-size: cover !important;
  }
  .img_tekli_sablon10 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-10.jpg');
    background-size: cover !important;
  }
  .img_tekli_sablon11 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-11.jpg');
    background-size: cover !important;
  }
  .img_tekli_sablon12 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-12.jpg');
    background-size: cover !important;
  }
  .img_tekli_sablon13 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-13.jpg');
    background-size: cover !important;
  }
  .img_tekli_sablon14 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-14.jpg');
    background-size: cover !important;
  }
  .img_tekli_sablon15 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-15.jpg');
    background-size: cover !important;
  }
  .img_tekli_sablon16 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-16.jpg');
    background-size: cover !important;
  }
  .img_tekli_sablon17 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-17.jpg');
    background-size: cover !important;
  }
  .img_tekli_sablon18 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-18.jpg');
    background-size: cover !important;
  }
  .img_uclu_sablon1 {
    background-image: url('../images/sablonlar/uclu/uclu-sablon-1.jpg');
    background-size: cover !important;
  }
  .img_uclu_sablon2 {
    background-image: url('../images/sablonlar/uclu/uclu-sablon-2.jpg');
    background-size: cover !important;
  }
  .img_uclu_sablon3 {
    background-image: url('../images/sablonlar/uclu/uclu-sablon-3.jpg');
    background-size: cover !important;
  }
  .img_uclu_sablon4 {
    background-image: url('../images/sablonlar/uclu/uclu-sablon-4.jpg');
    background-size: cover !important;
  }
  .img_altili_sablon1 {
    background-image: url('../images/sablonlar/altili/altili-sablon-1.jpg');
    background-size: cover !important;
  }
  .img_altili_sablon2 {
    background-image: url('../images/sablonlar/altili/altili-sablon-2.jpg');
    background-size: cover !important;
  }
  .img_altili_sablon3 {
    background-image: url('../images/sablonlar/altili/altili-sablon-3.jpg');
    background-size: cover !important;
  }
  .img_altili_sablon4 {
    background-image: url('../images/sablonlar/altili/altili-sablon-4.jpg');
    background-size: cover !important;
  }
  .img_dokuzlu_sablon1 {
    background-image: url('../images/sablonlar/dokuzlu/dokuzlu-sablon-1.jpg');
    background-size: cover !important;
  }
  .img_dokuzlu_sablon2 {
    background-image: url('../images/sablonlar/dokuzlu/dokuzlu-sablon-2.jpg');
    background-size: cover !important;
  }
  .img_dokuzlu_sablon3 {
    background-image: url('../images/sablonlar/dokuzlu/dokuzlu-sablon-3.jpg');
    background-size: cover !important;
  }
  .img_dokuzlu_sablon4 {
    background-image: url('../images/sablonlar/dokuzlu/dokuzlu-sablon-4.jpg');
    background-size: cover !important;
  }
  .img_dokuzlu_sablon5 {
    background-image: url('../images/sablonlar/dokuzlu/dokuzlu-sablon-5.jpg');
    background-size: cover !important;
  } */

.img_tekli_sablon1 {
  background-image: url('../images/sablonlar/tekli/tekli-sablon-1.jpg');
  background-size: cover !important;
}

.img_tekli_sablon2 {
  background-image: url('../images/sablonlar/tekli/tekli-sablon-2.jpg');
  background-size: cover !important;
}

.img_tekli_sablon3 {
  background-image: url('../images/sablonlar/tekli/tekli-sablon-3.jpg');
  background-size: cover !important;
}

.img_tekli_sablon4 {
  background-image: url('../images/sablonlar/tekli/tekli-sablon-4.jpg');
  background-size: cover !important;
}

.img_tekli_sablon5 {
  background-image: url('../images/sablonlar/tekli/tekli-sablon-5.jpg');
  background-size: cover !important;
}

.img_tekli_sablon6 {
  background-image: url('../images/sablonlar/tekli/tekli-sablon-6.jpg');
  background-size: cover !important;
}

.img_tekli_sablon7 {
  background-image: url('../images/sablonlar/tekli/tekli-sablon-7.jpg');
  background-size: cover !important;
}

.img_tekli_sablon8 {
  background-image: url('../images/sablonlar/tekli/tekli-sablon-8.jpg');
  background-size: cover !important;
}

.img_tekli_sablon9 {
  background-image: url('../images/sablonlar/tekli/tekli-sablon-9.jpg');
  background-size: cover !important;
}

.img_tekli_sablon10 {
  background-image: url('../images/sablonlar/tekli/tekli-sablon-10.jpg');
  background-size: cover !important;
}

.img_tekli_sablon11 {
  background-image: url('../images/sablonlar/tekli/tekli-sablon-11.jpg');
  background-size: cover !important;
}

.img_tekli_sablon12 {
  background-image: url('../images/sablonlar/tekli/tekli-sablon-12.jpg');
  background-size: cover !important;
}

.img_uclu_sablon1 {
  background-image: url('../images/sablonlar/uclu/img_uclu_sablon1.jpg');
  background-size: cover !important;
}

.img_uclu_sablon2 {
  background-image: url('../images/sablonlar/uclu/img_uclu_sablon2.jpg');
  background-size: cover !important;
}

.img_uclu_sablon3 {
  background-image: url('../images/sablonlar/uclu/img_uclu_sablon3.jpg');
  background-size: cover !important;
}

.img_uclu_sablon4 {
  background-image: url('../images/sablonlar/uclu/img_uclu_sablon4.jpg');
  background-size: cover !important;
}

.img_uclu_sablon5 {
  background-image: url('../images/sablonlar/uclu/img_uclu_sablon5.jpg');
  background-size: cover !important;
}

.img_uclu_sablon6 {
  background-image: url('../images/sablonlar/uclu/img_uclu_sablon6.jpg');
  background-size: cover !important;
}

.img_uclu_sablon7 {
  background-image: url('../images/sablonlar/uclu/img_uclu_sablon7.jpg');
  background-size: cover !important;
}

.img_uclu_sablon8 {
  background-image: url('../images/sablonlar/uclu/img_uclu_sablon8.jpg');
  background-size: cover !important;
}

.img_uclu_sablon9 {
  background-image: url('../images/sablonlar/uclu/img_uclu_sablon9.jpg');
  background-size: cover !important;
}

.img_uclu_sablon10 {
  background-image: url('../images/sablonlar/uclu/img_uclu_sablon10.jpg');
  background-size: cover !important;
}

.img_uclu_sablon11 {
  background-image: url('../images/sablonlar/uclu/img_uclu_sablon11.jpg');
  background-size: cover !important;
}

.img_uclu_sablon12 {
  background-image: url('../images/sablonlar/uclu/img_uclu_sablon12.jpg');
  background-size: cover !important;
}

.img_altili_sablon1 {
  background-image: url('../images/sablonlar/altili/altili-sablon-1.jpg');
  background-size: cover !important;
}

.img_altili_sablon2 {
  background-image: url('../images/sablonlar/altili/altili-sablon-2.jpg');
  background-size: cover !important;
}

.img_altili_sablon3 {
  background-image: url('../images/sablonlar/altili/altili-sablon-3.jpg');
  background-size: cover !important;
}

.img_altili_sablon4 {
  background-image: url('../images/sablonlar/altili/altili-sablon-4.jpg');
  background-size: cover !important;
}

.img_altili_sablon5 {
  background-image: url('../images/sablonlar/altili/altili-sablon-5.jpg');
  background-size: cover !important;
}

.img_altili_sablon6 {
  background-image: url('../images/sablonlar/altili/altili-sablon-6.jpg');
  background-size: cover !important;
}

.img_altili_sablon7 {
  background-image: url('../images/sablonlar/altili/altili-sablon-7.jpg');
  background-size: cover !important;
}

.img_altili_sablon8 {
  background-image: url('../images/sablonlar/altili/altili-sablon-8.jpg');
  background-size: cover !important;
}

.img_altili_sablon9 {
  background-image: url('../images/sablonlar/altili/altili-sablon-9.jpg');
  background-size: cover !important;
}

.img_altili_sablon10 {
  background-image: url('../images/sablonlar/altili/altili-sablon-10.jpg');
  background-size: cover !important;
}

.img_altili_sablon11 {
  background-image: url('../images/sablonlar/altili/altili-sablon-11.jpg');
  background-size: cover !important;
}

.img_altili_sablon12 {
  background-image: url('../images/sablonlar/altili/altili-sablon-12.jpg');
  background-size: cover !important;
}

.img_dokuzlu_sablon1 {
  background-image: url('../images/sablonlar/dokuzlu/dokuzlu-sablon-1.jpg');
  background-size: cover !important;
}

.img_dokuzlu_sablon2 {
  background-image: url('../images/sablonlar/dokuzlu/dokuzlu-sablon-2.jpg');
  background-size: cover !important;
}

.img_dokuzlu_sablon3 {
  background-image: url('../images/sablonlar/dokuzlu/dokuzlu-sablon-3.jpg');
  background-size: cover !important;
}

.img_dokuzlu_sablon4 {
  background-image: url('../images/sablonlar/dokuzlu/dokuzlu-sablon-4.jpg');
  background-size: cover !important;
}

.img_dokuzlu_sablon5 {
  background-image: url('../images/sablonlar/dokuzlu/dokuzlu-sablon-5.jpg');
  background-size: cover !important;
}

.img_dokuzlu_sablon6 {
  background-image: url('../images/sablonlar/dokuzlu/dokuzlu-sablon-6.jpg');
  background-size: cover !important;
}

.img_dokuzlu_sablon7 {
  background-image: url('../images/sablonlar/dokuzlu/dokuzlu-sablon-7.jpg');
  background-size: cover !important;
}

.img_dokuzlu_sablon8 {
  background-image: url('../images/sablonlar/dokuzlu/dokuzlu-sablon-8.jpg');
  background-size: cover !important;
}

.img_dokuzlu_sablon9 {
  background-image: url('../images/sablonlar/dokuzlu/dokuzlu-sablon-9.jpg');
  background-size: cover !important;
}