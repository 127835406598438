@font-face {
  font-family: 'Montserrat Extra Bold';
  src: url(./fonts/Montserrat-ExtraBold.otf);
}
@font-face {
  font-family:'Gagalin';
  src: url(./fonts/Gagalin-Regular.otf);
}
@font-face {
  font-family: 'Montserrat';
  src: url(./fonts/Montserrat-Regular.otf);
}
@font-face {
  font-family: 'Bangers';
  src: url(./fonts/Bangers.ttf);
}
@font-face {
  font-family: 'Arial Black';
  src: url(./fonts/ariblk.ttf);
}
@font-face {
  font-family: 'Anton Regular';
  src: url(./fonts/Anton-Regular.ttf);
}
@font-face {
  font-family: 'Azo Sans Uber Regular';
  src: url(./fonts/Azo-Sans-Uber-Regular.ttf);
}
@font-face {
  font-family: 'Work Sans';
  src: url(./fonts/WorkSans-Regular.ttf);
}
@font-face {
  font-family: 'Open Sans Bold Italic';
  src: url(./fonts/OpenSans-BoldItalic.ttf);
}
@font-face {
  font-family: 'Open Sans Extra Bold';
  src: url(./fonts/OpenSans-ExtraBold.ttf);
}
@font-face {
  font-family: 'Open Sans Semibold Italic';
  src: url(./fonts/OpenSans-SemiboldItalic.ttf);
}
@font-face {
  font-family: 'Metropolis Extra Bold';
  src: url(./fonts/Metropolis-ExtraBold.otf);
}
@font-face {
  font-family: 'Metropolis Semibold';
  src: url(./fonts/Metropolis-SemiBold.otf);
}
@font-face {
  font-family: 'Bungee';
  src: url(./fonts/Bungee-Regular.ttf);
}
@font-face {
  font-family: 'Avant Garde';
  src: url(./fonts/ITCAvantGardeStd-Demi.otf);
}
@font-face {
  font-family: 'Luckiest Guy';
  src: url(./fonts/LuckiestGuy.ttf);
}
@font-face {
  font-family: 'Bebas Neue Bold';
  src: url(./fonts/BebasNeue-Bold.otf);
}
@font-face {
  font-family: 'Raleway Bold';
  src: url(./fonts/Raleway-Bold.ttf);
}
@font-face {
  font-family: 'Lato Bold';
  src: url(./fonts/Lato-Bold.ttf);
}
@font-face {
  font-family: 'Lato Black';
  src: url(./fonts/Lato-Black.ttf);
}
@font-face {
  font-family: 'Uni Sans';
  src: url(./fonts/Uni\ Sans\ Heavy\ Italic.otf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
