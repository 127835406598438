/* img_tekli_sablon12 Start */

.img_tekli_sablon12 .new-price {
  font-size: 60px;
  position: absolute;
  width: 300px;
  text-align: center;
  left: 663px;
  top: 280px;
  font-family: 'Montserrat Extra Bold';
  color: #ffffff;
  background-color: #57a337;
  border-radius: 11px;
}

.img_tekli_sablon12 .old-price {
  font-size: 45px;
  position: absolute;
  width: 245px;
  text-align: center;
  left: 718px;
  top: 370px;
  text-decoration: line-through;
  font-family: 'Montserrat Extra Bold';
  color: #000;
  background-color: #d5e7cd;
  border-radius: 11px;
}

.img_tekli_sablon12 .title {
  font-size: 48px;
  position: absolute;
  left: 179px;
  top: 775px;
  width: 633px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  font-family: 'Montserrat Extra Bold';
  font-weight: bolder;
  line-height: 55px;
  overflow-wrap: break-word;
}

.img_tekli_sablon12 .titleTR {
  font-size: 35px;
  position: absolute;
  left: 320px;
  top: 715px;
  width: 330px;
  text-align: center;
  text-transform: capitalize;
  color: #070707;
  font-family: 'Montserrat';
  font-weight: bolder;
  line-height: 40px;
  overflow-wrap: break-word;
}

.img_tekli_sablon12 .origin {
  font-size: 35px;
  position: absolute;
  left: 860px;
  top: 845px;
  width: 89px;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  font-family: 'Montserrat Extra Bold';
  font-weight: bolder;
  overflow-wrap: break-word;
}

.img_tekli_sablon12 .weight {
  font-size: 19px;
  position: absolute;
  width: 310px;
  text-align: center;
  left: 100px;
  top: 860px;
  font-family: 'Montserrat';
  color: #070707;
}

.img_tekli_sablon12 .pattern-logo {
  position: absolute;
  left: 78px;
  top: 52px;
}

.img_tekli_sablon12 .pattern-logo img {
  min-width: 200px;
  max-height: 200px;
}

.img_tekli_sablon12 .product-image img {
  position: absolute;
  max-height: 25rem;
  left: 95px;
  top: 280px;
}

.img_tekli_sablon12 .campaing-dates {
  position: absolute;
  font-size: 25px;
  width: 400px;
  left: 20px;
  top: 945px;
  text-align: center;
  font-family: 'Montserrat Extra Bold';
  color: #57A337;
}

.img_tekli_sablon12 .address {
  position: absolute;
  font-size: 20px;
  width: 300px;
  text-align: center;
  top: 945px;
  left: 400px;
  font-family: 'Montserrat Extra Bold';
  color: #57A337;
}

.img_tekli_sablon12 .phone {
  position: absolute;
  font-size: 25px;
  width: 320px;
  text-align: center;
  top: 945px;
  left: 665px;
  font-family: 'Montserrat Extra Bold';
  color: #57A337;
}

/* img_tekli_sablon12 Finish */