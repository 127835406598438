/* Genel Start */

.img_altili_sablon9 .campaing-dates {
  position: absolute;
  font-size: 30px;
  left: 42px;
  top: 942px;
  width: 387px;
  text-align: left;
  font-family: 'Montserrat Extra Bold';
}

.img_altili_sablon9 .address {
  position: absolute;
  font-size: 21px;
  width: 337px;
  text-align: right;
  top: 955px;
  left: 625px;
  font-family: 'Montserrat Extra Bold';
}

.img_altili_sablon9 .pattern-logo img {
  position: absolute;
  left: 42px;
  top: 45px;
  width: 14rem;
}

.img_altili_sablon9 .phone {
  position: absolute;
  font-size: 21px;
  width: 337px;
  text-align: right;
  top: 932px;
  left: 625px;
  font-family: 'Montserrat Extra Bold';
}

/* Genel Finish */

/* img_altili_sablon9 6-1 Start */

.img_altili_sablon9 .new-price6-1 {
  font-size: 29px;
  position: absolute;
  left: 298px;
  top: 290px;
  width: 157px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1a75bb;
  border-radius: 15px;
  color: #fff;
}

.img_altili_sablon9 .old-price6-1 {
  font-size: 24px;
  position: absolute;
  left: 313px;
  top: 335px;
  width: 127px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d7eaf9;
  border-radius: 15px;
  text-decoration: line-through;
}

.img_altili_sablon9 .title6-1 {
  font-size: 24px;
  position: absolute;
  left: 245px;
  width: 250px;
  text-align: center;
  top: 215px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon9 .titleTR6-1 {
  font-size: 17px;
  position: absolute;
  left: 245px;
  top: 250px;
  width: 250px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon9 .origin6-1 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 462px;
  top: 390px;
  font-family: 'Montserrat';
}

.img_altili_sablon9 .weight6-1 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 245px;
  top: 390px;
  font-family: 'Montserrat';
}

.img_altili_sablon9 .product-image6-1 img {
  position: absolute;
  max-height: 10rem;
  left: 72px;
  top: 235px;
}


/* img_altili_sablon9 6-2 Finish */


/* img_altili_sablon9 6-2 Start */

.img_altili_sablon9 .new-price6-2 {
  font-size: 29px;
  position: absolute;
  left: 761px;
  top: 290px;
  width: 157px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1a75bb;
  border-radius: 15px;
  color: #fff;
}

.img_altili_sablon9 .old-price6-2 {
  font-size: 24px;
  position: absolute;
  left: 776px;
  top: 335px;
  width: 127px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d7eaf9;
  border-radius: 15px;
  text-decoration: line-through;
}

.img_altili_sablon9 .title6-2 {
  font-size: 24px;
  position: absolute;
  left: 707px;
  width: 250px;
  text-align: center;
  top: 215px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon9 .titleTR6-2 {
  font-size: 17px;
  position: absolute;
  left: 707px;
  top: 250px;
  width: 250px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon9 .origin6-2 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 925px;
  top: 390px;
  font-family: 'Montserrat';
}

.img_altili_sablon9 .weight6-2 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 707px;
  top: 390px;
  font-family: 'Montserrat';
}

.img_altili_sablon9 .product-image6-2 img {
  position: absolute;
  max-height: 10rem;
  left: 535px;
  top: 235px;
}

/* img_altili_sablon9 6-2 Finish */


/* img_altili_sablon9 6-3 Start */

.img_altili_sablon9 .new-price6-3 {
  font-size: 29px;
  position: absolute;
  left: 298px;
  top: 530px;
  width: 157px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1a75bb;
  border-radius: 15px;
  color: #fff;
}

.img_altili_sablon9 .old-price6-3 {
  font-size: 24px;
  position: absolute;
  left: 313px;
  top: 575px;
  width: 127px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d7eaf9;
  border-radius: 15px;
  text-decoration: line-through;
}

.img_altili_sablon9 .title6-3 {
  font-size: 24px;
  position: absolute;
  left: 245px;
  top: 450px;
  width: 250px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon9 .titleTR6-3 {
  font-size: 17px;
  position: absolute;
  left: 245px;
  top: 485px;
  width: 250px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon9 .origin6-3 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 462px;
  top: 625px;
  font-family: 'Montserrat';
}

.img_altili_sablon9 .weight6-3 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 245px;
  top: 625px;
  font-family: 'Montserrat';
}

.img_altili_sablon9 .product-image6-3 img {
  position: absolute;
  max-height: 10rem;
  left: 72px;
  top: 472px;
}

/* img_altili_sablon9 6-3 Finish */


/* img_altili_sablon9 6-4 Start */

.img_altili_sablon9 .new-price6-4 {
  font-size: 29px;
  position: absolute;
  left: 761px;
  top: 530px;
  width: 157px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1a75bb;
  border-radius: 15px;
  color: #fff;
}

.img_altili_sablon9 .old-price6-4 {
  font-size: 24px;
  position: absolute;
  left: 776px;
  top: 575px;
  width: 127px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d7eaf9;
  border-radius: 15px;
  text-decoration: line-through;
}

.img_altili_sablon9 .title6-4 {
  font-size: 24px;
  position: absolute;
  left: 707px;
  top: 450px;
  width: 250px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon9 .titleTR6-4 {
  font-size: 17px;
  position: absolute;
  left: 707px;
  top: 485px;
  width: 250px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon9 .origin6-4 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 925px;
  top: 625px;
  font-family: 'Montserrat';
}

.img_altili_sablon9 .weight6-4 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 707px;
  top: 625px;
  font-family: 'Montserrat';
}

.img_altili_sablon9 .product-image6-4 img {
  position: absolute;
  max-height: 10rem;
  left: 535px;
  top: 472px;
}

/* img_altili_sablon9 6-4 Finish */

/* img_altili_sablon9 6-5 Start */

.img_altili_sablon9 .new-price6-5 {
  font-size: 29px;
  position: absolute;
  left: 298px;
  top: 765px;
  width: 157px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1a75bb;
  border-radius: 15px;
  color: #fff;
}

.img_altili_sablon9 .old-price6-5 {
  font-size: 24px;
  position: absolute;
  left: 313px;
  top: 810px;
  width: 127px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d7eaf9;
  border-radius: 15px;
  text-decoration: line-through;
}

.img_altili_sablon9 .title6-5 {
  font-size: 24px;
  position: absolute;
  left: 245px;
  top: 697px;
  width: 250px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon9 .titleTR6-5 {
  font-size: 17px;
  position: absolute;
  left: 245px;
  top: 732px;
  width: 250px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon9 .origin6-5 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 462px;
  top: 860px;
  font-family: 'Montserrat';
}

.img_altili_sablon9 .weight6-5 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 245px;
  top: 860px;
  font-family: 'Montserrat';
}

.img_altili_sablon9 .product-image6-5 img {
  position: absolute;
  max-height: 10rem;
  left: 72px;
  top: 710px;
}

/* img_altili_sablon9 6-5 Finish */

/* img_altili_sablon9 6-6 Start */

.img_altili_sablon9 .new-price6-6 {
  font-size: 29px;
  position: absolute;
  left: 761px;
  top: 765px;
  width: 157px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1a75bb;
  border-radius: 15px;
  color: #fff;
}

.img_altili_sablon9 .old-price6-6 {
  font-size: 24px;
  position: absolute;
  left: 776px;
  top: 810px;
  width: 127px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #d7eaf9;
  border-radius: 15px;
  text-decoration: line-through;
}

.img_altili_sablon9 .title6-6 {
  font-size: 24px;
  position: absolute;
  left: 707px;
  top: 697px;
  width: 250px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon9 .titleTR6-6 {
  font-size: 17px;
  position: absolute;
  left: 707px;
  top: 732px;
  width: 250px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon9 .origin6-6 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 925px;
  top: 860px;
  font-family: 'Montserrat';
}

.img_altili_sablon9 .weight6-6 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 707px;
  top: 860px;
  font-family: 'Montserrat';
}

.img_altili_sablon9 .product-image6-6 img {
  position: absolute;
  max-height: 10rem;
  left: 535px;
  top: 710px;
}

/* img_altili_sablon9 6-6 Finish */