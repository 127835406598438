/* img_tekli_sablon4 Start */

.img_tekli_sablon4 .new-price {
  font-size: 45px;
  position: absolute;
  width: 300px;
  text-align: center;
  left: 105px;
  top: 536px;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #f39c11;
  background-color: #282828;
  border-radius: 11px;
}

.img_tekli_sablon4 .old-price {
  font-size: 40px;
  position: absolute;
  width: 245px;
  text-align: center;
  left: 126px;
  top: 607px;
  text-decoration: line-through;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #282828;
  background-color: #f39c11;
  border-radius: 11px;
}

.img_tekli_sablon4 .title {
  font-size: 45px;
  position: absolute;
  left: 60px;
  top: 365px;
  width: 445px;
  text-align: center;
  text-transform: capitalize;
  color: #282828;
  font-family: 'Montserrat';
  font-weight: bold;
  line-height: 55px;
  overflow-wrap: break-word;
}

.img_tekli_sablon4 .titleTR {
  font-size: 40px;
  position: absolute;
  left: 60px;
  top: 425px;
  width: 445px;
  text-align: center;
  text-transform: capitalize;
  color: #f39c11;
  font-family: 'Montserrat';
  font-weight: bold;
  line-height: 55px;
  overflow-wrap: break-word;
}

.img_tekli_sablon4 .origin {
  font-size: 25px;
  position: absolute;
  left: 60px;
  top: 480px;
  width: 445px;
  text-align: center;
  text-transform: uppercase;
  color: #282828;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_tekli_sablon4 .weight {
  font-size: 19px;
  position: absolute;
  width: 305px;
  text-align: center;
  left: 105px;
  top: 680px;
  font-family: 'Montserrat Extra Bold';
  color: #f39c11;
}

.img_tekli_sablon4 .pattern-logo {
  position: absolute;
  left: 65px;
  top: 60px;
}

.img_tekli_sablon4 .pattern-logo img {
  min-width: 200px;
  max-height: 200px;
}

.img_tekli_sablon4 .product-image img {
  position: absolute;
  max-height: 30rem;
  left: 495px;
  top: 330px;
}

.img_tekli_sablon4 .campaing-dates {
  position: absolute;
  font-size: 27px;
  width: 362px;
  left: 40px;
  top: 940px;
  text-align: left;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_tekli_sablon4 .address {
  position: absolute;
  font-size: 25px;
  width: 360px;
  text-align: right;
  top: 940px;
  left: 625px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_tekli_sablon4 .phone {
  position: absolute;
  font-size: 25px;
  width: 200px;
  text-align: center;
  top: 940px;
  left: 380px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

/* img_tekli_sablon4 Finish */