/* Genel Start */

.img_altili_sablon2 .campaing-dates {
  position: absolute;
  font-size: 30px;
  left: 556px;
  top: 18px;
  width: 387px;
  text-align: center;
  font-family: 'Montserrat Extra Bold';
  color: #756e6e;
}

.img_altili_sablon2 .address {
  position: absolute;
  font-size: 18px;
  width: 337px;
  text-align: center;
  top: 974px;
  left: 330px;
  font-family: 'Montserrat Extra Bold';
  color: #000;
}

.img_altili_sablon2 .pattern-logo img {
  position: absolute;
  left: 30px;
  top: 31px;
  width: 15rem;
}

.img_altili_sablon2 .phone {
  position: absolute;
  font-size: 23px;
  width: 337px;
  text-align: left;
  top: 267px;
  left: 45px;
  font-family: 'Montserrat Extra Bold';
  color: #756e6e;
}

/* Genel Finish */

/* img_altili_sablon2 6-1 Start */

.img_altili_sablon2 .new-price6-1 {
  font-size: 18px;
  position: absolute;
  left: 126px;
  top: 557px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #c40816;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon2 .old-price6-1 {
  font-size: 16px;
  position: absolute;
  left: 134px;
  top: 582px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f7e792;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon2 .title6-1 {
  font-size: 21px;
  position: absolute;
  left: 66px;
  width: 207px;
  text-align: center;
  top: 331px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon2 .titleTR6-1 {
  font-size: 15px;
  position: absolute;
  left: 66px;
  width: 207px;
  text-align: center;
  top: 365px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon2 .origin6-1 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 297px;
  top: 613px;
  font-family: 'Montserrat';
  color: #000;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon2 .weight6-1 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 26px;
  top: 613px;
  font-family: 'Montserrat';
}

.img_altili_sablon2 .product-image6-1 img {
  position: absolute;
  max-height: 10rem;
  left: 90px;
  top: 387px;
}


/* img_altili_sablon2 6-1 Finish */

/* img_altili_sablon2 6-2 Start */

.img_altili_sablon2 .new-price6-2 {
  font-size: 18px;
  position: absolute;
  left: 452px;
  top: 557px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #c40816;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon2 .old-price6-2 {
  font-size: 16px;
  position: absolute;
  left: 460px;
  top: 582px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f7e792;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon2 .title6-2 {
  font-size: 21px;
  position: absolute;
  left: 392px;
  width: 207px;
  text-align: center;
  top: 331px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon2 .titleTR6-2 {
  font-size: 15px;
  position: absolute;
  left: 392px;
  width: 207px;
  text-align: center;
  top: 365px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon2 .origin6-2 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 623px;
  top: 613px;
  font-family: 'Montserrat';
  color: #000;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon2 .weight6-2 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 352px;
  top: 613px;
  font-family: 'Montserrat';
}

.img_altili_sablon2 .product-image6-2 img {
  position: absolute;
  max-height: 10rem;
  left: 416px;
  top: 387px;
}


/* img_altili_sablon2 6-2 Finish */

/* img_altili_sablon2 6-3 Start */

.img_altili_sablon2 .new-price6-3 {
  font-size: 18px;
  position: absolute;
  left: 778px;
  top: 557px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #c40816;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon2 .old-price6-3 {
  font-size: 16px;
  position: absolute;
  left: 786px;
  top: 582px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f7e792;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon2 .title6-3 {
  font-size: 21px;
  position: absolute;
  left: 718px;
  width: 207px;
  text-align: center;
  top: 331px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon2 .titleTR6-3 {
  font-size: 15px;
  position: absolute;
  left: 718px;
  width: 207px;
  text-align: center;
  top: 365px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon2 .origin6-3 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 949px;
  top: 613px;
  font-family: 'Montserrat';
  color: #000;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon2 .weight6-3 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 678px;
  top: 613px;
  font-family: 'Montserrat';
}

.img_altili_sablon2 .product-image6-3 img {
  position: absolute;
  max-height: 10rem;
  left: 742px;
  top: 387px;
}


/* img_altili_sablon2 6-3 Finish */

/* img_altili_sablon2 6-4 Start */

.img_altili_sablon2 .new-price6-4 {
  font-size: 18px;
  position: absolute;
  left: 126px;
  top: 891px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #c40816;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon2 .old-price6-4 {
  font-size: 16px;
  position: absolute;
  left: 134px;
  top: 916px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f7e792;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon2 .title6-4 {
  font-size: 21px;
  position: absolute;
  left: 66px;
  width: 207px;
  text-align: center;
  top: 665px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon2 .titleTR6-4 {
  font-size: 15px;
  position: absolute;
  left: 66px;
  width: 207px;
  text-align: center;
  top: 699px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon2 .origin6-4 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 297px;
  top: 947px;
  font-family: 'Montserrat';
  color: #000;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon2 .weight6-4 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 26px;
  top: 947px;
  font-family: 'Montserrat';
}

.img_altili_sablon2 .product-image6-4 img {
  position: absolute;
  max-height: 10rem;
  left: 90px;
  top: 721px;
}


/* img_altili_sablon2 6-4 Finish */

/* img_altili_sablon2 6-5 Start */

.img_altili_sablon2 .new-price6-5 {
  font-size: 18px;
  position: absolute;
  left: 452px;
  top: 891px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #c40816;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon2 .old-price6-5 {
  font-size: 16px;
  position: absolute;
  left: 460px;
  top: 916px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f7e792;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon2 .title6-5 {
  font-size: 21px;
  position: absolute;
  left: 392px;
  width: 207px;
  text-align: center;
  top: 665px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon2 .titleTR6-5 {
  font-size: 15px;
  position: absolute;
  left: 392px;
  width: 207px;
  text-align: center;
  top: 699px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon2 .origin6-5 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 623px;
  top: 947px;
  font-family: 'Montserrat';
  color: #000;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon2 .weight6-5 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 352px;
  top: 947px;
  font-family: 'Montserrat';
}

.img_altili_sablon2 .product-image6-5 img {
  position: absolute;
  max-height: 10rem;
  left: 416px;
  top: 721px;
}


/* img_altili_sablon2 6-5 Finish */

/* img_altili_sablon2 6-6 Start */

.img_altili_sablon2 .new-price6-6 {
  font-size: 18px;
  position: absolute;
  left: 778px;
  top: 891px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #c40816;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon2 .old-price6-6 {
  font-size: 16px;
  position: absolute;
  left: 786px;
  top: 916px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f7e792;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon2 .title6-6 {
  font-size: 21px;
  position: absolute;
  left: 718px;
  width: 207px;
  text-align: center;
  top: 665px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon2 .titleTR6-6 {
  font-size: 15px;
  position: absolute;
  left: 718px;
  width: 207px;
  text-align: center;
  top: 699px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon2 .origin6-6 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 949px;
  top: 947px;
  font-family: 'Montserrat';
  color: #000;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon2 .weight6-6 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 678px;
  top: 947px;
  font-family: 'Montserrat';
}

.img_altili_sablon2 .product-image6-6 img {
  position: absolute;
  max-height: 10rem;
  left: 742px;
  top: 721px;
}


/* img_altili_sablon2 6-6 Finish */