/* Genel Start */

.img_altili_sablon8 .campaing-dates {
  position: absolute;
  font-size: 28px;
  left: 64px;
  top: 920px;
  width: 387px;
  text-align: left;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_altili_sablon8 .address {
  position: absolute;
  font-size: 19px;
  width: 238px;
  text-align: right;
  top: 940px;
  left: 462px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_altili_sablon8 .pattern-logo img {
  position: absolute;
  left: 775px;
  top: 96px;
  width: 10rem;
}

.img_altili_sablon8 .phone {
  position: absolute;
  font-size: 19px;
  width: 165px;
  text-align: right;
  top: 915px;
  left: 535px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

/* Genel Finish */

/* img_altili_sablon8 6-1 Start */

.img_altili_sablon8 .new-price6-1 {
  font-size: 24px;
  position: absolute;
  left: 228px;
  top: 215px;
  width: 120px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1e4a6f;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon8 .old-price6-1 {
  font-size: 18px;
  position: absolute;
  left: 238px;
  top: 245px;
  width: 100px;
  z-index: 1;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #9cd3e8;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon8 .title6-1 {
  font-size: 18px;
  position: absolute;
  left: 187px;
  width: 180px;
  text-align: center;
  top: 97px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon8 .titleTR6-1 {
  font-size: 16px;
  position: absolute;
  left: 190px;
  width: 170px;
  text-align: center;
  top: 127px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon8 .origin6-1 {
  font-size: 13px;
  position: absolute;
  width: 25px;
  text-align: center;
  left: 333px;
  top: 35px;
  background-color: #1e4a6f;
  color: #fff;
  font-family: 'Montserrat Extra Bold';
}

.img_altili_sablon8 .weight6-1 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 41px;
  top: 285px;
  font-family: 'Montserrat';
}

.img_altili_sablon8 .product-image6-1 img {
  position: absolute;
  max-height: 8rem;
  left: 50px;
  top: 75px;
}


/* img_altili_sablon8 6-2 Finish */


/* img_altili_sablon8 6-2 Start */

.img_altili_sablon8 .new-price6-2 {
  font-size: 24px;
  position: absolute;
  left: 578px;
  top: 215px;
  width: 120px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1e4a6f;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon8 .old-price6-2 {
  font-size: 18px;
  position: absolute;
  left: 588px;
  top: 248px;
  width: 100px;
  z-index: 1;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #9cd3e8;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon8 .title6-2 {
  font-size: 18px;
  position: absolute;
  left: 540px;
  width: 180px;
  text-align: center;
  top: 97px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon8 .titleTR6-2 {
  font-size: 16px;
  position: absolute;
  left: 550px;
  width: 170px;
  text-align: center;
  top: 127px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon8 .origin6-2 {
  font-size: 13px;
  position: absolute;
  width: 25px;
  text-align: center;
  left: 687px;
  top: 35px;
  background-color: #1e4a6f;
  color: #fff;
  font-family: 'Montserrat Extra Bold';
}

.img_altili_sablon8 .weight6-2 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 393px;
  top: 285px;
  font-family: 'Montserrat';
}

.img_altili_sablon8 .product-image6-2 img {
  position: absolute;
  max-height: 8rem;
  left: 410px;
  top: 75px;
}

/* img_altili_sablon8 6-2 Finish */


/* img_altili_sablon8 6-3 Start */

.img_altili_sablon8 .new-price6-3 {
  font-size: 24px;
  position: absolute;
  left: 228px;
  top: 515px;
  width: 120px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1e4a6f;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon8 .old-price6-3 {
  font-size: 18px;
  position: absolute;
  left: 238px;
  top: 545px;
  width: 100px;
  z-index: 1;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #9cd3e8;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon8 .title6-3 {
  font-size: 18px;
  position: absolute;
  left: 187px;
  width: 180px;
  text-align: center;
  top: 384px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon8 .titleTR6-3 {
  font-size: 16px;
  position: absolute;
  left: 190px;
  width: 170px;
  text-align: center;
  top: 413px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon8 .origin6-3 {
  font-size: 13px;
  position: absolute;
  width: 25px;
  text-align: center;
  left: 333px;
  top: 325px;
  background-color: #1e4a6f;
  color: #fff;
  font-family: 'Montserrat Extra Bold';
}

.img_altili_sablon8 .weight6-3 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 41px;
  top: 578px;
  font-family: 'Montserrat';
}

.img_altili_sablon8 .product-image6-3 img {
  position: absolute;
  max-height: 8rem;
  left: 50px;
  top: 352px;
}

/* img_altili_sablon8 6-3 Finish */


/* img_altili_sablon8 6-4 Start */

.img_altili_sablon8 .new-price6-4 {
  font-size: 24px;
  position: absolute;
  left: 578px;
  top: 515px;
  width: 120px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1e4a6f;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon8 .old-price6-4 {
  font-size: 18px;
  position: absolute;
  left: 588px;
  top: 545px;
  width: 100px;
  z-index: 1;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #9cd3e8;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon8 .title6-4 {
  font-size: 18px;
  position: absolute;
  left: 540px;
  width: 180px;
  text-align: center;
  top: 384px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon8 .titleTR6-4 {
  font-size: 16px;
  position: absolute;
  left: 572px;
  width: 145px;
  text-align: center;
  top: 413px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon8 .origin6-4 {
  font-size: 13px;
  position: absolute;
  width: 25px;
  text-align: center;
  left: 687px;
  top: 325px;
  background-color: #1e4a6f;
  color: #fff;
  font-family: 'Montserrat Extra Bold';
}

.img_altili_sablon8 .weight6-4 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 393px;
  top: 578px;
  font-family: 'Montserrat';
}

.img_altili_sablon8 .product-image6-4 img {
  position: absolute;
  max-height: 9rem;
  left: 403px;
  top: 352px;
}

/* img_altili_sablon8 6-4 Finish */

/* img_altili_sablon8 6-5 Start */

.img_altili_sablon8 .new-price6-5 {
  font-size: 24px;
  position: absolute;
  left: 228px;
  top: 803px;
  width: 120px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1e4a6f;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon8 .old-price6-5 {
  font-size: 18px;
  position: absolute;
  left: 238px;
  top: 833px;
  width: 100px;
  z-index: 1;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #9cd3e8;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon8 .title6-5 {
  font-size: 18px;
  position: absolute;
  left: 187px;
  width: 180px;
  text-align: center;
  top: 691px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon8 .titleTR6-5 {
  font-size: 16px;
  position: absolute;
  left: 190px;
  width: 170px;
  text-align: center;
  top: 717px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon8 .origin6-5 {
  font-size: 13px;
  position: absolute;
  width: 25px;
  text-align: center;
  left: 333px;
  top: 620px;
  background-color: #1e4a6f;
  color: #fff;
  font-family: 'Montserrat Extra Bold';
}

.img_altili_sablon8 .weight6-5 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 41px;
  top: 871px;
  font-family: 'Montserrat';
}

.img_altili_sablon8 .product-image6-5 img {
  position: absolute;
  max-height: 8rem;
  left: 50px;
  top: 646px;
}

/* img_altili_sablon8 6-5 Finish */

/* img_altili_sablon8 6-6 Start */

.img_altili_sablon8 .new-price6-6 {
  font-size: 24px;
  position: absolute;
  left: 578px;
  top: 803px;
  width: 120px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1e4a6f;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon8 .old-price6-6 {
  font-size: 18px;
  position: absolute;
  left: 588px;
  top: 833px;
  width: 100px;
  z-index: 1;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #9cd3e8;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon8 .title6-6 {
  font-size: 18px;
  position: absolute;
  left: 540px;
  width: 180px;
  text-align: center;
  top: 691px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon8 .titleTR6-6 {
  font-size: 16px;
  position: absolute;
  left: 550px;
  width: 170px;
  text-align: center;
  top: 717px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon8 .origin6-6 {
  font-size: 13px;
  position: absolute;
  width: 25px;
  text-align: center;
  left: 687px;
  top: 620px;
  background-color: #1e4a6f;
  color: #fff;
  font-family: 'Montserrat Extra Bold';
}

.img_altili_sablon8 .weight6-6 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 393px;
  top: 871px;
  font-family: 'Montserrat';
}

.img_altili_sablon8 .product-image6-6 img {
  position: absolute;
  max-height: 9rem;
  left: 403px;
  top: 646px;
}

/* img_altili_sablon8 6-6 Finish */