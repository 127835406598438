/* img_tekli_sablon8 Start */

.img_tekli_sablon8 .new-price {
  font-size: 55px;
  position: absolute;
  width: 275px;
  text-align: center;
  left: 590px;
  top: 485px;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fff;
  background-color: #f40000;
  border-radius: 11px;
}

.img_tekli_sablon8 .old-price {
  font-size: 42px;
  position: absolute;
  width: 220px;
  text-align: center;
  left: 620px;
  top: 575px;
  text-decoration: line-through;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fff;
  background-color: #828282;
  border-radius: 11px;
}

.img_tekli_sablon8 .title {
  font-size: 50px;
  position: absolute;
  left: 502px;
  top: 340px;
  width: 490px;
  text-align: center;
  text-transform: capitalize;
  color: #424242;
  font-family: 'Montserrat';
  font-weight: bold;
  line-height: 55px;
  overflow-wrap: break-word;
}

.img_tekli_sablon8 .titleTR {
  font-size: 30px;
  position: absolute;
  left: 502px;
  top: 395px;
  width: 490px;
  text-align: center;
  text-transform: capitalize;
  color: #424242;
  font-family: 'Montserrat';
  font-weight: bold;
  line-height: 35px;
  overflow-wrap: break-word;
}

.img_tekli_sablon8 .origin {
  font-size: 20px;
  position: absolute;
  left: 502px;
  top: 435px;
  width: 490px;
  text-align: center;
  text-transform: uppercase;
  color: #424242;
  font-family: 'Montserrat Extra Bold';
  font-weight: bolder;
  overflow-wrap: break-word;
}

.img_tekli_sablon8 .weight {
  font-size: 24px;
  position: absolute;
  width: 460px;
  text-align: center;
  left: 502px;
  top: 650px;
  font-family: 'Montserrat Extra Bold';
  color: #424242;
}

.img_tekli_sablon8 .pattern-logo {
  position: absolute;
  left: 45px;
  top: 75px;
}

.img_tekli_sablon8 .pattern-logo img {
  min-width: 300px;
  max-height: 300px;
}

.img_tekli_sablon8 .product-image img {
  position: absolute;
  max-height: 24rem;
  left: 60px;
  top: 317px;
}

.img_tekli_sablon8 .campaing-dates {
  position: absolute;
  font-size: 29px;
  width: 440px;
  left: 35px;
  top: 947px;
  text-align: left;
  font-family: 'Montserrat Extra Bold';
  color: #565656;
}

.img_tekli_sablon8 .address {
  position: absolute;
  font-size: 25px;
  width: 360px;
  text-align: right;
  top: 953px;
  left: 625px;
  font-family: 'Montserrat Extra Bold';
  color: #565656;
}

.img_tekli_sablon8 .phone {
  position: absolute;
  font-size: 25px;
  width: 200px;
  text-align: center;
  top: 953px;
  left: 383px;
  font-family: 'Montserrat Extra Bold';
  color: #565656;
}

/* img_tekli_sablon8 Finish */