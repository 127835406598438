/* Genel Start */

.img_altili_sablon6 .campaing-dates {
  position: absolute;
  font-size: 28px;
  left: 360px;
  top: 205px;
  width: 387px;
  text-align: left;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_altili_sablon6 .address {
  position: absolute;
  font-size: 16px;
  width: 238px;
  text-align: right;
  top: 226px;
  left: 732px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_altili_sablon6 .pattern-logo img {
  position: absolute;
  left: 65px;
  top: 110px;
  width: 11rem;
}

.img_altili_sablon6 .phone {
  position: absolute;
  font-size: 19px;
  width: 165px;
  text-align: right;
  top: 200px;
  left: 805px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

/* Genel Finish */

/* img_altili_sablon6 6-1 Start */

.img_altili_sablon6 .new-price6-1 {
  font-size: 24px;
  position: absolute;
  left: 480px;
  top: 420px;
  width: 120px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1e4a6f;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon6 .old-price6-1 {
  font-size: 18px;
  position: absolute;
  left: 490px;
  top: 450px;
  width: 100px;
  z-index: 1;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #9cd3e8;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon6 .title6-1 {
  font-size: 17px;
  position: absolute;
  left: 450px;
  width: 170px;
  text-align: center;
  top: 340px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon6 .titleTR6-1 {
  font-size: 14px;
  position: absolute;
  left: 465px;
  width: 145px;
  text-align: center;
  top: 370px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon6 .origin6-1 {
  font-size: 13px;
  position: absolute;
  width: 25px;
  text-align: center;
  left: 585px;
  top: 278px;
  background-color: #1e4a6f;
  color: #fff;
  font-family: 'Montserrat Extra Bold';
}

.img_altili_sablon6 .weight6-1 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 293px;
  top: 485px;
  font-family: 'Montserrat';
}

.img_altili_sablon6 .product-image6-1 img {
  position: absolute;
  max-height: 8rem;
  left: 310px;
  top: 305px;
}


/* img_altili_sablon6 6-2 Finish */


/* img_altili_sablon6 6-2 Start */

.img_altili_sablon6 .new-price6-2 {
  font-size: 24px;
  position: absolute;
  left: 830px;
  top: 420px;
  width: 120px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1e4a6f;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon6 .old-price6-2 {
  font-size: 18px;
  position: absolute;
  left: 840px;
  top: 450px;
  width: 100px;
  z-index: 1;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #9cd3e8;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon6 .title6-2 {
  font-size: 17px;
  position: absolute;
  left: 800px;
  width: 170px;
  text-align: center;
  top: 340px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon6 .titleTR6-2 {
  font-size: 14px;
  position: absolute;
  left: 815px;
  width: 145px;
  text-align: center;
  top: 370px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon6 .origin6-2 {
  font-size: 13px;
  position: absolute;
  width: 25px;
  text-align: center;
  left: 939px;
  top: 278px;
  background-color: #1e4a6f;
  color: #fff;
  font-family: 'Montserrat Extra Bold';
}

.img_altili_sablon6 .weight6-2 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 645px;
  top: 485px;
  font-family: 'Montserrat';
}

.img_altili_sablon6 .product-image6-2 img {
  position: absolute;
  max-height: 8rem;
  left: 655px;
  top: 305px;
}

/* img_altili_sablon6 6-2 Finish */


/* img_altili_sablon6 6-3 Start */

.img_altili_sablon6 .new-price6-3 {
  font-size: 24px;
  position: absolute;
  left: 480px;
  top: 655px;
  width: 120px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1e4a6f;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon6 .old-price6-3 {
  font-size: 18px;
  position: absolute;
  left: 490px;
  top: 685px;
  width: 100px;
  z-index: 1;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #9cd3e8;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon6 .title6-3 {
  font-size: 17px;
  position: absolute;
  left: 450px;
  width: 170px;
  text-align: center;
  top: 572px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon6 .titleTR6-3 {
  font-size: 14px;
  position: absolute;
  left: 471px;
  width: 145px;
  text-align: center;
  top: 602px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon6 .origin6-3 {
  font-size: 13px;
  position: absolute;
  width: 25px;
  text-align: center;
  left: 585px;
  top: 515px;
  background-color: #1e4a6f;
  color: #fff;
  font-family: 'Montserrat Extra Bold';
}

.img_altili_sablon6 .weight6-3 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 293px;
  top: 719px;
  font-family: 'Montserrat';
}

.img_altili_sablon6 .product-image6-3 img {
  position: absolute;
  max-height: 8rem;
  left: 305px;
  top: 540px;
}

/* img_altili_sablon6 6-3 Finish */


/* img_altili_sablon6 6-4 Start */

.img_altili_sablon6 .new-price6-4 {
  font-size: 24px;
  position: absolute;
  left: 830px;
  top: 655px;
  width: 120px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1e4a6f;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon6 .old-price6-4 {
  font-size: 18px;
  position: absolute;
  left: 840px;
  top: 685px;
  width: 100px;
  z-index: 1;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #9cd3e8;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon6 .title6-4 {
  font-size: 17px;
  position: absolute;
  left: 800px;
  width: 170px;
  text-align: center;
  top: 572px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon6 .titleTR6-4 {
  font-size: 14px;
  position: absolute;
  left: 815px;
  width: 145px;
  text-align: center;
  top: 602px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon6 .origin6-4 {
  font-size: 13px;
  position: absolute;
  width: 25px;
  text-align: center;
  left: 939px;
  top: 515px;
  background-color: #1e4a6f;
  color: #fff;
  font-family: 'Montserrat Extra Bold';
}

.img_altili_sablon6 .weight6-4 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 645px;
  top: 719px;
  font-family: 'Montserrat';
}

.img_altili_sablon6 .product-image6-4 img {
  position: absolute;
  max-height: 8rem;
  left: 655px;
  top: 540px;
}

/* img_altili_sablon6 6-4 Finish */

/* img_altili_sablon6 6-5 Start */

.img_altili_sablon6 .new-price6-5 {
  font-size: 24px;
  position: absolute;
  left: 480px;
  top: 895px;
  width: 120px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1e4a6f;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon6 .old-price6-5 {
  font-size: 18px;
  position: absolute;
  left: 490px;
  top: 925px;
  width: 100px;
  z-index: 1;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #9cd3e8;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon6 .title6-5 {
  font-size: 17px;
  position: absolute;
  left: 450px;
  width: 170px;
  text-align: center;
  top: 823px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon6 .titleTR6-5 {
  font-size: 14px;
  position: absolute;
  left: 471px;
  width: 145px;
  text-align: center;
  top: 853px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon6 .origin6-5 {
  font-size: 13px;
  position: absolute;
  width: 25px;
  text-align: center;
  left: 585px;
  top: 753px;
  background-color: #1e4a6f;
  color: #fff;
  font-family: 'Montserrat Extra Bold';
}

.img_altili_sablon6 .weight6-5 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 293px;
  top: 960px;
  font-family: 'Montserrat';
}

.img_altili_sablon6 .product-image6-5 img {
  position: absolute;
  max-height: 8rem;
  left: 305px;
  top: 780px;
}

/* img_altili_sablon6 6-5 Finish */

/* img_altili_sablon6 6-6 Start */

.img_altili_sablon6 .new-price6-6 {
  font-size: 24px;
  position: absolute;
  left: 830px;
  top: 895px;
  width: 120px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1e4a6f;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon6 .old-price6-6 {
  font-size: 18px;
  position: absolute;
  left: 840px;
  top: 925px;
  width: 100px;
  z-index: 1;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #9cd3e8;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon6 .title6-6 {
  font-size: 17px;
  position: absolute;
  left: 800px;
  width: 170px;
  text-align: center;
  top: 823px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon6 .titleTR6-6 {
  font-size: 14px;
  position: absolute;
  left: 815px;
  width: 145px;
  text-align: center;
  top: 850px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon6 .origin6-6 {
  font-size: 13px;
  position: absolute;
  width: 25px;
  text-align: center;
  left: 939px;
  top: 753px;
  background-color: #1e4a6f;
  color: #fff;
  font-family: 'Montserrat Extra Bold';
}

.img_altili_sablon6 .weight6-6 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 645px;
  top: 960px;
  font-family: 'Montserrat';
}

.img_altili_sablon6 .product-image6-6 img {
  position: absolute;
  max-height: 8rem;
  left: 655px;
  top: 780px;
}

/* img_altili_sablon6 6-6 Finish */