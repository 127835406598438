/* Genel Start */

.img_dokuzlu_sablon7 .campaing-dates {
  position: absolute;
  font-size: 30px;
  left: 0;
  top: 945px;
  width: 387px;
  text-align: center;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_dokuzlu_sablon7 .address {
  position: absolute;
  font-size: 23px;
  width: 337px;
  text-align: left;
  top: 952px;
  left: 415px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_dokuzlu_sablon7 .pattern-logo img {
  position: absolute;
  left: 50px;
  top: 840px;
  width: 10rem;
}

.img_dokuzlu_sablon7 .phone {
  position: absolute;
  font-size: 23px;
  width: 337px;
  text-align: left;
  top: 950px;
  left: 695px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

/* Genel Finish */

/* img_dokuzlu_sablon7 9-1 Start */

.img_dokuzlu_sablon7 .new-price9-1 {
  font-size: 16px;
  position: absolute;
  left: 290px;
  top: 210px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1a4c71;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon7 .old-price9-1 {
  font-size: 14px;
  position: absolute;
  left: 295px;
  top: 234px;
  width: 72px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #babdd0;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon7 .title9-1 {
  font-size: 21px;
  position: absolute;
  left: 107px;
  width: 207px;
  text-align: left;
  top: 44px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon7 .titleTR9-1 {
  font-size: 15px;
  position: absolute;
  left: 107px;
  width: 125px;
  text-align: left;
  top: 71px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon7 .origin9-1 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 340px;
  top: 35px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon7 .weight9-1 {
  font-size: 8px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 107px;
  top: 248px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon7 .product-image9-1 img {
  position: absolute;
  max-height: 7rem;
  left: 180px;
  top: 95px;
}


/* img_dokuzlu_sablon7 9-1 Finish */

/* img_dokuzlu_sablon7 9-2 Start */

.img_dokuzlu_sablon7 .new-price9-2 {
  font-size: 16px;
  position: absolute;
  left: 585px;
  top: 210px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1a4c71;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon7 .old-price9-2 {
  font-size: 14px;
  position: absolute;
  left: 590px;
  top: 234px;
  width: 72px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #babdd0;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon7 .title9-2 {
  font-size: 21px;
  position: absolute;
  left: 404px;
  width: 207px;
  text-align: left;
  top: 44px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon7 .titleTR9-2 {
  font-size: 15px;
  position: absolute;
  left: 404px;
  width: 125px;
  text-align: left;
  top: 71px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon7 .origin9-2 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 635px;
  top: 35px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon7 .weight9-2 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 405px;
  top: 248px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon7 .product-image9-2 img {
  position: absolute;
  max-height: 7rem;
  left: 465px;
  top: 95px;
}


/* img_dokuzlu_sablon7 9-2 Finish */

/* img_dokuzlu_sablon7 9-3 Start */

.img_dokuzlu_sablon7 .new-price9-3 {
  font-size: 16px;
  position: absolute;
  left: 880px;
  top: 210px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1a4c71;
  border-radius: 5px;
  color: #ffffff;
}

.img_dokuzlu_sablon7 .old-price9-3 {
  font-size: 14px;
  position: absolute;
  left: 885px;
  top: 234px;
  width: 72px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #babdd0;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon7 .title9-3 {
  font-size: 21px;
  position: absolute;
  left: 705px;
  width: 207px;
  text-align: left;
  top: 44px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon7 .titleTR9-3 {
  font-size: 15px;
  position: absolute;
  left: 705px;
  width: 125px;
  text-align: left;
  top: 71px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon7 .origin9-3 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 930px;
  top: 35px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon7 .weight9-3 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 700px;
  top: 248px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon7 .product-image9-3 img {
  position: absolute;
  max-height: 7rem;
  left: 750px;
  top: 95px;
}


/* img_dokuzlu_sablon7 9-3 Finish */

/* img_dokuzlu_sablon7 9-4 Start */

.img_dokuzlu_sablon7 .new-price9-4 {
  font-size: 16px;
  position: absolute;
  left: 290px;
  top: 448px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1a4c71;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon7 .old-price9-4 {
  font-size: 14px;
  position: absolute;
  left: 295px;
  top: 472px;
  width: 72px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #babdd0;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon7 .title9-4 {
  font-size: 21px;
  position: absolute;
  left: 107px;
  width: 207px;
  text-align: left;
  top: 283px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon7 .titleTR9-4 {
  font-size: 15px;
  position: absolute;
  left: 107px;
  width: 125px;
  text-align: left;
  top: 310px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon7 .origin9-4 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 340px;
  top: 272px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon7 .weight9-4 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 107px;
  top: 484px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon7 .product-image9-4 img {
  position: absolute;
  max-height: 7rem;
  left: 180px;
  top: 334px;
}


/* img_dokuzlu_sablon7 9-4 Finish */

/* img_dokuzlu_sablon7 9-5 Start */

.img_dokuzlu_sablon7 .new-price9-5 {
  font-size: 16px;
  position: absolute;
  left: 585px;
  top: 448px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1a4c71;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon7 .old-price9-5 {
  font-size: 14px;
  position: absolute;
  left: 590px;
  top: 472px;
  width: 72px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #babdd0;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon7 .title9-5 {
  font-size: 21px;
  position: absolute;
  left: 404px;
  width: 207px;
  text-align: left;
  top: 283px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon7 .titleTR9-5 {
  font-size: 15px;
  position: absolute;
  left: 404px;
  width: 125px;
  text-align: left;
  top: 310px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon7 .origin9-5 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 635px;
  top: 272px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon7 .weight9-5 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 405px;
  top: 484px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon7 .product-image9-5 img {
  position: absolute;
  max-height: 7rem;
  left: 465px;
  top: 334px;
}


/* img_dokuzlu_sablon7 9-5 Finish */

/* img_dokuzlu_sablon7 9-6 Start */

.img_dokuzlu_sablon7 .new-price9-6 {
  font-size: 16px;
  position: absolute;
  left: 880px;
  top: 448px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1a4c71;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon7 .old-price9-6 {
  font-size: 14px;
  position: absolute;
  left: 885px;
  top: 472px;
  width: 72px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #babdd0;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon7 .title9-6 {
  font-size: 21px;
  position: absolute;
  left: 705px;
  width: 207px;
  text-align: left;
  top: 283px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon7 .titleTR9-6 {
  font-size: 15px;
  position: absolute;
  left: 705px;
  width: 125px;
  text-align: left;
  top: 310px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon7 .origin9-6 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 930px;
  top: 272px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon7 .weight9-6 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 705px;
  top: 484px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon7 .product-image9-6 img {
  position: absolute;
  max-height: 7rem;
  left: 750px;
  top: 334px;
}


/* img_dokuzlu_sablon7 9-6 Finish */




.img_dokuzlu_sablon7 .new-price9-7 {
  font-size: 16px;
  position: absolute;
  left: 290px;
  top: 682px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1a4c71;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon7 .old-price9-7 {
  font-size: 14px;
  position: absolute;
  left: 295px;
  top: 706px;
  width: 72px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #babdd0;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon7 .title9-7 {
  font-size: 21px;
  position: absolute;
  left: 107px;
  width: 207px;
  text-align: left;
  top: 516px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon7 .titleTR9-7 {
  font-size: 15px;
  position: absolute;
  left: 107px;
  width: 125px;
  text-align: left;
  top: 543px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon7 .origin9-7 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 340px;
  top: 507px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon7 .weight9-7 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 107px;
  top: 720px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon7 .product-image9-7 img {
  position: absolute;
  max-height: 7rem;
  left: 180px;
  top: 567px;
}


/* img_dokuzlu_sablon7 9-4 Finish */

/* img_dokuzlu_sablon7 9-5 Start */

.img_dokuzlu_sablon7 .new-price9-8 {
  font-size: 16px;
  position: absolute;
  left: 585px;
  top: 682px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1a4c71;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon7 .old-price9-8 {
  font-size: 14px;
  position: absolute;
  left: 590px;
  top: 706px;
  width: 72px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #babdd0;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon7 .title9-8 {
  font-size: 21px;
  position: absolute;
  left: 404px;
  width: 207px;
  text-align: left;
  top: 516px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon7 .titleTR9-8 {
  font-size: 15px;
  position: absolute;
  left: 404px;
  width: 125px;
  text-align: left;
  top: 543px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon7 .origin9-8 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 635px;
  top: 507px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon7 .weight9-8 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 405px;
  top: 720px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon7 .product-image9-8 img {
  position: absolute;
  max-height: 7rem;
  left: 465px;
  top: 567px;
}


/* img_dokuzlu_sablon7 9-5 Finish */

/* img_dokuzlu_sablon7 9-6 Start */

.img_dokuzlu_sablon7 .new-price9-9 {
  font-size: 16px;
  position: absolute;
  left: 880px;
  top: 682px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #1a4c71;
  border-radius: 5px;
  color: #fff;
}

.img_dokuzlu_sablon7 .old-price9-9 {
  font-size: 14px;
  position: absolute;
  left: 885px;
  top: 706px;
  width: 72px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #babdd0;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon7 .title9-9 {
  font-size: 21px;
  position: absolute;
  left: 705px;
  width: 207px;
  text-align: left;
  top: 516px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon7 .titleTR9-9 {
  font-size: 15px;
  position: absolute;
  left: 705px;
  width: 125px;
  text-align: left;
  top: 543px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon7 .origin9-9 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 930px;
  top: 507px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_dokuzlu_sablon7 .weight9-9 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 700px;
  top: 720px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon7 .product-image9-9 img {
  position: absolute;
  max-height: 7rem;
  left: 750px;
  top: 567px;
}