/* Genel Start */

.img_altili_sablon7 .campaing-dates {
  position: absolute;
  font-size: 30px;
  left: 0;
  top: 945px;
  width: 387px;
  text-align: center;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_altili_sablon7 .address {
  position: absolute;
  font-size: 23px;
  width: 337px;
  text-align: left;
  top: 952px;
  left: 415px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_altili_sablon7 .pattern-logo img {
  position: absolute;
  left: 50px;
  top: 40px;
  width: 10rem;
}

.img_altili_sablon7 .phone {
  position: absolute;
  font-size: 23px;
  width: 337px;
  text-align: left;
  top: 950px;
  left: 695px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

/* Genel Finish */

/* img_altili_sablon7 6-1 Start */

.img_altili_sablon7 .new-price6-1 {
  font-size: 18px;
  position: absolute;
  left: 249px;
  top: 578px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #c40816;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon7 .old-price6-1 {
  font-size: 16px;
  position: absolute;
  left: 258px;
  top: 603px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f7e792;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon7 .title6-1 {
  font-size: 21px;
  position: absolute;
  left: 44px;
  width: 207px;
  text-align: left;
  top: 367px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon7 .titleTR6-1 {
  font-size: 15px;
  position: absolute;
  left: 44px;
  width: 125px;
  text-align: left;
  top: 397px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon7 .origin6-1 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 313px;
  top: 370px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon7 .weight6-1 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 50px;
  top: 613px;
  font-family: 'Montserrat';
}

.img_altili_sablon7 .product-image6-1 img {
  position: absolute;
  max-height: 11rem;
  left: 90px;
  top: 410px;
}


/* img_altili_sablon7 6-1 Finish */

/* img_altili_sablon7 6-2 Start */

.img_altili_sablon7 .new-price6-2 {
  font-size: 18px;
  position: absolute;
  left: 556px;
  top: 578px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #c40816;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon7 .old-price6-2 {
  font-size: 16px;
  position: absolute;
  left: 565px;
  top: 603px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f7e792;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon7 .title6-2 {
  font-size: 21px;
  position: absolute;
  left: 352px;
  width: 207px;
  text-align: left;
  top: 367px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon7 .titleTR6-2 {
  font-size: 15px;
  position: absolute;
  left: 352px;
  width: 125px;
  text-align: left;
  top: 397px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon7 .origin6-2 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 623px;
  top: 370px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon7 .weight6-2 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 360px;
  top: 613px;
  font-family: 'Montserrat';
}

.img_altili_sablon7 .product-image6-2 img {
  position: absolute;
  max-height: 11rem;
  left: 400px;
  top: 410px;
}


/* img_altili_sablon7 6-2 Finish */

/* img_altili_sablon7 6-3 Start */

.img_altili_sablon7 .new-price6-3 {
  font-size: 18px;
  position: absolute;
  left: 861px;
  top: 578px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #c40816;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon7 .old-price6-3 {
  font-size: 16px;
  position: absolute;
  left: 870px;
  top: 603px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f7e792;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon7 .title6-3 {
  font-size: 21px;
  position: absolute;
  left: 656px;
  width: 207px;
  text-align: left;
  top: 367px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon7 .titleTR6-3 {
  font-size: 15px;
  position: absolute;
  left: 656px;
  width: 125px;
  text-align: left;
  top: 397px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon7 .origin6-3 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 928px;
  top: 370px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon7 .weight6-3 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 665px;
  top: 613px;
  font-family: 'Montserrat';
}

.img_altili_sablon7 .product-image6-3 img {
  position: absolute;
  max-height: 11rem;
  left: 705px;
  top: 410px;
}


/* img_altili_sablon7 6-3 Finish */

/* img_altili_sablon7 6-4 Start */

.img_altili_sablon7 .new-price6-4 {
  font-size: 18px;
  position: absolute;
  left: 249px;
  top: 848px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #c40816;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon7 .old-price6-4 {
  font-size: 16px;
  position: absolute;
  left: 258px;
  top: 873px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f7e792;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon7 .title6-4 {
  font-size: 21px;
  position: absolute;
  left: 44px;
  width: 207px;
  text-align: left;
  top: 637px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon7 .titleTR6-4 {
  font-size: 15px;
  position: absolute;
  left: 44px;
  width: 125px;
  text-align: left;
  top: 667px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon7 .origin6-4 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 313px;
  top: 640px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon7 .weight6-4 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 50px;
  top: 883px;
  font-family: 'Montserrat';
}

.img_altili_sablon7 .product-image6-4 img {
  position: absolute;
  max-height: 11rem;
  left: 90px;
  top: 680px;
}


/* img_altili_sablon7 6-4 Finish */

/* img_altili_sablon7 6-5 Start */

.img_altili_sablon7 .new-price6-5 {
  font-size: 18px;
  position: absolute;
  left: 556px;
  top: 848px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #c40816;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon7 .old-price6-5 {
  font-size: 16px;
  position: absolute;
  left: 565px;
  top: 873px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f7e792;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon7 .title6-5 {
  font-size: 21px;
  position: absolute;
  left: 352px;
  width: 207px;
  text-align: left;
  top: 637px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon7 .titleTR6-5 {
  font-size: 15px;
  position: absolute;
  left: 352px;
  width: 125px;
  text-align: left;
  top: 667px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon7 .origin6-5 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 623px;
  top: 640px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon7 .weight6-5 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 360px;
  top: 883px;
  font-family: 'Montserrat';
}

.img_altili_sablon7 .product-image6-5 img {
  position: absolute;
  max-height: 11rem;
  left: 400px;
  top: 680px;
}


/* img_altili_sablon7 6-5 Finish */

/* img_altili_sablon7 6-6 Start */

.img_altili_sablon7 .new-price6-6 {
  font-size: 18px;
  position: absolute;
  left: 861px;
  top: 848px;
  width: 93px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #c40816;
  border-radius: 5px;
  color: #fff;
}

.img_altili_sablon7 .old-price6-6 {
  font-size: 16px;
  position: absolute;
  left: 870px;
  top: 873px;
  width: 76px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #f7e792;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_altili_sablon7 .title6-6 {
  font-size: 21px;
  position: absolute;
  left: 660px;
  width: 207px;
  text-align: left;
  top: 637px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon7 .titleTR6-6 {
  font-size: 15px;
  position: absolute;
  left: 660px;
  width: 125px;
  text-align: left;
  top: 667px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_altili_sablon7 .origin6-6 {
  font-size: 14px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 928px;
  top: 640px;
  font-family: 'Montserrat';
  background-color: red;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.img_altili_sablon7 .weight6-6 {
  font-size: 10px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 665px;
  top: 883px;
  font-family: 'Montserrat';
}

.img_altili_sablon7 .product-image6-6 img {
  position: absolute;
  max-height: 11rem;
  left: 705px;
  top: 680px;
}


/* img_altili_sablon7 6-6 Finish */