/* img_tekli_sablon11 Start */

.img_tekli_sablon11 .new-price {
  font-size: 60px;
  position: absolute;
  width: 300px;
  text-align: center;
  left: 660px;
  top: 280px;
  font-family: 'Montserrat Extra Bold';
  color: #ffffff;
  background-color: #a81d22;
  border-radius: 11px;
}

.img_tekli_sablon11 .old-price {
  font-size: 45px;
  position: absolute;
  width: 245px;
  text-align: center;
  left: 715px;
  top: 370px;
  text-decoration: line-through;
  font-family: 'Montserrat Extra Bold';
  color: #070707;
  background-color: #f9bbbc;
  border-radius: 11px;
}

.img_tekli_sablon11 .title {
  font-size: 48px;
  position: absolute;
  left: 179px;
  top: 780px;
  width: 633px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  font-family: 'Montserrat Extra Bold';
  font-weight: bolder;
  line-height: 55px;
  overflow-wrap: break-word;
}

.img_tekli_sablon11 .titleTR {
  font-size: 30px;
  position: absolute;
  left: 300px;
  top: 720px;
  width: 330px;
  text-align: center;
  text-transform: capitalize;
  color: #070707;
  font-family: 'Montserrat';
  font-weight: bolder;
  line-height: 40px;
  overflow-wrap: break-word;
}

.img_tekli_sablon11 .origin {
  font-size: 35px;
  position: absolute;
  left: 860px;
  top: 845px;
  width: 89px;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  font-family: 'Montserrat Extra Bold';
  font-weight: bolder;
  overflow-wrap: break-word;
}

.img_tekli_sablon11 .weight {
  font-size: 19px;
  position: absolute;
  width: 310px;
  text-align: center;
  left: 100px;
  top: 860px;
  font-family: 'Montserrat';
  color: #070707;
}

.img_tekli_sablon11 .pattern-logo {
  position: absolute;
  left: 108px;
  top: 102px;
}

.img_tekli_sablon11 .pattern-logo img {
  min-width: 200px;
  max-height: 200px;
}

.img_tekli_sablon11 .product-image img {
  position: absolute;
  max-height: 24rem;
  left: 110px;
  top: 320px;
}

.img_tekli_sablon11 .campaing-dates {
  position: absolute;
  font-size: 35px;
  width: 440px;
  left: 0px;
  top: 937px;
  text-align: center;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_tekli_sablon11 .address {
  position: absolute;
  font-size: 12px;
  width: 337px;
  text-align: center;
  top: 5px;
  left: 341px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_tekli_sablon11 .phone {
  position: absolute;
  font-size: 35px;
  width: 335px;
  text-align: center;
  top: 937px;
  left: 690px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

/* img_tekli_sablon11 Finish */