@media only screen and (min-width:578px) and (max-width:992px) {

  .home-pattern-sd,
  .home-pattern-ed {
    margin-top: 0.2rem !important;
  }

  .home-pattern {
    margin: 1rem;
  }

  .product-cart,
  .cart-list {
    width: 80% !important;
  }

  .box {
    width: 80% !important;
  }

  .close-icon {
    right: calc(10% - 12px) !important;
  }

  .select-pattern-title {
    position: relative;
  }

  .img_sablon1 {
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 800px;
    height: 800px;
    position: absolute;
    top: -100% !important;
    left: -300%;

  }

  .img_sablon1-mobile {
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 1000px !important;
    height: 1000px !important;
    top: -9rem !important;
    left: -135px;
    position: absolute;
    transform: scale(0.45) !important;
  }

  .img_sablon2-mobile {
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 1000px !important;
    width: 1000px !important;
    position: absolute;
    margin-top: -1000% !important;
    transform: scale(1);
  }

  /* .img_tekli_sablon1 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-1.jpg');
    background-size: cover !important;
  }

  .img_tekli_sablon2 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-2.jpg');
    background-size: cover !important;
  }

  .img_tekli_sablon3 {
    background-image: url('../images/sablonlar/tekli/tekli-sablon-3.jpg');
    background-size: cover !important;
  }

  .img_uclu_sablon1 {
    background-image: url('../images/sablonlar/uclu/uclu-sablon-1.jpg');
    background-size: cover !important;
  }

  .img_altili_sablon1 {
    background-image: url('../images/sablonlar/altili/altili-sablon-1.jpg');
    background-size: cover !important;
  }

  .img_dokuzlu_sablon1 {
    background-image: url('../images/sablonlar/dokuzlu/dokuzlu-sablon-1.jpg');
    background-size: cover !important;
  } */

  .price1 {
    font-size: 70px;
    position: absolute;
    left: 30px;
    top: 300px;
  }

  .title1 {
    font-size: 70px;
    position: absolute;
    right: 30px;
    top: 10px;
    text-transform: uppercase;
  }

  .desc1 {
    font-size: 23px;
    position: absolute;
    right: 80px;
    top: 335px;
  }

  .pattern-canvas {
    display: grid;
    justify-content: center;
    margin-top: -10% !important;
    margin-bottom: -30% !important;
    z-index: -1 !important;
  }

  .pattern-canvas .img {
    position: inherit !important;
  }

  .product-list-filter-row {
    float: left;
    overflow-x: auto !important;
    padding-bottom: 10px;
    width: 100% !important;
  }

  .product-list-filter {
    width: 300% !important;
    max-width: 300% !important;
    float: left;
    cursor: pointer;
  }
}