/* Genel Start */
.img_dokuzlu_sablon2 .campaing-dates {
  position: absolute;
  font-size: 25px;
  left: 658px;
  top: 153px;
  width: 387px;
  text-align: center;
  font-family: 'Montserrat Extra Bold';
}

.img_dokuzlu_sablon2 .address {
  position: absolute;
  font-size: 13px;
  width: 337px;
  text-align: left;
  top: 10px;
  left: 795px;
  font-family: 'Montserrat Extra Bold';
  color: #000;
}

.img_dokuzlu_sablon2 .pattern-logo img {
  position: absolute;
  left: 200px;
  top: 40px;
  width: 16rem;
}

.img_dokuzlu_sablon2 .phone {
  position: absolute;
  font-size: 23px;
  width: 337px;
  text-align: left;
  top: 953px;
  left: 35px;
  font-family: 'Montserrat Extra Bold';
  color: #2bac50;
}

/* Genel Finish */


/* img_dokuzlu_sablon1 9-1 Start */

.img_dokuzlu_sablon2 .new-price9-1 {
  font-size: 18px;
  position: absolute;
  left: 275px;
  top: 380px;
  width: 90px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #FFCF01;
  border-radius: 10px;
  color: rgb(0, 0, 0);
}

.img_dokuzlu_sablon2 .old-price9-1 {
  font-size: 15px;
  position: absolute;
  left: 280px;
  top: 407px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #FFF3BF;
  border-radius: 10px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon2 .title9-1 {
  font-size: 19px;
  position: absolute;
  left: 210px;
  width: 240px;
  text-align: center;
  top: 218px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon2 .titleTR9-1 {
  font-size: 16px;
  position: absolute;
  left: 210px;
  width: 238px;
  text-align: center;
  top: 248px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon2 .origin9-1 {
  font-size: 13px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 415px;
  top: 435px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon2 .weight9-1 {
  font-size: 9px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 220px;
  top: 440px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon2 .product-image9-1 img {
  position: absolute;
  max-height: 6rem;
  left: 270px;
  top: 275px;
}


/* img_dokuzlu_sablon1 9-2 Finish */


/* img_dokuzlu_sablon1 9-2 Start */

.img_dokuzlu_sablon2 .new-price9-2 {
  font-size: 18px;
  position: absolute;
  left: 527px;
  top: 380px;
  width: 90px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #FFCF01;
  border-radius: 10px;
  color: rgb(0, 0, 0);
}

.img_dokuzlu_sablon2 .old-price9-2 {
  font-size: 15px;
  position: absolute;
  left: 532px;
  top: 407px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #FFF3BF;
  border-radius: 10px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon2 .title9-2 {
  font-size: 19px;
  position: absolute;
  left: 459px;
  width: 240px;
  text-align: center;
  top: 218px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon2 .titleTR9-2 {
  font-size: 16px;
  position: absolute;
  left: 465px;
  width: 230px;
  text-align: center;
  top: 248px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon2 .origin9-2 {
  font-size: 13px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 665px;
  top: 435px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon2 .weight9-2 {
  font-size: 9px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 470px;
  top: 440px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon2 .product-image9-2 img {
  position: absolute;
  max-height: 6rem;
  left: 528px;
  top: 275px;
}

/* img_dokuzlu_sablon1 9-2 Finish */


/* img_dokuzlu_sablon1 9-3 Start */

.img_dokuzlu_sablon2 .new-price9-3 {
  font-size: 18px;
  position: absolute;
  left: 775px;
  top: 380px;
  width: 90px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #FFCF01;
  border-radius: 10px;
  color: rgb(0, 0, 0);
}

.img_dokuzlu_sablon2 .old-price9-3 {
  font-size: 15px;
  position: absolute;
  left: 780px;
  top: 407px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #FFF3BF;
  border-radius: 10px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon2 .title9-3 {
  font-size: 19px;
  position: absolute;
  left: 710px;
  width: 240px;
  text-align: center;
  top: 218px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon2 .titleTR9-3 {
  font-size: 16px;
  position: absolute;
  left: 715px;
  width: 230px;
  text-align: center;
  top: 248px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon2 .origin9-3 {
  font-size: 13px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 920px;
  top: 435px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon2 .weight9-3 {
  font-size: 9px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 725px;
  top: 440px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon2 .product-image9-3 img {
  position: absolute;
  max-height: 6rem;
  left: 770px;
  top: 275px;
}

/* img_dokuzlu_sablon1 9-3 Finish */


/* img_dokuzlu_sablon1 9-4 Start */

.img_dokuzlu_sablon2 .new-price9-4 {
  font-size: 18px;
  position: absolute;
  left: 275px;
  top: 645px;
  width: 90px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #FFCF01;
  border-radius: 10px;
  color: rgb(0, 0, 0);
}

.img_dokuzlu_sablon2 .old-price9-4 {
  font-size: 15px;
  position: absolute;
  left: 280px;
  top: 673px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #FFF3BF;
  border-radius: 10px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon2 .title9-4 {
  font-size: 19px;
  position: absolute;
  left: 210px;
  width: 240px;
  text-align: center;
  top: 477px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon2 .titleTR9-4 {
  font-size: 16px;
  position: absolute;
  left: 210px;
  width: 230px;
  text-align: center;
  top: 510px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon2 .origin9-4 {
  font-size: 13px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 415px;
  top: 695px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon2 .weight9-4 {
  font-size: 9px;
  position: absolute;
  width: 145px;
  text-align: left;
  left: 220px;
  top: 700px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon2 .product-image9-4 img {
  position: absolute;
  max-height: 6rem;
  left: 270px;
  top: 537px;
}

/* img_dokuzlu_sablon1 9-4 Finish */

/* img_dokuzlu_sablon1 9-5 Start */

.img_dokuzlu_sablon2 .new-price9-5 {
  font-size: 18px;
  position: absolute;
  left: 527px;
  top: 645px;
  width: 90px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #FFCF01;
  border-radius: 10px;
  color: rgb(0, 0, 0);
}

.img_dokuzlu_sablon2 .old-price9-5 {
  font-size: 15px;
  position: absolute;
  left: 532px;
  top: 673px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #FFF3BF;
  border-radius: 10px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon2 .title9-5 {
  font-size: 19px;
  position: absolute;
  left: 455px;
  width: 240px;
  text-align: center;
  top: 477px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon2 .titleTR9-5 {
  font-size: 16px;
  position: absolute;
  left: 465px;
  width: 230px;
  text-align: center;
  top: 510px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon2 .origin9-5 {
  font-size: 13px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 665px;
  top: 695px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon2 .weight9-5 {
  font-size: 9px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 470px;
  top: 700px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon2 .product-image9-5 img {
  position: absolute;
  max-height: 6rem;
  left: 528px;
  top: 537px;
}

/* img_dokuzlu_sablon1 9-5 Finish */

/* img_dokuzlu_sablon1 9-6 Start */

.img_dokuzlu_sablon2 .new-price9-6 {
  font-size: 18px;
  position: absolute;
  left: 775px;
  top: 645px;
  width: 90px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #FFCF01;
  border-radius: 10px;
  color: rgb(0, 0, 0);
}

.img_dokuzlu_sablon2 .old-price9-6 {
  font-size: 15px;
  position: absolute;
  left: 780px;
  top: 673px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #FFF3BF;
  border-radius: 5px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon2 .title9-6 {
  font-size: 19px;
  position: absolute;
  left: 710px;
  width: 240px;
  text-align: center;
  top: 477px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon2 .titleTR9-6 {
  font-size: 16px;
  position: absolute;
  left: 715px;
  width: 230px;
  text-align: center;
  top: 510px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon2 .origin9-6 {
  font-size: 13px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 920px;
  top: 695px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon2 .weight9-6 {
  font-size: 9px;
  position: absolute;
  width: 145px;
  text-align: left;
  left: 725px;
  top: 700px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon2 .product-image9-6 img {
  position: absolute;
  max-height: 6rem;
  left: 770px;
  top: 537px;
}

/* img_dokuzlu_sablon1 9-6 Finish */

/* img_dokuzlu_sablon1 9-7 Start */

.img_dokuzlu_sablon2 .new-price9-7 {
  font-size: 18px;
  position: absolute;
  left: 275px;
  top: 900px;
  width: 90px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #FFCF01;
  border-radius: 10px;
  color: rgb(0, 0, 0);
}

.img_dokuzlu_sablon2 .old-price9-7 {
  font-size: 15px;
  position: absolute;
  left: 280px;
  top: 928px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #FFF3BF;
  border-radius: 10px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon2 .title9-7 {
  font-size: 19px;
  position: absolute;
  left: 210px;
  width: 240px;
  text-align: center;
  top: 738px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon2 .titleTR9-7 {
  font-size: 16px;
  position: absolute;
  left: 210px;
  width: 238px;
  text-align: center;
  top: 768px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon2 .origin9-7 {
  font-size: 13px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 415px;
  top: 950px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon2 .weight9-7 {
  font-size: 9px;
  position: absolute;
  width: 145px;
  text-align: left;
  left: 220px;
  top: 955px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon2 .product-image9-7 img {
  position: absolute;
  max-height: 6rem;
  left: 270px;
  top: 798px;
}

/* img_dokuzlu_sablon1 9-7 Finish */

/* img_dokuzlu_sablon1 9-8 Start */

.img_dokuzlu_sablon2 .new-price9-8 {
  font-size: 18px;
  position: absolute;
  left: 527px;
  top: 900px;
  width: 90px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #FFCF01;
  border-radius: 10px;
  color: rgb(0, 0, 0);
}

.img_dokuzlu_sablon2 .old-price9-8 {
  font-size: 15px;
  position: absolute;
  left: 532px;
  top: 928px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #FFF3BF;
  border-radius: 10px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon2 .title9-8 {
  font-size: 19px;
  position: absolute;
  left: 455px;
  width: 240px;
  text-align: center;
  top: 738px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon2 .titleTR9-8 {
  font-size: 16px;
  position: absolute;
  left: 465px;
  width: 230px;
  text-align: center;
  top: 768px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon2 .origin9-8 {
  font-size: 13px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 665px;
  top: 950px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon2 .weight9-8 {
  font-size: 9px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 470px;
  top: 955px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon2 .product-image9-8 img {
  position: absolute;
  max-height: 6rem;
  left: 528px;
  top: 798px;
}

/* img_dokuzlu_sablon1 9-8 Finish */

/* img_dokuzlu_sablon1 9-9 Start */

.img_dokuzlu_sablon2 .new-price9-9 {
  font-size: 18px;
  position: absolute;
  left: 775px;
  top: 900px;
  width: 90px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #FFCF01;
  border-radius: 10px;
  color: rgb(0, 0, 0);
}

.img_dokuzlu_sablon2 .old-price9-9 {
  font-size: 15px;
  position: absolute;
  left: 780px;
  top: 928px;
  width: 80px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  background-color: #FFF3BF;
  border-radius: 10px;
  text-decoration: line-through;
}

.img_dokuzlu_sablon2 .title9-9 {
  font-size: 19px;
  position: absolute;
  left: 712px;
  width: 240px;
  text-align: center;
  top: 738px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon2 .titleTR9-9 {
  font-size: 16px;
  position: absolute;
  left: 715px;
  width: 230px;
  text-align: center;
  top: 768px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_dokuzlu_sablon2 .origin9-9 {
  font-size: 13px;
  position: absolute;
  width: 30px;
  text-align: left;
  left: 920px;
  top: 950px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon2 .weight9-9 {
  font-size: 9px;
  position: absolute;
  width: 160px;
  text-align: left;
  left: 725px;
  top: 955px;
  font-family: 'Montserrat';
}

.img_dokuzlu_sablon2 .product-image9-9 img {
  position: absolute;
  max-height: 6rem;
  left: 770px;
  top: 798px;
}

/* img_dokuzlu_sablon1 9-9 Finish */