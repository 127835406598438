/* img_tekli_sablon7 Start */

.img_tekli_sablon7 .new-price {
  font-size: 49px;
  position: absolute;
  width: 255px;
  text-align: center;
  left: 682px;
  top: 482px;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fff;
  background-color: #fe0000;
  border-radius: 11px;
}

.img_tekli_sablon7 .old-price {
  font-size: 45px;
  position: absolute;
  width: 214px;
  text-align: center;
  left: 708px;
  top: 578px;
  text-decoration: line-through;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fe0000;
  background-color: #c3f97f;
  border-radius: 11px;
}

.img_tekli_sablon7 .title {
  font-size: 55px;
  position: absolute;
  left: 10%;
  top: 282px;
  width: 80%;
  text-align: center;
  text-transform: capitalize;
  color: #28552e;
  font-family: 'Montserrat Extra Bold';
  line-height: 55px;
  overflow-wrap: break-word;
}

.img_tekli_sablon7 .titleTR {
  font-size: 45px;
  position: absolute;
  left: 10%;
  top: 340px;
  width: 80%;
  text-align: center;
  text-transform: capitalize;
  color: #28552e;
  font-family: 'Montserrat Extra Bold';
  line-height: 45px;
  overflow-wrap: break-word;
}

.img_tekli_sablon7 .origin {
  font-size: 25px;
  position: absolute;
  left: 10%;
  top: 391px;
  width: 80%;
  text-align: center;
  text-transform: uppercase;
  color: #28552e;
  font-family: 'Montserrat Extra Bold';
  overflow-wrap: break-word;
}

.img_tekli_sablon7 .weight {
  font-size: 17px;
  position: absolute;
  width: 255px;
  text-align: center;
  left: 682px;
  top: 705px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_tekli_sablon7 .pattern-logo {
  position: absolute;
  left: 70px;
  top: 90px;
}

.img_tekli_sablon7 .pattern-logo img {
  min-width: 200px;
  max-height: 200px;
}

.img_tekli_sablon7 .product-image img {
  position: absolute;
  max-height: 25rem;
  left: 60px;
  top: 446px;
}

.img_tekli_sablon7 .campaing-dates {
  position: absolute;
  font-size: 40px;
  width: 440px;
  left: 50px;
  top: 930px;
  text-align: left;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #000;
}

.img_tekli_sablon7 .address {
  position: absolute;
  font-size: 25px;
  width: 360px;
  text-align: right;
  top: 935px;
  left: 625px;
  font-family: 'Montserrat Extra Bold';
  color: #000;
}

.img_tekli_sablon7 .phone {
  position: absolute;
  font-size: 26px;
  width: 200px;
  text-align: center;
  top: 858px;
  left: 701px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

/* img_tekli_sablon7 Finish */