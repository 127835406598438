/* img_tekli_sablon6 Start */

.img_tekli_sablon6 .new-price {
  font-size: 60px;
  position: absolute;
  width: 300px;
  text-align: center;
  left: 142px;
  top: 481px;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fff;
  background-color: #08536a;
  border-radius: 11px;
}

.img_tekli_sablon6 .old-price {
  font-size: 40px;
  position: absolute;
  width: 245px;
  text-align: center;
  left: 165px;
  top: 575px;
  text-decoration: line-through;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fff;
  background-color: #068ab1;
  border-radius: 11px;
}

.img_tekli_sablon6 .title {
  font-size: 57px;
  position: absolute;
  top: 310px;
  left: 30px;
  width: 558px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  font-family: 'Montserrat';
  font-weight: bold;
  line-height: 55px;
  overflow-wrap: break-word;
}

.img_tekli_sablon6 .titleTR {
  font-size: 40px;
  position: absolute;
  top: 365px;
  left: 30px;
  width: 558px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  font-family: 'Montserrat';
  font-weight: bold;
  line-height: 55px;
  overflow-wrap: break-word;
}

.img_tekli_sablon6 .origin {
  font-size: 25px;
  position: absolute;
  top: 420px;
  left: 30px;
  width: 558px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-family: 'Montserrat';
  font-weight: bold;
  overflow-wrap: break-word;
}

.img_tekli_sablon6 .weight {
  font-size: 20px;
  position: absolute;
  width: 305px;
  text-align: center;
  left: 142px;
  top: 680px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_tekli_sablon6 .pattern-logo {
  position: absolute;
  left: 52px;
  top: 870px;
}

.img_tekli_sablon6 .pattern-logo img {
  min-width: 200px;
  max-height: 200px;
}

.img_tekli_sablon6 .product-image img {
  position: absolute;
  max-height: 26rem;
  left: 555px;
  top: 326px;
}

.img_tekli_sablon6 .campaing-dates {
  position: absolute;
  font-size: 30px;
  width: 395px;
  left: 555px;
  top: 910px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fff;
}

.img_tekli_sablon6 .address {
  position: absolute;
  font-size: 21px;
  width: 605px;
  text-align: left;
  top: 967px;
  left: 52px;
  font-family: 'Montserrat Extra Bold';
  color: #565656;
}

.img_tekli_sablon6 .phone {
  position: absolute;
  font-size: 25px;
  width: 200px;
  text-align: center;
  top: 815px;
  left: 75px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

/* img_tekli_sablon6 Finish */