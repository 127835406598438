/* img_tekli_sablon1 Start */

.img_tekli_sablon1 .new-price {
  font-size: 71px;
  position: absolute;
  width: 300px;
  text-align: center;
  left: 65px;
  top: 612px;
  font-family: 'Montserrat Extra Bold';
  color: #218642;
  background-color: #ffcd02;
  border-radius: 11px;
}

.img_tekli_sablon1 .old-price {
  font-size: 55px;
  position: absolute;
  width: 245px;
  text-align: center;
  left: 93px;
  top: 718px;
  text-decoration: line-through;
  font-family: 'Montserrat Extra Bold';
  color: #218642;
  background-color: #ffffff;
  border-radius: 11px;
}

.img_tekli_sablon1 .title {
  font-size: 33px;
  position: absolute;
  left: 50px;
  top: 270px;
  width: 330px;
  text-align: center;
  text-transform: capitalize;
  color: #ffcd02;
  font-family: 'Montserrat Extra Bold';
  font-weight: bolder;
  line-height: 55px;
  overflow-wrap: break-word;
}

.img_tekli_sablon1 .titleTR {
  font-size: 30px;
  position: absolute;
  left: 50px;
  top: 345px;
  width: 330px;
  text-align: center;
  text-transform: capitalize;
  color: #ffcd02;
  font-family: 'Montserrat Extra Bold';
  font-weight: bolder;
  line-height: 40px;
  overflow-wrap: break-word;
}

.img_tekli_sablon1 .origin {
  font-size: 25px;
  position: absolute;
  left: 50px;
  top: 400px;
  width: 330px;
  text-align: center;
  text-transform: uppercase;
  color: #ffcd02;
  font-family: 'Montserrat Extra Bold';
  font-weight: bolder;
  overflow-wrap: break-word;
}

.img_tekli_sablon1 .weight {
  font-size: 19px;
  position: absolute;
  width: 310px;
  text-align: center;
  left: 550px;
  top: 900px;
  font-family: 'Montserrat Extra Bold';
  color: #4d870f;
}

.img_tekli_sablon1 .pattern-logo {
  position: absolute;
  left: 108px;
  top: 102px;
}

.img_tekli_sablon1 .pattern-logo img {
  min-width: 200px;
  max-height: 200px;
}

.img_tekli_sablon1 .product-image img {
  position: absolute;
  max-height: 25rem;
  left: 495px;
  top: 415px;
}

.img_tekli_sablon1 .campaing-dates {
  position: absolute;
  font-size: 35px;
  width: 440px;
  left: 467px;
  top: 945px;
  text-align: center;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_tekli_sablon1 .address {
  position: absolute;
  font-size: 20px;
  width: 337px;
  text-align: center;
  top: 885px;
  left: 47px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

.img_tekli_sablon1 .phone {
  position: absolute;
  font-size: 25px;
  width: 337px;
  text-align: center;
  top: 945px;
  left: 47px;
  font-family: 'Montserrat Extra Bold';
  color: #fff;
}

/* img_tekli_sablon1 Finish */